import { useCallback, useMemo, useState } from 'react'
import { Editable, withReact, Slate, ReactEditor, useSlate } from 'slate-react'
import { createEditor, Editor } from 'slate'
import { withHistory } from 'slate-history';
import { Element } from '../../../../Utiles/Texto/Element';
import { Leaf } from '../../../../Utiles/Texto/Mark';
import { hotKeysValue } from '../../../../Utiles/Texto/HotKeys';
import { TitleCard, RowConfig, CardConfig } from '../index'
import { ColorButton, FontStyle, MarkMenu } from '../../../../Utiles/Texto/MarkProps';
import { AlingMenu, ListMenu, TabsMenu, TypeMenu } from '../../../../Utiles/Texto/BlockProps';
import BuscarShort from '../../Shorts/Buscar';
import { ButtonConfig } from '../Buttons';
const SlateProvider = ({ value, setValue, children }: { value: any, setValue: (d: any) => void, children: any }) => {
    const editor = useMemo(() => withHistory(withReact(createEditor() as ReactEditor)), []);
    return <Slate editor={editor} value={value} onChange={setValue}>
        {children}
    </Slate>
}
export default SlateProvider;
export const EditArea = ({ change, value }: { change?: (d: any) => void, value?: any[] }) => {
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useSlate();
    return <Editable key={'' + Math.random()}
        id='EditableHTML'
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck style={{ width: '100%', minWidth: '100px', minHeight: '30px' }}
        onChange={change} value={value}
        onKeyDown={(event: any) => hotKeysValue(editor, event)}
    />
}

export const TBElement = () => {
    return <CardConfig width='265px'>
        <RowConfig>
            <MarkMenu />
            <ColorButton left={1} />
            <AlingMenu left={1} />
        </RowConfig>
        <RowConfig top={2}>
            <TabsMenu left={0} />
            <ListMenu left={1} />
            <TypeMenu left={1} />
        </RowConfig>
        <RowConfig top={2}>
            <FontStyle left={0} width='calc(100% - 36px)' />
            <Inserts />
        </RowConfig>
        <TitleCard>FUENTE Y TEXTO</TitleCard>
    </CardConfig>
}
const insertText = (editor: any, text: string) => {
    Editor.insertText(editor, text)
}
export const Inserts = () => {
    const editor = useSlate();
    const [show, setShow] = useState(false);
    return <>
        <BuscarShort open={show} close={() => setShow(false)}
            add={s => insertText(editor, `{${s.clave}}`)} />
        <ButtonConfig icon='fas fa-hashtag' className='ml-1'
            tip='INSERTAR ATAJO' action={() => setShow(true)} />
    </>
}