import { localData } from "./localData";
import { isLine } from "./OnLineCheker";
type setData = (d: any) => void;
export const don = '';
export class Conexion {
    persistencia = 0;
    update = -100;
    clase = '';
    state = '';
    init = true;
    updater(ref: string) {
        // setEstado(ref);
    }
    commadUpdate() {
        this.updater(this.clase)
    }
    async cambios(setData: setData, setNot?: () => void) {
        //Solicitando condicion de los estados
        isLine()
        let token = localStorage.getItem('Authorization');
        let url = don + '/api/' + this.clase + '/cambios/' + this.state.replace(' ', '/');
        try {
            //solicitar nuevos datos
            let response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
            let data: { data: any, fecha: string } = await response.json();
            //cargar nuevos datos
            if (Array.isArray(await data.data)) {
                if (await data.data.length !== 0) {
                    this.state = await data.fecha;
                    setData(await data.data);
                    if (this.init) this.init = false;
                }
                if (setNot) setNot()
            }
        } catch (err) {
            console.log(err);
        }

    }
    //Manejo de listas
    cargarListaCache(setData: setData) {
        let url = don + '/api/' + this.clase + '/lista';
        let ld = new localData(url);
        if (ld.data !== undefined) { setData(ld.data); }
    }
    async cargarListaWeb(setData: setData) {
        //Solicitando condicion de los estados
        let token = localStorage.getItem('Authorization');
        let url = don + '/api/' + this.clase + '/lista';
        try {
            //solicitar nuevos datos
            let response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
            let data = await response.json();
            //cargar nuevos datos
            if (Array.isArray(await data)) {
                setData(await data);
                //Actualizadar nuevos datos en cache
                this.saveLista(await data);
            }
        } catch (err) {
            console.log(err);
        }
    }
    //get pre cache
    async lista(setData: setData) {
        this.cargarListaCache(setData);
        //Solicitando condicion de los estados
        let token = localStorage.getItem('Authorization');
        try {
            let response = await fetch(don + '/api/Auth/estado', { headers: { Authorization: 'Bearer ' + token } });
            let data = await response.json();
            //Comprobando el estados en la web
            if (this.update < await data[this.clase]) {
                this.update = await data[this.clase];
                this.cargarListaWeb(setData);
            }
        } catch (e) {
            if (isLine())
                console.log(e);
        }
    }
    async detalle(codigo: string | number, setData: setData) {
        let url = don + '/api/' + this.clase + '/detalles/' + codigo;
        let key = this.clase + '/detalles/' + codigo;
        const detalle = localStorage.getItem(key);
        if (detalle !== null) {
            setData(JSON.parse(detalle));
        } else {
            //Solicitando condicion de los estados
            try {
                //solicitar nuevos datos
                let token = localStorage.getItem('Authorization');
                let response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
                let data = await response.json();
                //cargar nuevos datos
                setData(await data);
                //Actualizadar nuevos datos en cache
                localStorage.setItem(key, JSON.stringify(await data));
            } catch (err) {
                if (isLine())
                    console.log(err);
            }
        }
    }
    //get no cache
    async cancelar(codigo: string | number, setData?: setData) {
        let token = localStorage.getItem('Authorization');
        try {
            //solicitar nuevos datos
            let response = await fetch(don + '/api/' + this.clase + '/cancelar/' + codigo, { headers: { 'Authorization': 'Bearer ' + token } });
            let data = await response.json();
            if (setData) setData(data);
            //cargar 
            this.commadUpdate();
        } catch (err) {
            if (isLine())
                console.log(err);
        }
    }
    //post no cache
    nuevo(data: any, setData?: setData, setError?: setData) {
        this.post(don + '/api/' + this.clase + '/nuevo', data, setData, setError)
    }
    actualizar(codigo: string | number, data: any, setData?: setData, setError?: setData) {
        this.post(don + '/api/' + this.clase + '/actualizar/' + codigo, data, setData, setError);
    }
    checkListaCache(itens: any, setData: setData, conver?: (data: any[]) => any[]) {
        // let url = don + '/api/' + this.clase + '/lista';
        // let ld = new localData(url);
        // if (ld.data !== undefined) {
        //     const data = conver ? conver(ld.data) : ld.data;
        //     let l = JSON.stringify(itens);
        //     data.forEach((e: any) => { l = l.replace(JSON.stringify(e), '') });
        //     if (JSON.parse(l).length) setData(data);
        // }
    }
    saveLista(data: any) {
        let url = don + '/api/' + this.clase + '/lista';
        //Actualizadar nuevos datos en cache
        let ld = new localData(url);
        ld.setData(data);
        localStorage.setItem(this.clase + 'Update', this.update + '')
    }
    //Solicitud de datos
    async get(url: string, setData: setData) {
        let ld = new localData(url);
        if (ld.data !== undefined) { setData(ld.data); }
        //Solicitando condicion de los estados
        if (ld.renovar(this.persistencia)) {
            try {
                //solicitar nuevos datos
                let token = localStorage.getItem('Authorization');
                let response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
                let data = await response.json();
                //cargar nuevos datos
                setData(await data);
                //Actualizadar nuevos datos en cache
                ld.setData(await data);
            } catch (err) {
                if (isLine())
                    console.log(err);
            }
        }
    }
    //Forsar actualizacion de datos
    async forceGetWeb(url: string, setData: setData, errorData?: setData) {
        try {
            //solicitar nuevos datos
            let token = localStorage.getItem('Authorization');
            let response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
            let data = await response.json();
            //cargar nuevos datos
            setData(await data);
            //Actualizadar nuevos datos en cache
            let ld = new localData(url);
            ld.setData(await data);
        } catch (err) {
            if (isLine()) {
                console.log(err);
                if (errorData) errorData(err)
            }
        }
    }
    //Cargar solo datos en cache
    getCacheOnly(url: string, setData: setData) {
        let ld = new localData(url);
        if (ld.data !== undefined) { setData(ld.data); }
    }
    //Envio de datos para crud
    post(url = '', obj = {}, success = (d: any) => { }, error = (d: any) => { }) {
        let token = localStorage.getItem('Authorization');
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open("POST", url);
        if (token !== null)
            xhr.setRequestHeader('Authorization', 'Bearer ' + token)
        xhr.onreadystatechange = (e) => {
            if (xhr.readyState !== 4) {
                return;
            }
            if (xhr.status === 200) {
                if (xhr.response.errores) error(xhr.response.errores);
                else success(xhr.response);
                //cargar 
                this.commadUpdate();
            } else {
                if (isLine())
                    error(xhr);
            }
        };
        xhr.send(this.FormDataFotmat(obj));
    }
    //formateador de datos
    FormDataFotmat(o: any) {
        const formData = new FormData();
        Object.keys(o).forEach(key => {
            switch (typeof o[key]) {
                case 'object':
                    formData.append(key, JSON.stringify(o[key]));
                    break;
                default:
                    formData.append(key, o[key]);
            }
        });
        return formData;
    };
}