import { Conexion } from "../../../Config/Coneccion";
class NotaConexion extends Conexion {
    clase = 'Nota';
    persistencia = 1000;
    commadUpdate() {
        this.updater(this.clase)
        this.updater('Producto')
    }
}
var conot: NotaConexion;
export default function conNota() {
    if (conot === undefined) conot = new NotaConexion();
    return conot;
}