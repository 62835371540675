import React from 'react'
import FilaProducto from './Productos'
import { Col, Button, ButtonGroup } from 'react-bootstrap'
import { Detalles, GeneralFila } from '../../../../Models/General'
import { conCotizacion, conNota, Cotizacion, Nota, sentNota, updatePrecio } from '../../../../Models/Ventas';
import BuscarCotizacion from '../../Cotizacion/BuscarCotizacion';
import BuscarNota from '../BuscarNota';
import AuxiliarVentas from '../../../../Utiles/Ventas/AuxiliarVentas';
import { ProductosType } from '../../../../Context/Producto'
import { ProductosModal } from '../../../../Utiles/Prototipos/AuxiliarNuevo'
import { numberFormat } from '../../../../Utiles/Formatos';
export default class AuxiliarNotas extends AuxiliarVentas {
    productos: ProductosModal = 'Factura';
    BotonesLeft() {
        const { cargarCotizacion, cargarNota, state: { aux2, aux } } = this;
        return <Col xs={6}>
            <BuscarCotizacion open={aux} add={cargarCotizacion.bind(this)} close={() => this.setState({ aux: false })} />
            <BuscarNota open={aux2} add={cargarNota.bind(this)} close={() => this.setState({ aux2: false })} />
            <ButtonGroup>
                <Button onClick={() => this.setState({ aux: true })}
                    data-class="toolbody" data-html="true"
                    data-tip="CARGAR COTIZACION" variant="primary">
                    <span className="fa fa fa-calculator" />
                </Button>
                <Button onClick={() => this.setState({ aux2: true })}
                    data-class="toolbody" data-html="true"
                    data-tip="COPIAR NOTA" variant="primary">
                    <span className="fa fa-paper-plane" />
                </Button>
            </ButtonGroup>
        </Col>
    }

    BotonesRight() {
        const { props: { setData, data: { dolar, id_nota } } } = this;
        return <Col xs={6} style={{ textAlign: 'right' }} className="float-right">
            <Button data-tip={"CAMBIAR A " + (dolar ? 'BOLIVARES' : 'DOLARES')} style={{ marginRight: "15px" }}
                variant={dolar ? 'warning' : "primary"} onClick={() => setData({ dolar: !dolar })} size='sm'>
                {dolar ? <span className="fa fa-dollar-sign" /> : <b>BS</b>} DOCUMENTO EN {dolar ? 'DOLARES' : 'BOLIVARES'}
            </Button>
            <Button data-tip={(id_nota ? 'QUITAR' : 'AGREGAR') + ' SERIALES'} style={{ marginRight: "15px" }}
                variant={id_nota ? 'success' : "outline-dark"} onClick={() => setData({ id_nota: !id_nota })}>
                <i className="fa fa-barcode"></i>
            </Button>
            <Button data-tip="PROCESAR" style={{ marginRight: "15px" }} variant="success"
                className="float-right" onClick={() => this.setData({ procesar: true })} >
                <span className="fa fa-print" />
            </Button>
            <ButtonGroup className="float-right" style={{ marginRight: "15px" }}>
                {this.createProductoValidation() ? <Button data-tip="NUEVO PRODUCTO" onClick={() => { this.setState({ nuevoProducto: true }) }} variant="info">
                    <i className="fa fa-edit"></i>
                </Button> : <></>}
                <Button data-tip="BUSCAR PRODUCTO" onClick={() => { this.setState({ buscarProducto: true }) }} variant="primary">
                    <span className="fa fa-search"></span>
                </Button>
            </ButtonGroup>
        </Col>
    }
    Calcular({ imponible, exento }: { imponible: number, exento: number }) {
        return <tr>
            <th colSpan={3}></th>
            <th style={{ textAlign: "right" }} colSpan={2}>TOTAL:</th>
            <th style={{ textAlign: "right" }}>{numberFormat(imponible + exento)}</th>
        </tr>;
    }
    cargarCotizacion({ codigo, rif }: GeneralFila) {
        const { tasa, dolar } = this.context as ProductosType;
        this.setData({ cod_cliente: rif, id_cotizacion: codigo });
        conCotizacion().detalle(codigo, (data: Cotizacion) => 
        this.setData(updatePrecio(data, tasa, dolar)));
    }
    cargarNota(d: GeneralFila) {
        conNota().detalle(d.codigo, (data: Nota) => {
            let p: any = sentNota(data, 0);
            p.detalles = data.detalles;
            p.codigo = 0;
            if (this.props.data.nota !== '') p.nota = this.props.data.nota;
            this.setData(p)
        });
    }
    Row({ data, add, remove }: { data: Detalles, add: (pr: Detalles) => void, remove: (pr: Detalles) => void }) {
        let { dolar, id_nota } = this.props.data;
        return <FilaProducto
            key={data.codigo + 'venta' + this.productos}
            data={data}
            edit={add}
            remove={remove}
            dolar={dolar ? true : false}
            open={id_nota ? true : false}
        />;
    }
}