import { useEffect, useState } from 'react'
import { Paralax1 } from '../EditPanel/Paralax1';
import { Paralax2 } from '../EditPanel/Paralax2';
import { Video } from '../EditPanel/Video';
import { Galeria } from '../EditPanel/Galeria';
import { Carrusel } from '../EditPanel/Carrusel';
import { Cargos } from '../EditPanel/Cargos';
import { Servicio } from '../EditPanel/Servicio';
import { Servicio2 } from '../EditPanel/Servicio2';
import { Preguntas } from '../EditPanel/Preguntas';
import { Title } from '../EditPanel/Title';
import { Imagen } from '../EditPanel/Imagen';
import { Pestanas } from '../EditPanel/Pestanas';
import { Mapa } from '../EditPanel/Mapa';
import { Suscribir } from '../EditPanel/Suscribir';
import { conPanel } from '../../../Models/Panel';
import { useParams } from "react-router-dom";
import Cargando from '../../../Utiles/Componentes/Cargando';
import { Contact } from '../EditPanel/Contact';
export function EditarPanel() {
    let { id } = useParams<{ id: any }>();
    const [panel, setPanel] = useState<any>([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { conPanel().forceGetWeb(`/api/Panel/detalles/${id}`, setPanel, () => { }); }, [])
    switch (panel.type) {
        case 'cargos': return <Cargos key={'panel' + id} data={panel} />
        case 'carrusel': return <Carrusel key={'panel' + id} data={panel} />
        case 'galeria': return <Galeria key={'panel' + id} data={panel} />
        case 'imagen': return <Imagen key={'panel' + id} data={panel} />
        case 'pestanas': return <Pestanas key={'panel' + id} data={panel} />
        case 'mapa': return <Mapa key={'panel' + id} data={panel} />
        case 'paralax1': return <Paralax1 key={'panel' + id} data={panel} />
        case 'paralax2': return <Paralax2 key={'panel' + id} data={panel} />
        case 'preguntas': return <Preguntas key={'panel' + id} data={panel} />
        case 'servivio1': return <Servicio key={'panel' + id} data={panel} />
        case 'servivio2': return <Servicio2 key={'panel' + id} data={panel} />
        case 'suscribir': return <Suscribir key={'panel' + id} data={panel} />
        case 'titulo': return <Title key={'panel' + id} data={panel} />
        case 'video': return <Video key={'panel' + id} data={panel} />
        case 'contacto': return <Contact key={'panel' + id} data={panel} />
        default: return <Cargando />
    }
}