import React from 'react'
import { Button, Col } from 'react-bootstrap';
import { ProductosContext, ProductosType } from '../../../Context/Producto';
import { conProducto, Producto } from '../../../Models/Productos';
import CardBuscador from '../../../Utiles/Buscadores/Card';
import Avanzados, { AvanzadosProps } from './Avanzados';
import ProductosExcel from './Excel';
import FilaProducto from './Fila';
import FormProductos from './FormProductos';

class ListaProductos extends CardBuscador {
    con = conProducto();
    placeholder = "BUSCAR PRODUCTO";
    fecha_init = 'dd/mm/aaaa';
    fecha_fin = 'dd/mm/aaaa';
    departamento = 'TODO';
    max = 0;
    min = -100;
    status = -1;
    buscarEn = ['codigo', 'descripcion'];
    Title() {
        const { setState, set, state: { modal } } = this;
        return <>
            <FormProductos
                dolar={this.state.dolar}
                open={modal}
                close={() => { setState({ modal: false }) }}
                add={set.bind(this)} />
            <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR PRODUCTO</h4>
            <Button onClick={() => { setState({ modal: true }) }} variant='primary'>
                <span className="fa fa-plus"></span> NUEVO PRODUCTO
            </Button>
        </>
    }
    Avansado() {
        const { fecha_fin, fecha_init, status, departamento, min, max, setAvansados } = this;
        return <Avanzados fecha_fin={fecha_fin} fecha_init={fecha_init} status={status}
            departamento={departamento} min={min} max={max} setData={setAvansados.bind(this)} />
    }
    Row(d: Producto) {
        const { findProVen } = this.context as ProductosType;
        const data = findProVen(d, this.state.dolar);
        return <FilaProducto dolar={this.state.dolar} data={data} update={this.set.bind(this)} />
    }
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='90px' label='codigo'>COD</Th>
            <Th width='calc(100% - 500px)' label='descripcion'>DESCRIPCION</Th>
            <Th width='160px' label='cantidad' aling='right'>CANTIDAD</Th>
            <Th width='250px' label='costo' aling='right'>COSTO</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='150px'>ACCIONES</Th>
        </tr>
    }
    setAvansados({ fecha_init, fecha_fin, min, max, departamento, status }: AvanzadosProps) {
        if (fecha_init) this.fecha_init = fecha_init;
        if (fecha_fin) this.fecha_fin = fecha_fin;
        if (departamento) this.departamento = departamento;
        if (status) this.status = status;
        if (status === 0) this.status = status;
        if (max) this.max = max;
        if (max === 0) this.max = max;
        if (min) this.min = min;
        if (min === 0) this.min = min;
        this.forceUpdate()
    }
    filter() {
        const { fecha_fin, fecha_init, status, departamento, min, max, datetonomber } = this;
        let itens = this.ordenar();
        return itens.filter((c: Producto) => {
            if (c.cantidad < min) return false;
            if (c.cantidad > max && max !== 0) return false;
            if (c.estatus !== status && status !== -1) return false;
            if (departamento !== 'TODO' && c.departamento !== departamento) return false;
            const creacion = datetonomber(c.fecha_creacion);
            if (fecha_fin !== 'dd/mm/aaaa' && creacion > datetonomber(fecha_fin)) return false;
            if (fecha_init !== 'dd/mm/aaaa' && creacion < datetonomber(fecha_init)) return false;
            return true;
        });
    }
    getItens() { return this.context.Productos; }
    set() { this.context.actProducto(); }
    getEstado() { return this.context.estProducto }
    Actualizar() {
        const { hora } = this.getEstado();
        const { dolar } = this.state;
        return <Col md={3} className='mr-auto d-flex flex-row-reverse'>
            <Button data-tip={"CAMBIAR A " + (dolar ? 'BOLIVARES' : 'DOLARES')} style={{ marginRight: "15px" }}
                variant={dolar ? 'warning' : "primary"} onClick={() => this.setState({ dolar: !dolar })} size='sm'>
                {dolar ? <span className="fa fa-dollar-sign" /> : <b>BS</b>}
            </Button>
            <ProductosExcel productos={this.filter()} />
            <div style={{ fontSize: '12px', width: "200px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> <br />
                {hora}
            </div>
        </Col>
    }
}
ListaProductos.contextType = ProductosContext;
export default ListaProductos;
