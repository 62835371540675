import React from 'react';
import { Panel } from './Panel';
import { Config } from './Config';
import { icon, defaultIcon } from '../../Component/Icons/IconSelector';
import { conPanel } from '../../../../Models/Panel';
import { GalIten, StateS, Iten, defaultIten } from '../../ProtoPanes/GalIten';
import { getText } from '../../ProtoPanes/DefaultData';
interface I { icon: icon; aux: string; fill: string; panel: any }
interface S {
    font: number
    color: string;
    border: boolean;
    back: 'none' | 'circle' | 'rounded' | 'rectangle';
    tap: string,
    tapTitle: string,
    orientation: 'row' | 'row-reverse' | 'column' | 'column-reverse',
    tapPosition: 'row' | 'row-reverse' | 'column' | 'column-reverse',
    variant: 'tab' | 'mat';
}
export type State = StateS<I> & S;
export type iten = Iten & I;
const aux: I = { icon: defaultIcon, aux: '#cccc00', fill: '#00bbbb', panel: { type: '', id: 0 } }
export const dIten: Iten & I = { ...aux, ...defaultIten }
export class Pestanas extends GalIten<S, I> {
    constructor(p: { data?: any }) {
        super(p);
        if (p.data !== undefined) {
            p.data.itens = p.data.itens.map((x: any) => {
                const { props } = x;
                return { ...x, props: { ...props, panel: props.panel ? props.panel : 0 } };
            });
        }
        this.initGal(p, {
            tap: '#669977', tapTitle: '#444444', tapPosition: 'column',
            color: '#f0f0f0', border: true, variant: 'tab',
            back: 'circle', orientation: 'row', font: 2
        }, [
            { ...dIten, id: 1 },
            { ...dIten, id: 2 },
            { ...dIten, id: 3 },
        ]);
        setTimeout(() => {
            if (p.data !== undefined)
                this.state.itens.forEach(i => {
                    console.log(i.panel, i.panel.id)
                    if (i.panel && i.panel.id === undefined)
                        conPanel().forceGetWeb(`/api/Panel/detalles/${i.panel}`,
                            (panel) => {
                                if (panel.id !== undefined) this.setIten({ ...i, panel })
                            }, () => { })
                })
        }, 100);
    }
    Config = () => <Config
        save={this.save}
        key='config'
        iten={this.getIten(aux)}
        setIten={this.setIten}
        setConfig={this.setData}
        title={this.state.title}
        setTitle={this.setTitle}
        removeItem={this.removeItem}
        itenUp={this.itenUp}
        itenDown={this.itenDown}
        addIten={() => this.addIten(aux)}
        config={this.state} />
    Panel = () => <Panel key='panel'
        config={this.state}
        setConfig={this.setData}
        seled={this.seleted} />
    saveItens = () => this.state.itens.map(({ icon, aux, fill, color, id,  html, back, panel: { id: panel } }) => {
        const data = JSON.stringify({});
        const props = JSON.stringify({ icon, aux, fill, color, id, back, panel });
        return { data, props, html:JSON.stringify(getText(html)), id };
    })
    getDataSave = () => {
        const { title, color, back, orientation, border, font, tap, tapTitle, tapPosition, variant } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color, back, orientation, font, tap, tapTitle, tapPosition, variant, border });
        return { title, html: '', data, props, itens: this.saveItens(), type: 'pestanas' };
    }
}