import { InputGroup, ButtonGroup } from 'react-bootstrap';
import { InputNumber } from '../../../../Utiles/Inputs';
import { CardMain, SizeViewSelector, Title, Guardar, ButtonColor, TitleCard, CardConfig, RowConfig, ButtonConfig } from '../../Component';
import { ConfigIconBasic, TBElementItens } from '../../Component/Commons';
import { State, iten } from './index'
import { ConfigIten } from './Itens/Config';
interface Props {
    save: () => void
    iten: iten;
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void,
    setIten: (d: Partial<iten>) => void
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    addIten: () => void
    removeItem: (d: number) => void
}
export function Config({ save, title, config, setTitle, setConfig, setIten, iten, itenUp, itenDown, addIten, removeItem }: Props) {
    const { size, color, orientation, cols, itens: { length } } = config;
    const { back } = iten;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="250px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ButtonColor tip='COLOR DE FONDO' className='ml-1' setData={color => setConfig({ color })} color={color}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <InputGroup data-tip='NUMERO DE COLUMNAS' className='input-group-sm ml-1' style={{ height: '24px', width: 70 }}>
                    <InputGroup.Prepend style={{ height: '24px', width: '38px' }}>
                        <InputGroup.Text style={{ height: '24px', width: '38px' }}>
                            <i className="fas fa-columns"></i>
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputNumber max={3} step={1} min={1} decimal={0} style={{ height: 24 }} value={cols} setData={cols => setConfig({ cols })} />
                </InputGroup>
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <CardConfig width="132px">
            <ConfigIconBasic config={config} setConfig={setConfig}
                top={<ButtonGroup>
                    <ButtonConfig tip='ICONO A LA IZQUIERDA' active={orientation === 'row'} action={() => setConfig({ orientation: 'row' })} icon='fas fa-arrow-left' />
                    <ButtonConfig tip='ICONO A LA DERECHA' active={orientation === 'row-reverse'} action={() => setConfig({ orientation: 'row-reverse' })} icon='fas fa-arrow-right' />
                </ButtonGroup>} down={<div style={{ height: '24' }}></div>} />
            <TitleCard>CONFIGURACION DE ICONOS</TitleCard>
        </CardConfig>
        <ConfigIten addItem={addIten} cont={length} config={config} iten={iten} setConfig={setIten} itenUp={itenUp} itenDown={itenDown} removeItem={removeItem} />
        <TBElementItens back={back} setBack={back => setIten({ back })} />
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain>
}