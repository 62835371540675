import React from 'react';
import { conUnidad, Unidad } from '../../../Models/Productos';
import { FilaUnidad } from './Fila';
import ReactTooltip from 'react-tooltip';
import NuevoUnidad from './Nuevo';
import CardBuscador from '../../../Utiles/Buscadores/Card';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { InputText } from '../../../Utiles/Inputs';
import { UTDContext } from '../../../Context/Producto/UTD';
class ListUnidad extends CardBuscador {
    con = conUnidad();
    mostrar = 6;
    placeholder = "BUSCAR UNIDAD DE MEDIDA";
    estatus = [{ value: 0, label: 'CANCELADO' }, { value: 1, label: 'SIN FACTURA' }, { value: 2, label: 'FACTURADA' }];
    buscarEn = ["codigo", "descripcion"];
    Title() { return <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR UNIDAD DE MEDIDA</h4> }
    Avansado() { return <></>; }
    Buscador() {
        return <>
            <ReactTooltip />
            <Row className='d-flex' style={{ paddingBottom: '15px' }}>
                <Col md={9} className='mr-auto'>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                BUSCAR
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                        <InputGroup.Append>
                            <Button variant="primary"><i className="fa fa-search"></i> BUSCAR</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                {this.Actualizar()}
            </Row>
            <NuevoUnidad update={this.set.bind(this)} />
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='100px'>COD</Th>
            <Th label='descripcion' width='calc(100% - 270px)'>DESCRIPCION</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='70px'>ACCIONES</Th>
        </tr>
    }
    Row(p: Unidad) {
        return <FilaUnidad key={'unidad' + p.codigo} d={p} update={this.set.bind(this)} />
    }
    getItens() { return this.context.Unidades; }
    set() { this.context.actUnidad(); }
    getEstado() { return this.context.estUnidades; }
}
ListUnidad.contextType = UTDContext;
export default ListUnidad;
