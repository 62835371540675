import { css } from '@emotion/css';
export function getCSS() {
    return css`
    display: table;
    width: 100%;
    padding: 0;
    position: relative;
    & > li {
        display: table-cell;
        width: 50%;
        padding: 0 5px 0 0;
        position: relative;
    }
    & > li > .c-border-bottom {
        height: 5px;
        width: 90%;
        position: absolute;
        background-color: #e6e6e6cc;
    }
    & > .c-border-middle {
        height: 90%;
        width: 5px;
        position: absolute;
        background-color: #e6e6e6cc;
    }
    `;
}

