import { TitleCard, CardConfig, RowConfig, AddItens, ButtonConfig } from '../../../Component';
import { InputNumber } from '../../../../../Utiles/Inputs';
import { InputGroup, ButtonGroup } from 'react-bootstrap';
import { iten } from '../index';
import { CropModal } from '../../../../Media/CropModal';
interface Props {
    iten: iten,
    setConfig: (d: Partial<iten>) => void,
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void,
    addItem: () => void
    size: number
    gap: number
    cont: number
};
export function ConfigIten(p: Props) {
    const { iten, gap, size, setConfig, cont, addItem, itenUp, itenDown, removeItem } = p;
    const { id, row, col, img } = iten;
    const width = ((col * size) + ((col - 1) * gap));
    const height = ((row * size) + ((row - 1) * gap));
    return <CardConfig width='169px'>
        <RowConfig>
            <CropModal aspect={width / height} img={img} setImg={img => setConfig({ img })} />
            <AddItens tip='AGREGAR UNA IMAGEN' add={addItem} />
            <InputGroup data-tip='NUMERO DE FILAS OCUPARA LA IMAGEN' className='input-group-sm ml-1' style={{ height: '24px', width: 60 }}>
                <InputGroup.Prepend style={{ height: '24px', width: '30px' }}>
                    <InputGroup.Text style={{ height: '24px', width: '30px', paddingLeft: '.70rem' }}>
                        <i className="fas fa-arrows-v"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <InputNumber decimal={0} min={1} max={9} style={{ height: 24 }} value={row} setData={row => setConfig({ row, id })} />
            </InputGroup>
        </RowConfig>
        <RowConfig top={2}>
            <ButtonGroup>
                <ButtonConfig tip='MOVER A LA IZQUIERDA' icon='fas fa-arrow-left' action={() => itenUp(id)} />
                <ButtonConfig tip='MOVER A LA DERECHA' icon='fas fa-arrow-right' action={() => itenDown(id)} />
            </ButtonGroup>
            <ButtonConfig className='ml-1' tip='QUITAR' icon='fas fa-trash' action={() => removeItem(id)} />
            <InputGroup data-tip='NUMERO DE COLUMNAS OCUPARA LA IMAGEN' className='input-group-sm ml-1' style={{ height: '24px', width: 60 }}>
                <InputGroup.Prepend style={{ height: '24px', width: '30px' }}>
                    <InputGroup.Text style={{ height: '24px', width: '30px' }}>
                        <i className="fas fa-arrows-h"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <InputNumber decimal={0} min={1} max={9} style={{ height: 24 }} value={col} setData={col => setConfig({ col, id })} />
            </InputGroup>
        </RowConfig>
        <RowConfig top={2}>
            <span className='ml-auto mr-auto'>
                {id} DE {cont}
            </span>
        </RowConfig>
        <TitleCard>IMAGEN {id}</TitleCard>
    </CardConfig>
}