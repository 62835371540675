import React from 'react'
import { Button, Modal, Row, Col, FormGroup, ModalBody } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { UsuarioFila, conUsuario } from '../../Models/Usuario'
import { ControlText } from '../../Utiles/FormControl'

interface Props {
    open: boolean
    user: UsuarioFila
    title: string
    close: () => void
    add?: (c: UsuarioFila) => void
}
export default class FormClave extends React.Component<Props, { clave: string, repetir_clave: string }> {
    con = conUsuario();
    error: any = [];
    action: any
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this);
        this.state = {
            clave: '',
            repetir_clave: '',
        };
    }
    render() {
        let { setState, state: { clave, repetir_clave }, props: { open, close, title } } = this
        return <Modal animation show={open} onHide={close}>
            <Modal.Header closeButton>
                <h4 className='mr-auto'>{title}</h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <ModalBody>
                        <Col as={FormGroup} xs={12}>
                            <ControlText error={this.error} maxLength={120} type='password'
                                before="CLAVE" value={clave} setData={setState} update="clave" />
                        </Col>
                        <Col as={FormGroup} xs={12}>
                            <ControlText error={this.error} maxLength={120} type='password'
                                before="REPETIR" value={repetir_clave} setData={setState} update="repetir_clave" />
                        </Col>
                    </ModalBody></Row>
                <Modal.Footer>
                    <Button className="float-right" onClick={this.get.bind(this)} variant="primary">
                        <span className="fa fa-floppy-disk" /> Guardar</Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    }

    get() {
        let { clave, repetir_clave } = this.state;
        let procesar = true;
        if (clave === "") {
            this.error['clave'] = 'DEBE COLOCAR UNA CLAVE';
            procesar = false;
        }
        if (repetir_clave !== clave) {
            this.error['repetir_clave'] = 'DEBEN SER IGUALES';
            procesar = false;
        } if (procesar) {
            this.con.post('/api/Usuario/clave/' + this.props.user.codigo,this.state, () => {
                toast('CLAVE GUARDADA', { type: toast.TYPE.SUCCESS });
                this.props.close();
            }, () => toast('ERROR AL GUARDAR', { type: toast.TYPE.ERROR }))

        } this.forceUpdate();
    }

    setError(err: any) {
        if (err.response) {
            let { errores } = err.response;
            errores.forEach((err: any) => {
                if (err.sql) {
                    err.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
                } else {
                    Object.keys(err).forEach(t => this.error[t] = err[t])
                }
            })
            console.log(this.error);
            this.forceUpdate();
        }
    }
}



