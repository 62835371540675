import React from "react";
import { Dropdown, NavItem, Button } from "react-bootstrap";
import { conUsuario } from "../Models/Usuario";
import { formatoFecha } from "../Utiles/Formatos";
import { SeccionContext } from '../Context/Seccion'

class User extends React.Component {
    render() {
        const { User } = this.context.data
        return <Dropdown as={NavItem} className='user-menu'>
            <Dropdown.Toggle variant="success" >
                <img src="/logos/user.jpg" className="user-image img-circle elevation-2" alt="Logo2" />
                <span className="d-none d-md-inline">{User.usuario}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu >
                <li className="user-header bg-info">
                    <img src="/logos/user.jpg" className="img-circle elevation-2" alt="Logo" />
                    <p>
                        {User.usuario}
                        <small>{formatoFecha(User.fecha)}</small>
                    </p>
                </li>
                <li className="user-footer">
                    <Button onClick={this.cacheClear.bind(this)} className="btn btn-warning btn-flat">LIMPIAR</Button>
                    <Button onClick={this.context.logout} className="btn btn-danger btn-flat float-right">SALIR</Button>
                </li>
            </Dropdown.Menu>
        </Dropdown >;
    }
    cacheClear() {
        conUsuario().forceGetWeb('/api/Auth/token', (data) => {
            localStorage.clear()
            localStorage.setItem('Authorization', data.token);
        });
    }
}
User.contextType = SeccionContext;
export default User;