import { css } from '@emotion/css';
export function getCSS(backgroundColor: string, color: string, backgroundColorHover: string, colorHover: string) {
    return css`
        background-color: ${backgroundColor};
        & > * {height: 100%;}
        & a {
            color: ${color} !important;
            &:hover {
                background-color: ${backgroundColorHover} !important;
                color: ${colorHover} !important;
            }
        }
    `
}