
import { Button } from 'react-bootstrap';
import { Shor } from '../../../Models/Panel';
import THFila from '../../../Utiles/Componentes/THFila';
import { Link } from "react-router-dom";
export default function FilaShort({ data: { html, clave, descripcion, id } }: { data: Shor }) {
    return <tr>
        <THFila width='250px'>{clave}</THFila>
        <THFila width='500px'>{descripcion}</THFila>
        <td style={{ width: 'calc(100% - 820px)' }} dangerouslySetInnerHTML={{ __html: html }} />
        <td style={{ width: '70px' }} key={`tooltip${clave}`}>
            {id !== 0 ? <Button as={Link} to={`/panel/short/edit/${id}`}>
                <i className='fas fa-edit' data-tip='Editar' />
            </Button> : <></>}
        </td>
    </tr>

}