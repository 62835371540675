import { css } from '@emotion/css';
import { ToRGB } from '../../../../Utiles/Formatos';
export function getCSS(back: string, color: string, font: number) {
    const { r, g, b, a } = ToRGB(back)
    const { r: cr, g: cg, b: cb, a: ca } = ToRGB(color)
    return css`
        &> ul> li{
            background-color: rgba(${r + 30}, ${g + 30}, ${b + 30}, ${a});
            color: rgba(${cr - 150}, ${cg - 150}, ${cb - 150}, ${ca});
            border: 1px solid rgba(${cr}, ${cg}, ${cb}, ${ca});
            &:hover{
                background-color: rgba(${r - 30}, ${g - 30}, ${b - 30}, ${a});
                color: rgba(${cr + 150}, ${cg + 150}, ${cb + 150}, ${ca});
            }
            &.active,&:active{
                background-color: rgba(${r}, ${g}, ${b}, ${a});
                color: rgba(${cr}, ${cg}, ${cb}, ${ca});
            }
        }
        &.flex-row,
        &.flex-row-reverse {
            &> ul{
                width: 20%;
                height: 100%;
                flex-direction: column !important;
                &> li{max-height: ${(font * 1.65) + 1}rem;}
                &>.flex-column-reverse,&>.flex-column{max-height: ${(font + 1.5) + 2}rem;}
            }
            &> div{width: 80%;}
            &> .tab> li{
                margin: -10px 0 -10px 0;
                padding: 10px 5px 10px 5px !important;
                &> .p-1{margin-top: 5px;}
                &:first-child{margin-top: 0;}
                &:last-child{margin-bottom: 0;}
            }
        }
        &.flex-column-reverse,
        &.flex-column{
            &> .tab> li{
                margin: 0 -10px 0 -10px;
                padding: 5px 30px 5px 30px !important;
                &:first-child{margin-left: 0;}
                &:last-child{margin-right: 0;}
            }
        }
        &.flex-column> .tab> li{border-radius: 25px 25px 0 0;}
        &.flex-column-reverse> .tab> li{border-radius: 0 0 25px 25px;}
        &.flex-row> .tab> li{border-radius: 25px 0 0 25px;}
        &.flex-row-reverse> .tab> li{border-radius: 0 25px 25px 0;}
    `;
}

