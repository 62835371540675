import { Button, Col, InputGroup, Row } from "react-bootstrap";
import { arrayToContact, conContac, Contact } from "../../../../Models/Panel/Models";
import CardBuscador from "../../../../Utiles/Buscadores/Card";
import { InputText } from "../../../../Utiles/Inputs";
import Fila from "./Fila";


export default class ContactaMeList extends CardBuscador {
    bucarLabel = ["nombre"];
    buscarEn = ["nombre", "correo", "telefono"];
    con = conContac();
    Row(data: Contact) { return <Fila data={data} add={this.set.bind(this)} /> }
    Title() { return <h4 className='mr-auto'>LISTA DE MENSAJES RECIBIDOS</h4>; }
    set() { this.con.cargarListaWeb((itens) => { this.setState({ itens: arrayToContact(itens) }) }); }
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='50%' label='nombre'>NOMBRE</Th>
            <Th width='calc(25% - 75px)' label='correo'>CORREO</Th>
            <Th width='calc(25% - 75px)' label='telefono'>TELEFONOS</Th>
            <Th width='150px'> </Th>
        </tr>
    }
    Buscador() {
        return <>
            <Row className='d-flex' style={{ paddingBottom: '15px' }}>
                <Col md={9} className='mr-auto'>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                BUSCAR
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                        <InputGroup.Append>
                            <Button variant="primary"><i className="fa fa-search"></i> BUSCAR</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Button size='sm' onClick={this.set.bind(this)}
                    variant="info" data-tip="ACTUALIZAR LISTA" >
                    <i className="fa fa-refresh"></i> ACTUALIZAR
                </Button>
            </Row>
        </>
    }
    dolar() { }
}