import { useEffect, useState, useContext } from 'react'
import { PreviewSize } from './PreviewSize'
import { Opciones } from './Utils'
import { Row, Card, ButtonGroup, Button } from 'react-bootstrap';
import { SizeViewConteiner, sizeWidth } from '../Component';
import { ButtonConfig, TitleCard, CardConfig, RowConfig } from '../Component';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { PanelContext } from '../../../Context/Panel';
import { Pages } from './Page';
export function Ver() {
    const { panels, page, updatePanel } = useContext(PanelContext);
    const [size, setSize] = useState<sizeWidth>('1080px')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(updatePanel, [])
    return <>
        <Card style={{ backgroundColor: '#ccc', padding: '7px 7px 7px 7px' }}>
            <Row style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
                <CardConfig width='170px'>
                    <RowConfig>
                        <Button data-place='bottom' data-tip='CREAR UN NUEVO PANEL' as={Link} to='/panel/crear' size='sm' variant='success'>
                            <i className='fas fa-plus' />
                        </Button>
                        <Button data-place='bottom' data-tip='VER ATAJOS' className='ml-1' as={Link} to='/panel/short' size='sm' variant='info'>
                            <i className='fas fa-hashtag' />
                        </Button>
                        <Button data-place='bottom' data-tip='CONFIGURAR MENU' className='ml-1' as={Link} to='/panel/menu' size='sm' variant='warning'>
                            <i style={{ borderTop: '4px solid currentColor', fontSize: '.6rem' }} className='fas fa-arrow-up' />
                        </Button>
                        <Button data-place='bottom' data-tip='CONFIGURAR PIE DE PAGINA' className='ml-1' as={Link} to='/panel/pie' size='sm' variant='warning'>
                            <i style={{ borderBottom: '4px solid currentColor', fontSize: '.6rem' }} className='fas fa-arrow-down' />
                        </Button>
                        <Button data-place='bottom' data-tip='CONFIGURAR EL BOTON PARA EL CHAT' className='ml-1' as={Link} to='/panel/chats' size='sm' variant='primary'>
                            <i className='fas fa-comment' />
                        </Button>
                    </RowConfig>
                    <TitleCard>OPCIONES</TitleCard>
                </CardConfig>
                <CardConfig width='calc(100% - 370px - .5rem)'>
                    <Pages />
                    <TitleCard>PAGINAS</TitleCard>
                </CardConfig>
                <CardConfig className='ml-auto' width='193px'>
                    <RowConfig>
                        <ButtonGroup size='sm'>
                            <ButtonConfig tip='TAMAÑO PEQUENO(CELULARES)' active={size === '570px'} action={() => setSize('570px')} icon='fas fa-mobile-alt' />
                            <ButtonConfig tip='TAMAÑO PEQUENO(TABLET VERTICAL)' active={size === '780px'} action={() => setSize('780px')} icon='fas fa-tablet-alt' />
                            <ButtonConfig tip='TAMAÑO MEDIANO(TABLET HORIZONTAL)' active={size === '990px'} action={() => setSize('990px')} icon='fas fa-tablet-alt fa-rotate-90' />
                            <ButtonConfig tip='TAMAÑO MEDIANO(LAPTOP)' active={size === '1080px'} action={() => setSize('1080px')} icon='fas fa-chalkboard' />
                            <ButtonConfig tip='TAMAÑO GRANDE(MONITOR 17")' active={size === '1320px'} action={() => setSize('1320px')} icon='fas fa-desktop' />
                            <ButtonConfig tip='TAMAÑO GRANDE(MONITOR 19" O MAS)' active={size === '1440px'} action={() => setSize('1440px')} icon='fas fa-tv' />
                        </ButtonGroup>
                    </RowConfig>
                    <TitleCard>RESOLUCION</TitleCard>
                </CardConfig>
            </Row>
        </Card>
        <ReactTooltip key='tooltip' />
        <SizeViewConteiner size={size} h1='calc(100vh - 210px)' h2='calc(100vh - 210px)'>
            {panels.filter(({ pagina, estado }) => page === pagina && (estado === 0 || estado === 1))
                .map(p => <div id={`panel${p.id}`} className='w-100 m-0 p-0'>
                    <Opciones panel={p} actualizar={updatePanel} />
                    <PreviewSize key={p.type + p.id} panel={p} size={size} />
                </div>)}
        </SizeViewConteiner>
    </>
}