import { Detalles } from '../../Models/General'
import FilaProducto from './Productos'
import AuxiliarNuevo from '../Prototipos/AuxiliarNuevo';
export default class AuxiliarVentas<p={}> extends AuxiliarNuevo<p> {
    venta = true;
    Row({ data, add, remove }: { data: Detalles, add: (pr: Detalles) => void, remove: (pr: Detalles) => void }) {
        const { props: { data: { dolar } } } = this;
        return <FilaProducto
            key={data.codigo + 'venta' + this.productos}
            data={data}
            edit={add}
            remove={remove}
            dolar={dolar}
            venta={this.venta}
        />;
    }
}