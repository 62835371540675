import React, { useContext, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { SeccionContext, SeccionType } from '../Context/Seccion'
export default function Footer() {
    const { data: { online } } = useContext(SeccionContext) as SeccionType;
    return useMemo(() => <footer className={"main-footer" + (online ? '' : ' bg-danger')} style={{ bottom: 0, left: 0, right: 0, height: 50 }}>
        {online ? <>
            <div className="float-right hidden-xs"><b>Version</b> 2.2.5</div>
            <strong> <img src="/static/media/logo-text.svg" width="150px" alt='logo' /> Copyright © 2021-2025.</strong>
        </> : <>
            <strong>
                SE ENCUENTRA ACTULAMENTE SIN CONEXION A INTERNET, PUEDE NAVEGAR Y MONTAR LAS OPERACIONES PERO NO PODRA PROCESARLAS.<br />
                <Col xs={12} style={{ textAlign: 'center' }}>
                    TOMAR PREVISIONES
                </Col>
            </strong>
        </>}
    </footer>, [online]);
}