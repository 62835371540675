import { content, imagen } from './props';
import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
import { TextToHTML } from '../../../../../Utiles/Texto';

export function Itens({ config: { animated, seleted, },
    iten: { id, html, img, col, row, margin, color, back }, seled }: { iten: iten, config: State, seled: (i: number) => void }) {
    const seletedMe = () => {
        seled(id);
        setTimeout(() => {
            const div = document.getElementById("selectedMe")
            if (div) div.scrollIntoView();
        }, 10)
    }
    return <div id={seleted === id ? 'selectedMe' : undefined} onClick={seletedMe}
        className={content(back, color, margin, animated, col, row, seleted === id)}>
        <img style={imagen} alt="Bricks" src={img} />
        {seleted === id ?
            <div className={`caption `}> <EditArea key={'edit' + id} /></div> :
            <div className={`caption `}><TextToHTML text={html} /></div>
        }
    </div >
}