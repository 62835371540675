/* eslint-disable no-loop-func */
/* eslint-disable no-new-func */
import { GPUManager, GPUTrans } from "../GPU/Manager";
const getGPUManager = Function('width', 'height', 'return new GPUManager(width, height)');
export interface Crop { x: number, y: number, width: number, height: number }
export interface DataImage {
    hsl: number[][][];
    red: number, green: number, blue: number, cont: number,
    hue: number, saturate: number, ling: number
}
export const DataNull: DataImage = {
    hsl: [[[0, 0, 0, 0]]],
    red: 100, green: 100, blue: 100, cont: 100,
    hue: 0, saturate: 100, ling: 100,
}
type PF<i, o> = (i: i) => Promise<o>;
interface cropData { src: string, pixelCrop: Crop, rotation?: number, flip?: { horizontal: boolean, vertical: boolean } }
export class ImageManager {
    url = '';
    gpu?: GPUManager;
    img?: HTMLImageElement;
    createImage: PF<string, HTMLImageElement> = url => new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image as HTMLImageElement))
        image.addEventListener('error', (error) => reject(null))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })
    getCanvasById: PF<string, HTMLCanvasElement> = (id) => new Promise((resolve, reject) => {
        let canvas = document.getElementById(id);
        for (let i = 0; i < 10; i++) {
            if (canvas === null) setTimeout(() => canvas = document.getElementById(id), 5);
            else resolve(canvas as HTMLCanvasElement);
        }
        reject(null);
    })
    getCanvas = () => document.createElement('canvas') as HTMLCanvasElement;
    getString = async () => {
        const canvas = await this.getCanvasById('canvaImage');
        return canvas.toDataURL('image/jpeg', 1);
    }
    val2K = (width: number, height: number) => {
        if (width > height) {
            if (width > 1920) {
                const aspect = (height / width);
                return [1920, Math.floor(aspect * 1920)]
            }
        } else if (height > width) {
            if (height > 1920) {
                const aspect = (width / height);
                return [Math.floor(aspect * 1920), 1920]
            }
        }
        if (height > 1920) return [1920, 1920]
        return [width, height];
    }
    initCanvas = async (src: string) => {
        this.img = await this.createImage(src);
        if (this.img === null) return null;
        const canvas = await this.getCanvasById('canvaImage');
        const [width, height] = this.val2K(this.img.width, this.img.height);
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        if (!ctx) return null;
        if (ctx) {
            ctx.drawImage(this.img, 0, 0, width, height);
            const data = ctx.getImageData(0, 0, width, height);
            this.gpu = getGPUManager(width, height) as GPUManager;
            return await this.gpu.HSLToCanvas(data.data) as number[][][];
        };
        return null
    }
    imageTransforn = async (img: number[][][], data: number[]) => {
        const canvas = await this.getCanvasById('canvaImage');
        if (!this.gpu) return null;
        const { width, height } = this.gpu;
        const i8 = this.gpu.imageTransforn(img, data);
        const ctx = canvas.getContext('2d');
        if (!ctx) return null;
        if (ctx) {
            const data = ctx.getImageData(0, 0, width, height);
            data.data.set(i8);
            ctx.putImageData(data, 0, 0)
        }
        return true
    }
    preview = async () => {
        const canvas = await this.getCanvasById('canvaImage');
        return await this.toBlob(canvas);
    }
    toBlob: PF<HTMLCanvasElement, string> = async canvas => new Promise(async (resolve, reject) => canvas.toBlob((blob) => !blob ? reject(null) : resolve(window.URL.createObjectURL(blob)), 'image/jpeg', 1));
    apliyTransfor = (m: any, t: GPUTrans[]) => {
        if (this.gpu) for (let i = 0; i < t.length; i++) m = this.gpu[t[i]](m);
        return m;
    }
    getRadianAngle(degreeValue: number) {
        return (degreeValue * Math.PI) / 180
    }
    rotateSize(width: number, height: number, rotation: number) {
        const rotRad = this.getRadianAngle(rotation)
        return {
            width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }
    cropImage: PF<cropData, string> = async ({ src, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false } }) => new Promise(async (resolve, reject) => {
        const img = await this.createImage(src);
        if (img === null) reject(null);
        const canvas = this.getCanvas();
        if (canvas === null) reject(null);
        const ctx = canvas.getContext('2d');
        if (!ctx) return null;
        const rotRad = this.getRadianAngle(rotation);
        const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(img.width, img.height, rotation);
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.rotate(rotRad);
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
        ctx.translate(-img.width / 2, -img.height / 2);
        ctx.drawImage(img, 0, 0);
        const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height)
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height
        ctx.putImageData(data, 0, 0);
        resolve(this.toBlob(canvas));
    })
}
export default function getImageManager() {
    return new ImageManager();
}