import { css } from '@emotion/css';
import { getWidthSize } from '../Utils'
import { Row, Container, Col } from 'react-bootstrap';
import { Html } from '../../Component/Commons';
import { d } from '../../EditPanel/Paralax1/SVG';
export function Mapa({ size, data: { props: { xs, sm, md, lg, xl, xxl, color, align, mapa }, html } }: any) {
    return <div className={`w-100 ${getCSS} ${css`
    ${d.some(a => a === size) ? 'height: 800px !important;' : 'height: 400px !important;'}`}`} style={{ position: 'relative', backgroundColor: color.length === 9 ? color.substring(0, color.length - 2) : color }}>
        <div className={`${d.some(a => a === size) ? 'min' : 'max'}`} style={{ zIndex: 100 }}>
            <Container className="h-100"> |
                <Row className={`${getWidthSize(xs, sm, md, lg, xl, xxl, size)} ${row}`}>
                    <Col style={{ backgroundColor: color }}>
                        <Html style={{ height: '100%', width: '100%' }} html={html} />
                    </Col>
                </Row>
            </Container>
        </div>
        <div>
            <iframe src={mapa} title='mapa'/>
        </div>
    </div>
}
const row = css`
    height: 100% !important;
    width: 100% !important;
    &> .col{
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
        height: calc(100% - 3rem) !important;
    }
`
const getCSS = css`
        &> div{
            display: block;
            position: relative !important;
            width: 100%;
            height: 400px !important;
            &> iframe{
                border: 0;
                width: 100%;
                height: 100%;
            }
            &.max {
                display: block;
                top:0;
                bottom:0;
                left:0;
                right:0;
                position: absolute !important;
            }
        }`;