import { Conexion } from "../../Config/Coneccion";
import { Detalles, GeneralFila } from "./Interface";
export function getSubtotal(d: Detalles[]) {
    let subtotal = 0;
    d.forEach(e => {
        let { precio, unidades } = e;
        subtotal += precio * unidades;
    })
    return subtotal;
}
export function getExentoEImpuesto(d: Detalles[]) {
    let imp = 0, ext = 0;
    d.forEach(p => {
        if (p.exento) ext += p.precio * p.unidades;
        else imp += p.precio * p.unidades;
    })
    return [imp, ext];
}
export function getCosto(d: Detalles[]) {
    let subtotal = 0;
    d.forEach(e => {
        let { costo, unidades } = e;
        subtotal += costo * unidades;
    })
    return subtotal;
}
export function sentDetalles(d: Detalles[]) {
    return d.map(e => {
        let { codigo, precio, unidades, comentario } = e;
        return { codigo, precio, unidades, comentario };
    })
}
export function arrayToFilaGeneral(f: any[]): GeneralFila[] {
    return f.map(a => {
        return {
            codigo: a[0],
            rif: a[1],
            nombre: a[2],
            fecha: a[3],
            monto: a[4],
            usuario: a[5],
            status: a[6],
            detalles: a[7],
            tasa: a[8] ? a[8] : 0
        }
    })
}
export function updateOrPush(a: any[], b: GeneralFila[], con: Conexion) {
    const push = arrayToFilaGeneral(a).filter((d => {
        const i = b.findIndex(c => c.codigo === d.codigo);
        if (i > -1) {
            b[i] = d;
            return false;
        }
        return true;
    }));
    const arr = [...b, ...push];
    con.saveLista(arr);
    return arr;
}