import { Row, Container } from 'react-bootstrap';
import { State } from './index'
import { Itens } from './Itens'

export function Panel({ config, seled }: { config: State, seled: (i: number) => void }) {
    const { color, itens, gap, id } = config;
    return <Row style={{ backgroundColor: color, margin: '0 0 0 0', padding: '10px 0 10px 0' }}>
        <Container style={{ display: 'grid', gap: `${gap}px`, gridAutoFlow: 'dense', gridTemplateColumns: `repeat(auto-fill, minmax(320px, 1fr))` }}>
            {itens.map((i) => <Itens key={'iten' + id + i.id} iten={i} config={config} seled={seled} />)}
        </Container>
    </Row>
}