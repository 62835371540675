import { UsuarioFila, conUsuario } from '../../Models/Usuario'
import { AccordionToggle, Button, Col, InputGroup, Row } from 'react-bootstrap';
import FormUsuario from './FormUsuario';
import { FilaUsuario } from './Fila';
import CardBuscador from '../../Utiles/Buscadores/Card';
import Avanzados, { AvanzadosProps } from './Avanzados';
import { SeccionContext } from '../../Context/Seccion';
import UsuariosExcel from './Excel';
import { InputText } from '../../Utiles/Inputs';
class ListUsuario extends CardBuscador {
    con = conUsuario();
    placeholder = "BUSCAR USUARIO";
    rol = -1;
    status = -1;
    buscarEn = ["nombre", "correo"];
    Title() {
        return <>
            <FormUsuario
                user={null}
                title='NUEVO USUARIO'
                open={this.state.modal}
                close={() => { this.setState({ modal: false }) }}
                add={this.set.bind(this)} />
            <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR USUARIO</h4>
            <Button onClick={() => { this.setState({ modal: true }) }} variant='primary'>
                <span className="fa fa-plus"></span> NUEVO USUARIO
            </Button>
        </>
    }
    Avansado() {
        const { rol, status, setAvansados } = this;
        return <Avanzados rol={rol} status={status} setData={setAvansados.bind(this)} />;
    }
    Header() {
        let { Th } = this;
        return <tr className="info">
            <Th width='calc(100% - 710px)' label='codigo'>NOMBRE</Th>
            <Th width='350px' label='correo'>CORREO</Th>
            <Th width='150px'>ROL</Th>
            <Th width='150px'>ESTADO</Th>
            <Th width='110px'>ACCIONES</Th>
        </tr>
    }
    setAvansados({ rol, status }: AvanzadosProps) {
        if (rol) this.rol = rol;
        if (status) this.status = status;
        this.forceUpdate()
    }
    filter() {
        let itens = this.ordenar();
        return itens.filter((c: UsuarioFila) => this.validar(c));
    }
    validar({ nivel, estado }: UsuarioFila) {
        const { rol, status } = this;
        if (status !== -1 && estado !== status) return false;
        if (rol !== -1 && nivel !== rol) return false;
        return true;
    }
    Row(p: UsuarioFila) {
        return <FilaUsuario key={'usuario' + p.codigo + p.estado} d={p} update={this.set.bind(this)} />
    }
    getItens() { return this.context.usuarios; }
    set() { this.context.atcUsuario(); }
    getEstado() { return this.context.data.estUsuarios }
    Actualizar() {
        const { hora } = this.getEstado();
        return <Col md={3} className='mr-auto d-flex flex-row-reverse'>
            <UsuariosExcel usuarios={this.filter()} />
            <div style={{ fontSize: '12px', width: "200px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> <br />
                {hora}
            </div>
        </Col>
    }
    Buscador() {
        return <Row className='d-flex' style={{ paddingBottom: '15px' }}>
            <Col md={9} className='mr-auto'>
                <InputGroup >
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            BUSCAR
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText name='buscar' upper placeholder='ESCRIBA SU BUSQUEDA'
                    setData={this.setState} update='buscar' value={this.state.buscar} />
                    <InputGroup.Append>
                        <Button variant="primary">
                            <i className="fa fa-search"></i> BUSCAR
                        </Button>
                        <Button as={AccordionToggle} eventKey="0" variant='default' data-tip="BUSQUEDA DETALLADA" onClick={() => { this.setState({ open: !this.state.open }) }}>
                            <i className="fa fa-wrench"></i> DETALLADO
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            {this.Actualizar()}
        </Row>
    }
}
ListUsuario.contextType = SeccionContext;
export default ListUsuario;