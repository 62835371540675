/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useContext, useCallback } from 'react'
import { conFactura } from '../../Models/Ventas'
import { AuthSome } from '../../Config/Permisos';
import { NotasContext, NotasType } from './Notas';
import { updateOrPush, Estado, EstadoNull, GeneralFila } from '../../Models/General'
import { formatoHora } from '../../Utiles/Formatos';
import { semaforo } from '../../Utiles/Semaforo';
export interface VentasType extends NotasType {
    estFactura: Estado
    actFactura: () => void
    Facturas: GeneralFila[]
}
export const VentasContext = createContext<VentasType | null>(null)
export default function VentasProvider({ children }: { children: any }) {
    const [Facturas, setFacturas] = useState<GeneralFila[]>([]);
    const notas = useContext(NotasContext) as NotasType;
    const [est, setest] = useState(EstadoNull);
    const s = semaforo();
    const update = useCallback(() => {
        if (s.isTurn('Facturas')) {
            if (AuthSome(['FACTURA'])) conFactura().cambios((data: any[]) => {
                setest({ status: 3, hora: formatoHora(conFactura().state) })
                setFacturas(updateOrPush(data, Facturas, conFactura()))
            }, s.next)
            else s.next()
        }
    }, [Facturas])
    useEffect(() => {
        if (conFactura().state !== '') setest({ status: 3, hora: formatoHora(conFactura().state) })
        if (AuthSome(['FACTURA'])) conFactura().cargarListaCache(setFacturas);
        s.add('Facturas');
        return () => s.clean('Facturas');
    }, []);
    useEffect(() => {
        let interval = setInterval(update, 100)
        return () => clearInterval(interval)
    }, [Facturas]);
    return <VentasContext.Provider value={{
        ...notas,
        estFactura: est, Facturas,
        actFactura: update
    }}>
        {children}
    </VentasContext.Provider>
}
export const SeccionConsumer = VentasContext.Consumer;