export const Ori = () => <Svg />
export const Dia2 = () => <Svg><path x="285" y="0" style={{ fill }} d='M155,253h100V0l0,0' /></Svg>
export const Dia1 = () => <Svg><path x="285" y="0" style={{ fill }} d='M255,253h0V0L155,0' /></Svg>
export const Arrow1 = () => <Svg><path x="285" y="0" style={{ fill }} d='M255,0v253h0L150,126.5L240,0H255z' /></Svg>
export const Arrow2 = () => <Svg><path x="285" y="0" style={{ fill }} d='M255,0v253H150l100-126.5L150,0H255z' /></Svg>
export const Circle = () => <Svg><path x="285" y="0" style={{ fill }} d='M330,0v253H0c150,0,253,0,250-126.5S150,0,0,0H126.5z' /></Svg>
export const Onda2 = () => <Svg><path x="285" y="0" style={{ fill }} d='M250,100.2V0h-44.1c0,0,29.4,0,29.4,40.1c0,60.1-84.3,44.1-85.3,126.8c0,74.1,54.9,86.2,54.9,86.2H250V106.9V100.2z' /></Svg>
export const Onda1 = () => <Svg><path x="285" y="0" style={{ fill }} d='M250,146.2V0h-45.1c0,0-54.9,12.1-54.9,86.2c1,82.7,85.3,66.7,85.3,126.8c0,40.1-29.4,40.1-29.4,40.1H250V152.9V146.2z' /></Svg>
export const D = () => <Svg><path x="285" y="0" style={{ fill }} d='M255,0v253l0,0c0,0-126,0-126.5-126.5C126.5,0,255,0,255,0z' /></Svg>
export function Svg({ children }: any) {
    return <svg style={{
        fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round',
        strokeMiterlimit: 2, border: '1px solid black', backgroundColor: '#fff'
    }} width="72" height="48" viewBox="0 0 400 253" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <image href='/static/media/fondo.svg' x='0' y='0' width='400' height='253' />
        <rect x="250" y="0" width="150" height="253" style={{ fill }} />
        {children}
        <text style={{ fontFamily: "'TimesNewRomanPSMT', 'Times New Roman', serif", fontSize: "150" }} x="280" y="190">A</text>
    </svg>
}
const fill = 'currentcolor';