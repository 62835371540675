/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonGroup, Modal, Row, Col, DropdownButton, Button, Dropdown } from "react-bootstrap";
import { useContext, useState, useEffect, useCallback } from "react";
import ModalAlert from '../../../../Utiles/Componentes/ModalAlert';
import { ControlText } from '../../../../Utiles/FormControl';
import { PanelContext } from "../../../../Context/Panel";
import { ConConfig } from "../../../../Models/Config";
import ReactTooltip from 'react-tooltip';
import { css } from '@emotion/css';

export function Page({ title, visible, id, ident }: any) {
    const { setPage } = useContext(PanelContext);
    const drag = (ev: any) => ev.dataTransfer.setData("ident", ident);
    return <>
        <Change ident={ident} />
        <ButtonGroup onDragStart={drag} draggable="true" size='sm'>
            <Button style={{ padding: '.05rem .5rem .05rem .5rem' }} onClick={() => setPage(id)} data-place='bottom' data-tip="IR" variant='outline-dark'>
                <b>{title}</b>
            </Button>
            <DropdownButton title="" as={ButtonGroup} style={{ padding: '0 0 0 0' }} >
                <Edit id={id} />
                <Ocultar id={id} visible={visible} />
                <Eliminar id={id} title={title} />
            </DropdownButton>
        </ButtonGroup>
    </>
}
function Change({ ident }: any) {
    const { pages, updatePanel, setPages } = useContext(PanelContext);
    const [color, setColor] = useState('');
    const allowDrop = (ev: any) => {
        setColor('bg-info');
        ev.preventDefault();
    }
    const allowDropOut = (ev: any) => {
        setColor('');
    }
    const drop = (ev: any) => {
        ev.preventDefault();
        setColor('');
        var data = ev.dataTransfer.getData("ident");
        var chance = pages[data];
        var prev = pages.filter((p, i) => i < ident && p.id !== chance.id);
        var next = pages.filter((p, i) => i >= ident && p.id !== chance.id);
        const newP = [...prev, chance, ...next];
        setPages(newP);
        ConConfig('page').push({ paginas: newP }, updatePanel)
    }
    return <div style={{ height: '24px', width: '.5rem' }} className={color} onDragLeave={allowDropOut} onDragOver={allowDrop} onDrop={drop}>
    </div>
}
function Eliminar({ id, title }: any) {
    const { setPages, pages, panels, updatePanel } = useContext(PanelContext);
    const isPanel = panels.some(p => p.pagina === id);
    const [open, setOpen] = useState(false)
    const eliminar = () => {
        const newP = pages.filter(p => p.id !== id);
        setPages(newP);
        if (!isPanel) ConConfig('page').push({ paginas: newP }, updatePanel);
    }
    return <>
        <ModalAlert open={open} close={() => setOpen(false)}
            title={`ELIMINAR PAGINA ${title}`} isValid={!(isPanel || pages.length === 1)}
            msn={isPanel ? `<p>NO PUEDE SER ELIMINADO</p>
        <p>HAY UN PANEL EN LA PAGINA MUEVALO O ELIMINELO PARA PODER PROCEDER CON LA OPERACION</p>` :
                pages.length === 1 ? `<p>NO PUEDE SER ELIMINADO</p>
                <p>TIENE QUE EXISTIR AL MENOS UNA PAGINA</p>` :
                    `DESEA ELIMINAR PAGINA ${title}`}
            accion={eliminar} />
        <Dropdown.Item onClick={() => setOpen(true)} eventKey="2" className='bg-danger'>
            <i className='fas fa-trash' /> ELIMINAR
        </Dropdown.Item>
    </>

}
function Ocultar({ id, visible }: any) {
    const { setPages, pages, updatePanel } = useContext(PanelContext);
    const ocultar = () => {
        const newP = pages.map(p => p.id === id ? { ...p, visible: !visible } : p);
        setPages(newP);
        ConConfig('page').push({ paginas: newP }, updatePanel);
    }
    return <Dropdown.Item onClick={ocultar} eventKey="2" className={`bg-${visible ? "success" : "warning"}`}>
        <i className={`fas fa-eye${visible ? '' : '-slash'}`} /> {visible ? "OCULTAR" : "MOSTRAR"}
    </Dropdown.Item>
}
function Edit({ id }: any) {
    const [open, setOpen] = useState(false)
    return <>
        <Dropdown.Item onClick={() => setOpen(true)} eventKey="1" className='bg-info'>
            <i className='fas fa-edit' /> EDITAR
        </Dropdown.Item>
        <ModalEdit id={id} open={open} close={() => setOpen(false)} />
    </>
}
function ModalEdit({ open, close, id }: any) {
    const { setPages, panels, pages, updatePanel } = useContext(PanelContext);
    const [nombre, setNombre] = useState('');
    const [oculto, setOculto] = useState<number[]>([]);
    const setPanel = useCallback((id: number) => setOculto(oculto.some(i => i === id) ? oculto.filter(i => i !== id) : [...oculto, id]), [oculto]);
    const guardar = useCallback(() => {
        const n = pages.find(p => p.id === id);
        if (n !== undefined) {
            const newP = pages.map(p => p.id === id ? { ...n, title: nombre, ocultar: oculto } : p);
            setPages(newP);
            ConConfig('page').push({ paginas: newP }, updatePanel);
            close();
        }
    }, [nombre, oculto])
    useEffect(() => {
        const p = pages.find(p => p.id === id);
        if (p !== undefined) { setNombre(p.title); setOculto(p.ocultar); }
    }, [open])
    return <Modal size="sm" animation centered show={open} onHide={close}>
        <Modal.Header closeButton>
            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center" >
                EDITAR
            </h6>
        </Modal.Header>
        <Modal.Body>
            <ReactTooltip />
            <Row>
                <Col md={12}>
                    <ControlText data-tip='CAMBIAR NOMBRE' maxLength={15} before="NOMBRE" setData={setNombre} value={nombre} /><br />
                    <Row style={{ height: 'fit-content', overflowY: 'auto' }}>
                        {panels.filter(({ title, estado, pagina }) => title !== '' && estado === 1 && pagina === id)
                            .map(p => <div className={css`&:hover{color: white;background-color:black;}`} style={{ width: '100%', padding: '.5rem .5rem .5rem .5rem', height: '45px' }} onClick={() => setPanel(p.id)}>
                                <b>{p.title}</b> <i className={`float-right fas fa-${oculto.some(i => i === p.id) ? 'eye-slash' : 'eye'}`} />
                            </div>)
                        }
                    </Row>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={guardar} className="float-right" variant="primary">
                <span className="fa fa-floppy-o" /> GUARDAR
            </Button>
        </Modal.Footer>
    </Modal >
}



