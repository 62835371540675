import { useContext } from 'react';
import { EditArea } from '../../Component/SlateCustom'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { PanelContext } from '../../../../Context/Panel';
import { getCSS } from './props';
import { State } from './index';
import { Panel as P } from '../../../../Models/Panel';
export function Panel({ config: { height, orientacion, backgroundColor, color, backgroundColorHover, colorHover, img, size }, setData }: { config: State, setData: (s: Partial<State>) => void }) {
    const { panels, pages } = useContext(PanelContext);
    return <Navbar expand="lg" className={getCSS(backgroundColor, color, backgroundColorHover, colorHover)}>
        <Container>
            <div className='d-flex navbar-brand'> 
                <img height={height + 'px'} className="img-responsive" src={img} alt="Jango" />
                <div>
                    <EditArea />
                </div>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`m${orientacion ? 'l' : 'r'}-auto`}>
                    {pages.length === 1 ?
                        panels.filter(({ title, estado }) => title !== '' && estado === 1)
                            .map(({ title, id }) => <Nav.Link href={`#panel${id}`}><b>{title}</b></Nav.Link>) :
                        pages.filter(({ visible }) => visible).map(p => <NavPage {...p} />)
                    }
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
}

function NavPage({ title, id, ocultar }: { title: string, id: number, ocultar: number[] }) {
    const { panels } = useContext(PanelContext);
    const isPanel = panels.some(({ pagina, estado }) => pagina === id && estado === 1);
    if (!isPanel) return <></>;
    const link = title.replaceAll(' ', '-');
    const PanelTitles = panels.filter(({ pagina, estado, title, id }) => pagina === id && estado === 1 && title !== '' && ocultar.every(i => i !== id));
    if (PanelTitles.length) return <NavDropdown title={title} id="nav-dropdown">
        {PanelTitles.map(p => <DropIten panel={p} link={link} />)}
    </NavDropdown>
    return <Nav.Link href={`/${link}`}><b>{title}</b></Nav.Link>
}

function DropIten({ panel: { title }, link }: { panel: P, link: string }) {
    const link2 = title.replaceAll(' ', '-');
    return <NavDropdown.Item href={`/${link}/${link2}`} eventKey={`${link2}.${link2}`}>{title}</NavDropdown.Item>
}

