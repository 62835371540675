import { RowConfig, ButtonConfig, ButtonColor, AddItens } from '../index'
import { ButtonGroup } from 'react-bootstrap';
import { IconSelector, UseIcon } from '../Icons/IconSelector';
interface Props {
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    removeItem: (d: number) => void
    addItem: () => void,
    cont: number,
    iten: any
}
export function ItenBasico({ iten: { id }, itenUp, itenDown, removeItem, addItem }: Props) {
    return <RowConfig>
        <ButtonGroup>
            <ButtonConfig tip='MOVER A LA IZQUIERDA' icon='fas fa-arrow-left' action={() => itenUp(id)} />
            <ButtonConfig tip='MOVER A LA DERECHA' icon='fas fa-arrow-right' action={() => itenDown(id)} />
        </ButtonGroup>
        <ButtonConfig className='ml-1' tip='QUITAR' icon='fas fa-trash' action={() => removeItem(id)} />
        <AddItens tip='AGREGAR UNA IMAGEN' add={addItem} />
    </RowConfig>
}
interface IconProps extends Props {
    config: { back: string, card?: string },
    setConfig: (d: any) => void
}
export function ItenIconBox(p: IconProps) {
    const { iten: { icon, id, fill, aux }, config: { back: iBack }, setConfig, cont } = p;
    return <>
        <ItenBasico {...p} />
        <RowConfig top={2}>
            <IconSelector value={icon} onChange={icon => setConfig({ icon, id })} />
            <ButtonColor tip='COLOR DEL ICONO' className='ml-1' invert={true} style={{ width: 32, backgroundColor: iBack !== 'none' ? aux : undefined }} color={fill} setData={(fill) => setConfig({ fill, id })} >
                <UseIcon width={15} height={15} icon={icon} fill={fill} />
            </ButtonColor>
            <ButtonColor tip='COLOR DE FONDO DEL ICONO' disable={iBack === 'none'} className='ml-1' style={{ width: 32 }} color={aux} setData={(aux) => setConfig({ aux, id })} >
                <UseIcon width={15} height={15} icon={icon} fill={fill} />
            </ButtonColor>
        </RowConfig>
        <RowConfig top={2}>
            <span className='ml-auto mr-auto'>
                {id} DE {cont}
            </span>
        </RowConfig>
    </>
}