/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { TextToHTML } from '../../../../Utiles/Texto';
import { EditArea } from '../../Component/SlateCustom'
import { State } from './index'
const s1 = ['570px', '780px'];
const s2 = ['990px', '1080px'];
export function Panel({ config: { imgPosicion, backgroundColor, img, edit, left, center, right, size }, setData }: { config: State, setData: (s: Partial<State>) => void }) {
    const CI = s1.some(s => s === size) ? 4 : s2.some(s => s === size) ? 3 : 2;
    const CI2 = CI === 4 ? 8 : CI === 3 ? 9 : 4;
    const CT = s1.some(s => s === size) ? 12 : s2.some(s => s === size) ? 6 : 3;
    const TextCols = ({ col, html, edit: e }: { col: number, html: any, edit: string }) => edit === e ?
        <div style={{ border: '5px solid #FF0000' }} className={`col-${col} h-100`}><EditArea /></div> :
        <div className={`col-${col} h-100`} onClick={() => setData({ edit: e })}>
            <TextToHTML text={html} />
        </div>
    const ImgCol = () => useMemo(() => <div className={`col-${CI}`}>
        <img width='100%' key={img} className="img-responsive" src={img} alt="Jango" />
    </div>, [size, img]);
    return <footer className="c-layout-footer c-layout-footer-1" style={{ backgroundColor }}>
        <div className="c-prefooter">
            <div className="container c-last">
                <div className="row">
                    {imgPosicion === 1 ? <ImgCol /> : <></>}
                    <TextCols edit='left' col={[1, 2].some(p => p === imgPosicion) ? CI2 : CT} html={left} />
                    {imgPosicion === 2 ? <ImgCol /> : <></>}
                    <TextCols edit='center' col={CT} html={center} />
                    {imgPosicion === 3 ? <ImgCol /> : <></>}
                    <TextCols edit='right' col={[3, 4].some(p => p === imgPosicion) ? CI2 : CT} html={right} />
                    {imgPosicion === 4 ? <ImgCol /> : <></>}
                </div>
            </div>
        </div>
    </footer>
}