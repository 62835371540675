import React from 'react'
import { Button, Modal, Row, Col, InputGroup, FormControl } from 'react-bootstrap'
import { conProducto, Producto, ProductoNull } from '../../../Models/Productos'
import DepartamentoSelector from '../Departamento/Selector'
import UnidadesSelector from '../Unidad/Selector'
import TipoSelector from '../Tipos/Selector'
import { toast } from 'react-toastify'
import { ControlNumber, ControlArea } from '../../../Utiles/FormControl'
import { ProductosContext, ProductosType } from '../../../Context/Producto'
interface Props {
    open: boolean
    close: () => void
    add: (p: Producto) => void
    dolar?: boolean
}
interface State extends Producto {
    monto_1: number,
    monto_2: number,
    monto_3: number,
    dolar: boolean
}
class FormProductos extends React.Component<Props, State>{
    error: any = [];
    utils = true;
    procesar = true;
    con = conProducto();
    constructor(p: Props) {
        super(p)
        this.state = { ...ProductoNull, monto_1: 1, monto_2: 1, monto_3: 1, dolar: p.dolar ? true : false };
        this.reDol = this.reDol.bind(this);
        this.reDol = this.reDol.bind(this);
        this.setState = this.setState.bind(this)
        this.setPrecio = this.setPrecio.bind(this)
        this.setUtilidad = this.setUtilidad.bind(this)

    }
    setDepartamento(departamento: string) {
        this.setState({ departamento })
    }

    setUnidad(unidad: number) {
        this.setState({ unidad })
    }

    setTipo(tipo: number) {
        this.setState({ tipo })
    }
    render() {
        this.setContextUtilidades();
        let { setState, setPrecio, setUtilidad, reDol,
            state: { exento, descripcion, precio1, precio2, precio3, costo, tipo, unidad, departamento, enser, dolar },
            props: { open, close } } = this;
        return <Modal size="xl" animation show={open} onHide={close}>
            <Modal.Header closeButton>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> NUEVO PRODUCTO</h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} as={InputGroup} hasValidation={this.error['departamento'] ? true : false} >
                        <DepartamentoSelector tipo='SELECCIONAR' defaultValue={departamento} change={this.setDepartamento.bind(this)} isInvalid={this.error['departamento'] ? true : false} />
                        <FormControl.Feedback type="invalid">
                            {this.error['departamento'] ? this.error['departamento'] : ''}
                        </FormControl.Feedback>
                    </Col>
                    <Col xs={12}><br />
                        <b>DESCRIPCIÓN</b>
                        <ControlArea error={this.error} rows={3} maxLength={300} value={descripcion} setData={setState} update="descripcion" />
                    </Col>
                    <Col xs={12}><br /></Col>
                    <Col md={4}>
                        <Button block onClick={() => { this.setState({ enser: enser === 1 ? 0 : 1 }) }} variant={enser === 0 ? 'success' : 'danger'}>
                            PARA LA VENTA: {enser === 0 ? 'SI' : 'NO (ENSER)'}
                        </Button>
                    </Col>
                    <Col xs={4} as={InputGroup} hasValidation={this.error['unidad'] ? true : false} >
                        <UnidadesSelector defaultValue={unidad} change={this.setUnidad.bind(this)} isInvalid={this.error['unidad'] ? true : false} />
                        <FormControl.Feedback type="invalid">
                            {this.error['unidad'] ? this.error['unidad'] : ''}
                        </FormControl.Feedback>
                    </Col>
                    <Col xs={4} as={InputGroup} hasValidation={this.error['tipo'] ? true : false} >
                        <TipoSelector defaultValue={tipo} change={this.setTipo.bind(this)} isInvalid={this.error['tipo'] ? true : false} />
                        <FormControl.Feedback type="invalid">
                            {this.error['tipo'] ? this.error['tipo'] : ''}
                        </FormControl.Feedback>
                    </Col>
                    <Col md={2}><br />
                        <Button block onClick={() => { this.setState({ exento: !exento }) }} variant={exento ? 'success' : 'primary'}>
                            EXENTO: {exento ? 'SI' : 'NO'}
                        </Button>
                    </Col>
                    <Col md={2}><br />
                        <Button block onClick={() => { this.setState({ dolar: !dolar }) }} variant={dolar ? 'warning' : 'primary'}>
                            MONTOS EN: {dolar ? 'USD' : 'BS'}
                        </Button>
                    </Col>
                    <Col xs={8} style={{ height: 54 }} ><br />
                        <ControlNumber error={this.error} after={dolar ? 'USD' : 'BS'} min={.01} step=".01" before='COSTO' update='costo'
                            value={reDol(costo)} setData={this.setCosto.bind(this)} />
                    </Col>
                    <Col xs={4}><br />
                        <ControlNumber min={.01} step=".01" after="%" before="UTILIDAD 1" value={precio1} setData={setUtilidad} update="precio1" />
                    </Col>
                    <Col xs={8}><br />
                        <ControlNumber min={.01} step=".01" after={dolar ? 'USD' : 'BS'} before="PRECIO 1" value={this.getPrecio(precio1)} setData={setPrecio} update="monto_1" />
                    </Col>
                    <Col xs={4}><br />
                        <ControlNumber min={.01} step=".01" after="%" before="UTILIDAD 2" value={precio2} setData={setUtilidad} update="precio2" />
                    </Col>
                    <Col xs={8}><br />
                        <ControlNumber min={.01} step=".01" after={dolar ? 'USD' : 'BS'} before="PRECIO 2" value={this.getPrecio(precio2)} setData={setPrecio} update="monto_2" />
                    </Col>
                    <Col xs={4}><br />
                        <ControlNumber min={.01} step=".01" after="%" before="UTILIDAD 3" value={precio3} setData={setUtilidad} update="precio3" />
                    </Col>
                    <Col xs={8}><br />
                        <ControlNumber min={.01} step=".01" after={dolar ? 'USD' : 'BS'} before="PRECIO 3" value={this.getPrecio(precio3)} setData={setPrecio} update="monto_3" />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.create.bind(this)} variant="primary">
                    <span className="fa fa-floppy-disk" /> Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    }
    setCosto({ costo }: { costo: number }) {
        this.setState({ costo: this.rBol(costo) });
    }
    rBol = (p: number) => {
        const { context: { tasa }, state: { dolar } } = this;
        return (dolar) ? p * tasa : p;
    }
    reDol(p: number) {
        const { context: { tasa }, state: { dolar } } = this;
        return (dolar) ? p / tasa : p;
    }
    getPrecio(price: number) {
        const { reDol } = this;
        let utilidad = (price / 100) + 1
        return reDol(this.state.costo * utilidad);
    }
    setPrecio(d: { monto_1?: number, monto_2?: number, monto_3?: number }) {
        const { rBol } = this;
        if (d.monto_1 !== undefined)
            this.setState({ monto_1: rBol(d.monto_1), precio1: this.getUtilidad(rBol(d.monto_1)) });
        if (d.monto_2 !== undefined)
            this.setState({ monto_2: rBol(d.monto_2), precio2: this.getUtilidad(rBol(d.monto_2)) });
        if (d.monto_3 !== undefined)
            this.setState({ monto_3: rBol(d.monto_3), precio3: this.getUtilidad(rBol(d.monto_3)) });
    }
    getUtilidad(price: number) {
        return ((price / this.state.costo) - 1) * 100;
    }
    setUtilidad(d: { precio1?: number, precio2?: number, precio3?: number }) {
        if (d.precio1)
            this.setState({ monto_1: this.getPrecio(d.precio1), precio1: d.precio1 });
        if (d.precio2)
            this.setState({ monto_2: this.getPrecio(d.precio2), precio2: d.precio2 });
        if (d.precio3)
            this.setState({ monto_3: this.getPrecio(d.precio3), precio3: d.precio3 });
    }
    create() {
        this.error = [];
        let procesar = true;
        let { descripcion, tipo, unidad, departamento, costo, exento } = this.state;
        if (descripcion === 'descripcion') {
            this.error['descripcion'] = 'DEBE COLOCAR UNA DESCRIPCION';
            procesar = false;
        } if (departamento === 'TODO') {
            this.error['departamento'] = 'DEBE SELECCIONAR UN DEPARTAMENTO';
            procesar = false;
        } if (tipo === -1) {
            this.error['tipo'] = 'DEBE SELECCIONAR UN TIPO DE PRODUCTO';
            procesar = false;
        } if (unidad === -1) {
            this.error['unidad'] = 'DEBE SELECCIONAR UNA UNIDAD DE MEDIDA';
            procesar = false;
        } if (costo === 0) {
            this.error['costo'] = "DEBE COLOCAR EL COSTO";
            procesar = false;
        }
        this.forceUpdate();
        if (procesar) {
            if (this.procesar) {
                this.procesar = false;
                this.con.nuevo({ ...this.state, exento: exento ? 1 : 0 }, data => {
                    this.props.add(data);
                    this.procesar = true;
                    this.setState({ ...ProductoNull });
                    let { precio1, precio2, precio3 } = this.context as ProductosType;
                    this.setUtilidad({ precio1, precio2, precio3 })
                    this.props.close();
                }, this.setError)
            }
        }
    }
    setContextUtilidades() {
        if (this.utils) {
            let { precio1, precio2, precio3 } = this.context as ProductosType;
            if (precio1 > 1) {
                this.setUtilidad({ precio1, precio2, precio3 })
                this.utils = false;
            }
        }
    }
    setError = (err: any) => {
        if (err.response) {
            let { errores } = err.response;
            errores.forEach((err: any) => {
                if (err.sql) {
                    err.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
                } else {
                    Object.keys(err).forEach(t => this.error[t] = err[t])
                }
            })
            this.forceUpdate();
        }
    }
    componentWillReceiveProps({ dolar }: Props) {
        if (this.state.dolar !== dolar)
            this.setState({ dolar: dolar ? true : false })
    }
}
FormProductos.contextType = ProductosContext;
export default FormProductos