
import React from 'react'
import { Button } from 'react-bootstrap'
import { ProductosContext, ProductosType } from '../../../../Context/Producto'
import { Detalles } from '../../../../Models/General'
import { Producto } from '../../../../Models/Productos'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'
import { InputNumber } from '../../../../Utiles/Inputs'

interface Props {
    producto: Producto,
    add: (iten: Detalles) => void
}
class FilaUtilidad extends React.Component<Props, Detalles>{
    constructor(p: Props) {
        super(p)
        this.state = { ...p.producto, precio: p.producto.costo, unidades: 1, comentario: '' };
        this.setState = this.setState.bind(this);
    }
    render() {
        let { state: { codigo, descripcion, precio1, precio2, precio3 }, setState, } = this;
        return <tr>
            <THFila width='90px'>{codigo}</THFila>
            <THFila width='auto'>{descripcion}</THFila>
            <THInput width='150px'><InputNumber min={.01} step='0.01' setData={setState} value={precio1} update="precio1" /></THInput>
            <THInput width='150px'><InputNumber min={.01} step='0.01' setData={setState} value={precio2} update="precio2" /></THInput>
            <THInput width='150px'><InputNumber min={.01} step='0.01' setData={setState} value={precio3} update="precio3" /></THInput>
            <THInput width='70px'>
                <Button data-tip="AGREGAR PRODUCTO" onClick={() => { this.props.add(this.state) }} variant="primary">
                    <i className="fa fa-plus" />
                </Button>
            </THInput>
        </tr>
    }
    componentDidMount() {
        let { precio1, precio2, precio3 } = this.context as ProductosType;
        this.setState({ precio1, precio2, precio3 })
    }
}
FilaUtilidad.contextType = ProductosContext;
export default FilaUtilidad