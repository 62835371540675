import React, { useContext, useState } from 'react'
import { Button, FormControl } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { ProductosContext, ProductosType } from '../../../../Context/Producto'
import { Detalles } from '../../../../Models/General'
import { Producto } from '../../../../Models/Productos'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'
import { numberFormat } from '../../../../Utiles/Formatos'
import { InputNumber } from '../../../../Utiles/Inputs'

interface Props {
    producto: Producto,
    add: (iten: Detalles) => void
    dolar?: boolean
}
export default function FilaCompras({ producto, add, dolar = false }: Props) {
    const { findProVen, tasa } = useContext(ProductosContext) as ProductosType;
    const { codigo, descripcion, costo, inventario, cantidad } = findProVen(producto, dolar);
    const [unidades, setUnidades] = useState(1);
    const [precio, setPrecio] = useState(parseFloat(((dolar) ? costo * tasa : costo).toFixed(2)));
    const pre = (dolar) ? precio / tasa : precio;
    return <tr>
        <THFila width='90px'>{codigo}</THFila>
        <THFila width='auto'>{descripcion}</THFila>
        <THInput width='120px' tip={(inventario === 1) ? 'NO APLICA' : 'DISPONIBLE: ' + numberFormat(cantidad)}>
            <InputNumber min={.01} step='0.01' setData={setUnidades} value={unidades} />
        </THInput>
        <THInput width='180px'>
            <InputNumber list={costo + 'list'} value={pre} step='0.01'
                setData={(p: number) => setPrecio((dolar) ? p * tasa : p)} />
            <ReactTooltip />
        </THInput>
        <THInput width='230px'><FormControl style={{ textAlign: 'right' }} disabled
            value={numberFormat((parseFloat(pre.toFixed(2)) * unidades))} /> </THInput>
        <THInput width='70px'>
            <Button data-tip="AGREGAR PRODUCTO" variant="primary"
                onClick={() => add({ ...producto, precio, unidades, comentario: '' })}>
                <i className="fa fa-plus" />
            </Button>
        </THInput>
    </tr>
}