import { getCSS, getWidth } from './props';
import { EditArea } from '../../Component/SlateCustom'
import { Row, Col, } from 'react-bootstrap';
import { State } from './index';

export function Panel({ config: { image, color, xs, sm, md, lg, xl, xxl, size }, setConfig }: { config: State, setConfig: (d: Partial<State>) => void }) {
    return <div className={getCSS(image)}>
        <Row className={getWidth(xs, sm, md, lg, xl, xxl, size)}>
            <Col style={{ backgroundColor: color, height: '100%' }}>
                <div style={{ height: '100%', width: '100%' }}><EditArea /></div>
            </Col>
        </Row>
    </div>
}