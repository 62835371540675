import { TitleCard, CardConfig, RowConfig, ButtonConfig, BoxConfig, ButtonColor } from '../../../Component';
import { InputNumber } from '../../../../../Utiles/Inputs';
import { Button, ButtonGroup, InputGroup } from 'react-bootstrap';
import { iten } from '../index';
import { Inserts } from '../../../Component/SlateCustom';
import { ColorButton, FontStyle, MarkMenu } from '../../../../../Utiles/Texto/MarkProps';
import { AlingMenu, ListMenu, TabsMenu, TypeMenu } from '../../../../../Utiles/Texto/BlockProps';
export function Position({ value: p, setData }: { value: number, setData: (d: number) => void }) {
    return <ButtonGroup vertical data-tip='POSICION DEL AREA DE TEXTO'>
        <ButtonGroup>
            <Button onClick={() => setData(0)} active={p === 0} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i style={{ transform: 'rotate(45deg)' }} className="fas fa-arrow-left" />
            </Button>
            <Button onClick={() => setData(1)} active={p === 1} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i className="fas fa-arrow-up" />
            </Button>
            <Button onClick={() => setData(2)} active={p === 2} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i style={{ transform: 'rotate(-45deg)' }} className="fas fa-arrow-right" />
            </Button>
        </ButtonGroup>
        <ButtonGroup>
            <Button onClick={() => setData(3)} active={p === 3} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i className="fas fa-arrow-left" />
            </Button>
            <Button onClick={() => setData(4)} active={p === 4} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <b>O</b>
            </Button>
            <Button onClick={() => setData(5)} active={p === 5} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i className="fas fa-arrow-right" />
            </Button>
        </ButtonGroup>
        <ButtonGroup>
            <Button onClick={() => setData(6)} active={p === 6} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i style={{ transform: 'rotate(-45deg)' }} className="fas fa-arrow-left" />
            </Button>
            <Button onClick={() => setData(7)} active={p === 7} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i className="fas fa-arrow-down" />
            </Button>
            <Button onClick={() => setData(8)} active={p === 8} size='sm' style={{ width: 30, height: 30 }} variant='outline-dark'>
                <i style={{ transform: 'rotate(45deg)' }} className="fas fa-arrow-right" />
            </Button>
        </ButtonGroup>
    </ButtonGroup>
}
export function TextMenu(p: { iten: iten, setConfig: (d: Partial<iten>) => void }) {
    const { id, position, disable } = p.iten;
    return <CardConfig width='395px'>
        <RowConfig>
            <Position value={position} setData={position => p.setConfig({ position, id })} />
            <BoxConfig margin='0 0 0 5px' width='295px'>
                <RowConfig>
                    <MarkMenu />
                    <ColorButton left={1} />
                    <AlingMenu left={1} />
                    <ButtonConfig icon="fas fa-ad" active={!disable} className='ml-1'
                        tip={`COLOCAR FONDO PARA EL TEXTO ${disable ? 'NO' : 'SI'}`}
                        action={() => p.setConfig({ disable: !disable })} />
                </RowConfig>
                <RowConfig top={2}>
                    <TabsMenu left={0} />
                    <ListMenu left={1} />
                    <TypeMenu left={1} />
                    <Inserts />
                </RowConfig>
                <RowConfig top={2}>
                    <FontStyle left={0} width='100%' />
                </RowConfig>
            </BoxConfig>
        </RowConfig>
        <TitleCard>TEXTO Y FUENTE</TitleCard>
    </CardConfig>
}
export function BackMenu({ iten, setConfig }: { iten: iten, setConfig: (d: Partial<iten>) => void }) {
    const { back, bordeColor, id, borde, redondius, disable } = iten;
    return <CardConfig width='78'>
        <RowConfig>
            <ButtonGroup>
                <ButtonColor tip='COLOR DE FONDO DEL AREA DE TEXTO' disable={disable} color={back} setData={(back) => setConfig({ back, id })} >
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <ButtonColor tip='COLOR DEL BORDE DEL AREA DE TEXTO' disable={disable} disableAlpha={true} color={bordeColor} setData={(bordeColor) => setConfig({ bordeColor, id })} >
                    <i style={{ border: '2px solid currentColor', borderRadius: '2px', width: '13px', height: '13px' }} className="far"></i>
                </ButtonColor>
            </ButtonGroup>
        </RowConfig>
        <RowConfig top={2}>
            <InputGroup data-tip='GROSOR DEL BORDE DEL AREA DE TEXTO' className='input-group-sm' style={{ height: '24px' }}>
                <InputGroup.Prepend style={{ height: '24px', width: '30px' }}>
                    <InputGroup.Text style={{ height: '24px', width: '30px' }}>
                        <i style={{ border: '2px solid currentColor', width: '13px', borderRadius: '2px', height: '13px' }} className="far"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <InputNumber disabled={disable} decimal={0} style={{ height: 24 }} value={borde} setData={borde => setConfig({ borde, id })} />
            </InputGroup>
        </RowConfig>
        <RowConfig top={2}>
            <InputGroup data-tip='REDONDEO EN LAS ESQUINAS DEL AREA DE TEXTO' className='input-group-sm' style={{ height: '24px' }}>
                <InputGroup.Prepend style={{ height: '24px', width: '30px' }}>
                    <InputGroup.Text style={{ height: '24px', width: '30px' }}>
                        <i className="fas fa-spinner-third"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <InputNumber disabled={disable} decimal={0} style={{ height: 24 }} value={redondius} setData={redondius => setConfig({ redondius, id })} />
            </InputGroup>
        </RowConfig>
        <TitleCard>FONDO</TitleCard>
    </CardConfig>

}

