import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
import { Icon2 } from '../../../Component/Icons/IconSelector';
import { TextToHTML } from '../../../../../Utiles/Texto';

export function Itens({
    config: { seleted, card, border1, border, back: iback, orientation, font },
    iten: { id, html, icon, color, back, aux, fill }, seled }: { iten: iten, config: State, seled: (i: number) => void }) {
    const seletedMe = () => {
        seled(id);
        setTimeout(() => {
            const div = document.getElementById("selectedMe")
            if (div) div.scrollIntoView();
        }, 10)
    }
    return <div id={seleted === id ? 'selectedMe' : undefined} onClick={seletedMe} style={{
        border: seleted === id ? '5px solid #FF0000' : (card !== 'none' && border1) ? `5px solid ${fill}` : undefined,
        backgroundColor: card !== 'none' ? back : undefined,
        borderRadius: card === 'rounded' ? `${font / 3}rem` : undefined,
        padding: '10px 10px 10px 10px',
    }} className={`d-flex flex-${orientation}`}>
        <div className={
            orientation.indexOf('column') > -1 ?
                "d-flex justify-content-center" :
                "d-flex align-items-center h-100"}>
            <Icon2 icon={icon} font={font} fill={fill} back={iback} border={border} aux={aux} />
        </div>
        {seleted === id ? 
            <div style={{ color }} className="caption  d-flex align-items-center h-100 p-1"><EditArea key={'edit' + id} /></div> :
            <div style={{ color }} className="caption  d-flex align-items-center h-100 p-1"><TextToHTML text={html} /></div>
        }
    </div>
}