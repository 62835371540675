import { useContext, useState, useEffect } from 'react';
import { Button, ButtonGroup, FormControl } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip';
import { ProductosContext, ProductosType } from '../../Context/Producto';
import { SeccionContext, SeccionType } from '../../Context/Seccion';
import THFila, { THInput } from '../Componentes/THFila';
import { Detalles } from '../../Models/General';
import { numberFormat } from '../Formatos';
import { ControlText } from '../FormControl';
import { InputNumber } from '../Inputs';
interface Props {
    data: Detalles
    edit: (d: Detalles) => void
    remove: (iten: Detalles) => void
    dolar?: boolean
    venta?: boolean
    nota?: boolean
}
export default function ProductosVentas({ data, edit, remove, dolar = false, venta = false, nota = false }: Props) {
    const { findProVen, tasa } = useContext(ProductosContext) as ProductosType;
    const { data: { User: { nivel } } } = useContext(SeccionContext) as SeccionType;
    const { codigo, descripcion, costo, cantidad, monto1, monto2, monto3, inventario } = findProVen(data, dolar);
    const [unidades, setUnidades] = useState(data.unidades);
    const [precio, setPrecio] = useState(data.precio);
    const [comentario, setComentario] = useState(data.comentario);
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line
    useEffect(() => { edit({ ...data, unidades, precio, comentario }) }, [unidades, precio, comentario]);
    const pre = (dolar) ? precio / tasa : precio;
    const valStock = ((unidades > cantidad) && (inventario !== 1)) && venta;
    const valPrecio = (monto1 > pre) && venta;
    const className = valStock ? 'bg-danger' : valPrecio ? 'bg-warning' : undefined;
    const tip = valStock ? 'NO TIENES DISPONIBILIDAD SUFICIENTE' : valPrecio ? 'ESTAS POR DEBAJO DEL PRECIO MINIMO' : undefined;
    return <>
        <tr className={className}>
            <THFila tip={tip} width='90px'>{codigo}</THFila>
            <THFila tip={tip} width='auto'>{descripcion}</THFila>
            <THInput tip={tip} width='120px'><InputNumber min={0.01} setData={setUnidades} value={unidades} /></THInput>
            <THInput tip={tip} width='120px'><FormControl style={{ textAlign: 'right' }} disabled value={(inventario === 1) ? 'NO APLICA' : numberFormat(cantidad)} /></THInput>
            <THInput tip={tip} width='180px'>
                <InputNumber value={pre} min={(nivel === 0) ? costo : monto1} step='0.01'
                    list={costo + 'list'} setData={(p: number) => setPrecio((dolar) ? p * tasa : p)}
                    data-tip={'MINIMO: ' + numberFormat((nivel === 0) ? costo : monto1)} />
                <datalist id={costo + 'list'} key={costo + 'list' + dolar}>
                    <option>{monto1.toFixed(2)}</option>
                    <option>{monto2.toFixed(2)}</option>
                    <option>{monto3.toFixed(2)}</option>
                </datalist>
            </THInput>
            <THInput tip={tip} width='230px'><InputNumber disabled setData={() => { }} value={parseFloat(pre.toFixed(2)) * unidades} update="p" /></THInput>
            <THInput tip={tip} width='110px'>
                <ReactTooltip />
                <ButtonGroup>
                    {venta ? <></> : <Button variant="info" onClick={() => setOpen(!open)} data-tip="DEJAR NOTA">
                        <span className="fa fa-edit"></span>
                    </Button>}
                    <Button variant="danger" onClick={() => remove(data)} data-tip="QUITAR PRODUCTO">
                        <i className="fa fa-ban"></i>
                    </Button>
                </ButtonGroup>
            </THInput>
        </tr><tr></tr>
        {open ? <tr className={className}>
            <td colSpan={7}>
                <ControlText maxLength={250} before="NOTA INTERNA" setData={setComentario} value={comentario} />
            </td>
        </tr> : <tr></tr>}
    </>
}