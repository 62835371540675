import { Card, Row, ButtonGroup } from 'react-bootstrap';
import { SizeViewSelector, ButtonColor, TitleCard, Guardar, CardConfig, RowConfig, ButtonConfig } from '../../Component';
import { CropModal } from '../../../Media/CropModal';
import { TBElement } from '../../Component/SlateCustom';
import { State } from './index'
interface Props { save: () => void, config: State; setConfig: (d: Partial<State>) => void }
export function Config({ config, setConfig, save }: Props) {
    const { size, backgroundColor, imgPosicion, img } = config;
    return <Card style={{ backgroundColor: '#ccc', padding: '7px 7px 7px 7px' }}>
        <Row style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
            <Guardar guardar={save} />
            <CardConfig width='70px' >
                <RowConfig>
                    <ButtonGroup>
                        <ButtonConfig tip='MOVER IMAGEN A LA IZQUIERDA' icon="fas fa-angle-left"
                            action={() => setConfig({ imgPosicion: imgPosicion > 1 ? imgPosicion - 1 : 1 })} />
                        <ButtonConfig tip='MOVER IMAGEN A LA DERECHA' icon="fas fa-angle-right"
                            action={() => setConfig({ imgPosicion: imgPosicion < 4 ? imgPosicion + 1 : 4 })} />
                    </ButtonGroup>
                </RowConfig>
                <RowConfig top={2}>
                    <CropModal img={img} setImg={(img) => setConfig({ img })} aspect={1} />
                </RowConfig>
                <RowConfig top={2}>
                    <ButtonColor tip='COLOR DE FONDO DEL PIE DE PAGINA'
                     style={{ width: 32 }} color={backgroundColor}
                     setData={(backgroundColor) => setConfig({ backgroundColor })}>
                        <i className="fas fa-paint-roller"></i>
                    </ButtonColor>
                </RowConfig>
                <TitleCard>IMAGEN</TitleCard>
            </CardConfig>
            <TBElement />
            <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
        </Row >
    </Card >
}