import React from "react"
import Gauge from "../../../Utiles/Charts/Gauge";
import NuevoEquilibrio from './NuevoEquilibrio'
export default class Equilibrio extends React.Component<{ p: string[] }, { equilibrio: number, ventas: number, open: boolean }>{
    state = { equilibrio: 100, ventas: 0, open: false };
    open = true;
    render() {
        const { state: { equilibrio, ventas, open }, props: { p } } = this;
        return <div className="center Equilibrio">
            {p.some(d => d === 'CONFIG') ? <NuevoEquilibrio open={open} close={() => this.close()} /> : <></>}
            <div>
                <Gauge
                    label='Bs'
                    value={ventas}
                    red={0}
                    yellow={equilibrio / 2}
                    green={equilibrio}
                />
                <h5>PUNTO DE EQUILIBRIO</h5>
            </div>
        </div>
    }
    async send() {
        let date = new Date();
        let año = date.getFullYear(), mes = date.getMonth() + 1;
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch('/api/Factura/equilibrio/' + año + '/' + mes, { headers: { 'Authorization': 'Bearer ' + token } });
            let datos = await response.json();
            this.setState({ equilibrio: parseFloat(datos[0]), ventas: Math.floor(datos[1]) });
            if (parseFloat(datos[0]) === 0 && this.open) {
                this.open = false;
                this.setState({ equilibrio: 100, open: true });
            }
        } catch (error) {
            console.log(error);
            this.setState({ equilibrio: 100, ventas: 0 });
        }
    }
    close = () => {
        this.setState({ open: false });
        this.send();
    }
    componentDidMount() { this.send() }
}