import { Col } from 'react-bootstrap';
import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
import { PreviewSize } from '../../../Ver/PreviewSize';
import { Icon2 } from '../../../Component/Icons/IconSelector';
import { TextToHTML } from '../../../../../Utiles/Texto';
interface Props {
    iten: iten,
    config: State,
    setConfig: (p: Partial<State>) => void
}
export function Itens({ iten: { panel, id }, config: { size } }: Props) {
    if (panel === undefined) return <PreviewSize size={size} panel={{ type: '' } as any} key={`panelNOT${id}`} />
    return <PreviewSize size={size} panel={panel} key={`panel${panel.id}${id}`} />
}
export function Tap({ config, setConfig, iten }: Props) {
    const { id, html, icon, fill, aux } = iten;
    const { seleted, orientation, back, border, font } = config;
    const zIndex = seleted === id ? 20 : id > seleted ? 20 - (id - seleted) : 20 - (seleted - id);
    return <Col style={{ border: (seleted === id) ? '3px solid red' : '', zIndex }} as='li' className={`p-1 d-flex flex-${orientation} ${seleted === id ? 'active' : ''}`}>
        <div className={"icon-content text-center"}>
            <Icon2 icon={icon} font={font} fill={fill} back={back} border={border} aux={aux} />
        </div>
        {(seleted === id) ?
            <div style={{ minWidth: 'calc(100% - 3rem)' }} className="d-flex align-items-center h-100 p-1" ><EditArea key={'edit' + id} /></div> :
            <div style={{ minWidth: 'calc(100% - 3rem)' }} onClick={() => setConfig({ seleted: id })} className="d-flex align-items-center h-100 p-1"><TextToHTML text={html} /></div>
        }
    </Col>
}