import React from 'react'
import { Row, Col, Button, InputGroup, ButtonGroup, Card, Accordion, FormControl } from 'react-bootstrap'
import FormCliente from './Form'
import { Cliente, ClienteNull, conCliente } from '../../../Models/Ventas'
import { busqueda } from '../../../Utiles/Filtros'
import { ClientesContext } from '../../../Context/Ventas/Cliente'
interface Props {
    Cliente: string
    add: (Cliente: string) => void
}
interface State {
    cliente: Cliente
    buscarNumbre: string
    buscarCodigo: string
    nuevo: boolean
}
class ClienteSelector extends React.Component<Props, State> {
    con = conCliente();
    nombre = React.createRef<HTMLInputElement>();
    codigo = React.createRef<HTMLInputElement>();
    constructor(p: Props) {
        super(p)
        this.state = { nuevo: false, cliente: ClienteNull, buscarCodigo: '', buscarNumbre: '' }
    }
    render() {
        const { state: { nuevo, cliente, buscarCodigo, buscarNumbre } } = this
        return <Card as={Accordion} id="tabla">
            <FormCliente cliente={null} title={"NUEVO CLIENTE"} open={nuevo} add={this.seleccion.bind(this)} close={this.close.bind(this)} />
            <Card.Body>
                <Row><Col xs={12}><h4 className='mr-auto'><b>DATOS DEL CLIENTE</b></h4></Col></Row>
                <Row>
                    <InputGroup className="mb-3" style={{ width: '30%', marginRight: '15px', marginLeft: '10px' }}>
                        <InputGroup.Prepend >
                            <InputGroup.Text>RIF</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl style={{ width: 'calc(100% - 60px)', position: 'relative' }} list="inputCodigo"
                            ref={this.codigo}
                            onKeyUp={this.limpiarCodigo.bind(this)}
                            onChange={this.setBuscarCodigo.bind(this)}
                            defaultValue={buscarCodigo} />
                    </InputGroup>
                    <datalist id="inputCodigo">
                        {this.getList(busqueda(this.context.Clientes, buscarCodigo, ['codigo']), 'codigo')}
                    </datalist>
                    <InputGroup className="mb-3" style={{ width: 'calc(70% - 160px)', marginRight: '15px' }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text>NOMBRE</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl style={{ width: 'calc(100% - 100px)' }} list="inputNombre" className="form-control"
                            ref={this.nombre}
                            onKeyUp={this.limpiarNombre.bind(this)}
                            onChange={this.setBuscarNombre.bind(this)}
                            defaultValue={buscarNumbre} />
                    </InputGroup>
                    <datalist id="inputNombre">
                        {this.getList(busqueda(this.context.Clientes, buscarCodigo, ['nombre']), 'nombre')}
                    </datalist>
                    <ButtonGroup style={{ width: "110px" }} className="float-right mb-3">
                        <Button data-tip="VER MAS" as={Accordion.Toggle} eventKey="1">
                            <i className="fa fa-eye"></i>
                        </Button>
                        <Button data-tip="NUEVO CLIENTE" onClick={() => { this.setState({ nuevo: true }) }} variant="info">
                            <i className="fa fa-edit"></i>
                        </Button>
                    </ButtonGroup>
                </Row>
                <Accordion.Collapse eventKey='1'>
                    <Row>
                        <Col xs={12}>
                            <InputGroup style={{ width: '100%' }} className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>NOMBRE</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl disabled value={cliente.correo} />
                            </InputGroup>
                        </Col>
                        <Col xs={12}>
                            <InputGroup style={{ width: '100%' }} className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>TELEFONO</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl disabled value={cliente.telefono} />
                            </InputGroup>
                        </Col>
                        <Col xs={12}>
                            <InputGroup style={{ width: '100%' }} className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>PERSONA DE CONTACTO</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl disabled value={cliente.contacto} />
                            </InputGroup>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <b>DICECCION</b>
                            <FormControl rows={3} as='textarea' disabled value={cliente.direccion} />
                        </Col>
                    </Row>
                </Accordion.Collapse>
            </Card.Body>
        </Card>
    }
    seleccion(c: Cliente) {
        this.set();
        this.props.add(c.codigo);
        if (this.nombre.current) this.nombre.current.value = c.nombre;
        if (this.codigo.current) this.codigo.current.value = c.codigo;
        this.setState({ cliente: c, buscarCodigo: c.codigo, buscarNumbre: c.nombre, nuevo: false });
    }
    setBuscarNombre(e: React.ChangeEvent<HTMLInputElement>) {
        let b = e.target.value.toUpperCase()
        this.setState({ buscarNumbre: b });
        const cli: Cliente = this.context.Clientes.find((c: Cliente) => c.nombre === b);
        if (cli !== undefined) this.props.add(cli.codigo);
    }
    setBuscarCodigo(e: React.ChangeEvent<HTMLInputElement>) {
        let b = e.target.value.toUpperCase()
        this.setState({ buscarCodigo: b });
        const cli: Cliente = this.context.Clientes.find((c: Cliente) => c.codigo === b);
        if (cli !== undefined) this.props.add(cli.codigo);
    }
    limpiarNombre(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.keyCode === 8) {
            e.currentTarget.value = '';
            this.limpiar();
        }
    }
    limpiarCodigo(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.keyCode === 8) {
            e.currentTarget.value = '';
            this.limpiar();
        }
    }
    limpiar() {
        this.setState({ cliente: ClienteNull, buscarCodigo: '', buscarNumbre: '' })
        if (this.nombre.current)
            this.nombre.current.value = "";
        if (this.codigo.current)
            this.codigo.current.value = "";
    }
    getList(lista: Cliente[], label: 'codigo' | 'nombre') {
        return lista.map(c => <option value={c[label]} key={label + c.codigo}></option>)
    }
    close() {
        this.setState({
            nuevo: false
        })
    }
    componentWillReceiveProps(p: Props) {
        if (this.state.cliente.codigo !== p.Cliente) {
            const cli: Cliente = this.context.Clientes.find((c: Cliente) => c.codigo === p.Cliente);
            if (cli !== undefined) {
                if (this.nombre.current) this.nombre.current.value = cli.nombre;
                if (this.codigo.current) this.codigo.current.value = cli.codigo;
                this.setState({ cliente: cli, buscarCodigo: cli.codigo, buscarNumbre: cli.nombre });
            }
        }
    }
    componentDidMount() {
        this.set();
    }
    set() {
        this.context.actCliente();
    }
}
ClienteSelector.contextType = ClientesContext;
export default ClienteSelector