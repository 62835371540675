import { ClientesContext } from '../../Context/Ventas/Cliente';
import { Cliente } from '../../Models/Ventas';
import FilaProto from '../Prototipos/Fila/Proto';
class FilaVentas extends FilaProto<{},{}> {
    print = false;
    tip() {
        let cli: Cliente = this.context.findCliente(this.props.data.rif);
        if (cli)
            return "<h9 style='color: #5bc0de'>DATOS DE CONTACTO</h9><br />" +
                "<b>PERSONA DE CONTACTO:</b><br />" +
                cli.contacto + "<br />" +
                "<b>CORREO ELECTRONICO:</b><br />" +
                cli.correo + "<br />" +
                "<b>TELEFONO:</b><br />" +
                cli.telefono
        return '';
    }
}
FilaVentas.contextType = ClientesContext;
export default FilaVentas