import React from "react"
import Area from "../../../Utiles/Charts/Area";
export default class Ventas extends React.Component<{}, { dias: number[][] }>{
    constructor(p: any) {
        super(p);
        this.state = { dias: [] };
    }
    render() {
        return <div className="ventas">
            <Area label={['DIA', 'VENTAS']} values={this.get()} title="VENTAS DEL MES" hAxisTitle="DIA DEL MES" />
        </div>
    }
    get() {
        return this.state.dias.sort((a, b) => a[0] - b[0]).map(d => [d[0] + '', d[1]]);
    }
    async send() {
        let date = new Date();
        let año = date.getFullYear(),
            mes = date.getMonth() + 1;
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch('/api/Factura/linea/mes/'+ año + '/' + mes, { headers: { 'Authorization': 'Bearer ' + token } });
            let dias = await response.json();
            this.setState({ dias });
        } catch (error) {
            console.log(error);
            this.setState({ dias: [] });
        }
    }
    componentDidMount() { this.send() }
}