import { InputText } from '../../../../Utiles/Inputs';
import { SizeViewSelector, Title, CardMain, ButtonColor, TitleCard, Guardar, CardConfig, RowConfig } from '../../Component';
import { TBElement } from '../../Component/SlateCustom'
import { State } from './index'
interface Props {
    save: () => void
    title: string
    config: State
    setTitle: (d: string) => void
    setConfig: (d: any) => void
}
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { color, size, mapa } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='302px'>
            <RowConfig>
                <Title setData={setTitle} title={title} width='298px' />
            </RowConfig>
            <RowConfig top={2}>
                <InputText data-tip='COLOCAR EL CODIGO DEL MAPA DE GOOGLE' style={{ height: '24px' }} className='form-control-sm' setData={(s) => setConfig({ mapa: setLink(s) })} value={mapa} />
            </RowConfig>
            <RowConfig top={2}>
                <ButtonColor tip='COLOR DEL AREA DE TEXTO' color={color} setData={(color) => setConfig({ color })}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
            </RowConfig>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <TBElement />
        <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
    </CardMain>
}
function setLink(s: string) {
    s = s.replace('" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>', '');
    s = s.replace('<iframe src="', '');
    return s;
}