import { SizeViewSelector, Title, CardMain, ButtonColor, TitleCard, Guardar, CardConfig, RowConfig } from '../../Component';
import { TBElement } from '../../Component/SlateCustom'
import { State } from './index'
interface Props {
    save: () => void
    title: string
    config: State
    setTitle: (d: string) => void
    setConfig: (d: any) => void
}
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { color, size } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='302px'>
            <RowConfig>
                <Title setData={setTitle} title={title} width='298px' />
            </RowConfig>
            <RowConfig top={2}>
                <ButtonColor tip='COLOR DE FONDO' color={color} setData={(color) => setConfig({ color })}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <TBElement />
        <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
    </CardMain >
}