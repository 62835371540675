import React from 'react';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { InputText } from '../../../Utiles/Inputs';
import Estados from '../../../Utiles/Componentes/Estados';
import { conOrden } from '../../../Models/Compra';
import { GeneralFila } from '../../../Models/General';
import ModalBusqueda from '../../../Utiles/Buscadores/Modal';
import FilaGeneraModal from '../../../Utiles/Prototipos/FilaGeneraModal';
import { OrdenesContext } from '../../../Context/Compras/Ordenes';
class BuscarOrden extends ModalBusqueda{
    con=conOrden();
    buscarEn = ['codigo', 'nombre', 'fecha',];
    status = -1;
    Buscador() {
        return <Row style={{ paddingBottom: '15px' }}>
            <Col className="col-md-9 mr-auto">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {this.buscarLabel}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                    <Estados status={this.status} estatus={['CANCELADO', 'EN ESPERA', 'PROCESADA']} setData={d => { this.status = d; this.forceUpdate() }} />
                    <InputGroup.Append>
                        <Button variant="primary">
                            <i className="fa fa-search" /> BUSCAR
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            <Button size='sm' onClick={this.set.bind(this)} data-tip="ACTUALIZAR LISTA" variant="info">
                <i className="fa fa-refresh"></i> ACTUALIZAR
            </Button>
        </Row>
    }
    filter() {
        let itens = this.ordenar();
        return itens.filter((c: GeneralFila) => {
            if (c.status !== this.status && this.status !== -1) return false;
            return true;
        });
    }
    Title() {
        return <h4 className='mr-auto'>BUSCAR ORDEN DE COMPRA</h4>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='100px'>NUMERO</Th>
            <Th label='fecha' width='90px'>FECHA</Th>
            <Th label='nombre' width='calc(100% - 585px)'>PROVEEDOR</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='135px'>ACCIONES</Th>
        </tr>
    }
    Row(c: GeneralFila) {
        return <FilaGeneraModal key={c.codigo+c.status} data={c} type='Orden' add={this.add.bind(this)} />
    }
    add(c: GeneralFila) {
        this.props.add(c);
        this.props.close();
    }
    getItens() { return this.context.Ordenes; }
    set() { this.context.actOrden(); }
    getEstado() { return this.context.estOrdenes; }
    reset(){this.context.resetCompra()}
}
BuscarOrden.contextType = OrdenesContext;
export default BuscarOrden;