import { css } from '@emotion/css';
import { getWidthSize } from '../Utils';
import { Row, Col } from 'react-bootstrap';
import { Html } from '../../Component/Commons';
export function Paralax2({ size, data: { props: { xs, sm, md, lg, xl, xxl, color, align, image }, html } }: any) {
    return <div className={css`overflow: hidden;background-image: url(${image});background-attachment: fixed;background-position: center center;background-size: cover;background-color: transparent;`}>
        <Row className={`${getWidthSize(xs, sm, md, lg, xl, xxl, size)} m-0 p-0`}>
            <Col style={{ backgroundColor: color, height: '100%' }}>
                <Html style={{ height: '100%', width: '100%' }} html={html} />
            </Col>
        </Row>
    </div>
}