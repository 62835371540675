import React, { useState } from 'react';
import { Button, Col, Card, Row } from 'react-bootstrap';
import { ColorBox, Color, createColor } from 'material-ui-color';
import { ConConfig } from '../../../Models/Config';
import { ToRGB } from '../../../Utiles/Formatos';
interface State {
    colores: string[]
}
export default class Colores extends React.Component<any, State> {
    con = ConConfig('colores');
    state = { colores: ['#000000', '#FFFFFF'] };
    render() {
        let { colores } = this.state;
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i>
                    CONFIGURACION DE COLORES </h4>
            </Card.Header>
            <Card.Body>
                <Row>
                    <AddColor setData={c => this.setState({ colores: [...colores, c] })} />
                    <Row className='d-flex' style={{ minHeight: '350px', margin: '0 0 0 0', padding: '1rem 1rem 1rem 1rem', width: 'calc(100% - 320px)' }}>
                        {colores.map((c, i) => <div style={{
                            margin: '.25rem .25rem .25rem .25rem', border: 'solid 3px #000',
                            width: 150, color: getColor(c), backgroundColor: c, height: '40px'
                        }}><b>{c}</b>
                            <Button variant='danger' onClick={() => this.setState({ colores: colores.filter((s, j) => j !== i) })}
                                className='float-right' style={{ padding: '.2rem .2rem .2rem .2rem', fontSize: '.7rem' }}>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>)}
                    </Row>
                </Row>
                <Col md={12}><br />
                    <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                        <span className="fas fa-save" /> Guardar
                    </Button>
                </Col>
            </Card.Body>
        </Card>)
    }
    change(i: number, s: string) {
        this.setState({ colores: this.state.colores.map((o, j) => j !== i ? o : s) });
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}
const AddColor = ({ setData }: { setData: (d: string) => void }) => {
    const [color, setColor] = useState(createColor('red'));
    const handleChange = (c: Color) => setColor(c);
    return <div style={{ width: 320 }}>
        <ColorBox value={color} onChange={handleChange} disableAlpha={true} />
        <Button className='mt-2' variant='success' block onClick={() => setData(`#${color.hex}`)}>
            <i className="fa fa-plus"></i> NUEVO COLOR
        </Button>
    </div>
}
function getColor(color: string) {
    const { r, g, b } = ToRGB(color);
    let r1 = r >= 128 ? 0 : 255;
    let g1 = g >= 128 ? 0 : 255;
    let b1 = b >= 128 ? 0 : 255;
    const max = Math.max(r1, g1, b1);
    const min = Math.min(r1, g1, b1);
    if (max !== min) {
        if (r1 === g1) b1 = r1;
        if (r1 === b1) g1 = r1;
        if (b1 === g1) r1 = b1;
    }
    if ((g === 255 && r < 30 && b < 30)) return '#000000';
    return `rgb(${r1},${g1},${b1})`;
}

