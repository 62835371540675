import { CardMain, SizeViewSelector, Title, TitleCard, Guardar, CardConfig, RowConfig } from '../../Component';
import { TBElement } from '../../Component/SlateCustom';
import { NotaMenu, FormColor } from '../../Component/Commons';
import { State } from './index'
interface Props { save: () => void, title: string; config: State; setTitle: (d: string) => void; setConfig: (d: any) => void }
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, texto, } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='256px' >
            <RowConfig>
                <Title setData={setTitle} title={title} width='250px' />
            </RowConfig>
            <FormColor config={config} setConfig={setConfig} />
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <NotaMenu config={config} setConfig={setConfig} />
        {texto ? <TBElement /> : <></>}
        <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
    </CardMain >
}