import React from 'react'
import { Row, Col, Button, InputGroup, ButtonGroup, Card, Accordion, FormControl } from 'react-bootstrap'
import { ProveedoresContext } from '../../../Context/Compras/Proveedor'
import { Proveedor, conProveedor, ProveedorNull } from '../../../Models/Compra'
import { busqueda } from '../../../Utiles/Filtros'
import FormProveedor from './Form'
interface Props {
    Proveedor: string
    add: (Proveedor: string) => void
}
interface State {
    proveedor: Proveedor
    buscarNumbre: string
    buscarCodigo: string
    nuevo: boolean
}
class ProveedorSelector extends React.Component<Props, State> {
    con = conProveedor();
    nombre = React.createRef<HTMLInputElement>();
    codigo = React.createRef<HTMLInputElement>();
    constructor(p: Props) {
        super(p)
        this.state = { nuevo: false, proveedor: ProveedorNull, buscarCodigo: '', buscarNumbre: '' }
    }
    render() {
        const { state: { nuevo, proveedor, buscarCodigo, buscarNumbre } } = this
        return <Card as={Accordion} id="tabla">
            <FormProveedor Proveedor={null} title="NUEVO PROVEEDOR" open={nuevo} add={this.seleccion.bind(this)} close={this.close.bind(this)} />
            <Card.Body>
                <Row><Col xs={12}><h4 className='mr-auto'><b>DATOS DEL PROVEEDOR</b></h4></Col></Row>
                <Row>
                    <InputGroup className="mb-3" style={{ width: '30%', marginRight: '15px', marginLeft: '10px' }}>
                        <InputGroup.Prepend >
                            <InputGroup.Text>RIF</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl style={{ width: 'calc(100% - 60px)', position: 'relative' }} list="inputCodigo"
                            ref={this.codigo}
                            onKeyUp={this.limpiarCodigo.bind(this)}
                            onChange={this.setBuscarCodigo.bind(this)}
                            defaultValue={buscarCodigo} />
                    </InputGroup>
                    <datalist id="inputCodigo">
                        {this.getList(busqueda(this.context.Proveedores, buscarCodigo, ['codigo']), 'codigo')}
                    </datalist>
                    <InputGroup className="mb-3" style={{ width: 'calc(70% - 160px)', marginRight: '15px' }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text>NOMBRE</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl style={{ width: 'calc(100% - 100px)' }} list="inputNombre" className="form-control"
                            ref={this.nombre}
                            onKeyUp={this.limpiarNombre.bind(this)}
                            onChange={this.setBuscarNombre.bind(this)}
                            defaultValue={buscarNumbre} />
                    </InputGroup>
                    <datalist id="inputNombre">
                        {this.getList(busqueda(this.context.Proveedores, buscarCodigo, ['nombre']), 'nombre')}
                    </datalist>
                    <ButtonGroup style={{ width: "110px" }} className="float-right mb-3">
                        <Button data-tip="VER MAS" as={Accordion.Toggle} eventKey="1">
                            <i className="fa fa-eye"></i>
                        </Button>
                        <Button data-tip="NUEVO PROVEEDOR" onClick={() => { this.setState({ nuevo: true }) }} variant="info">
                            <i className="fa fa-edit"></i>
                        </Button>
                    </ButtonGroup>
                </Row>
                <Accordion.Collapse eventKey='1'>
                    <Row>
                        <Col xs={12}>
                            <InputGroup style={{ width: '100%' }} className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>NOMBRE</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl disabled value={proveedor.correo} />
                            </InputGroup>
                        </Col>
                        <Col xs={12}>
                            <InputGroup style={{ width: '100%' }} className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>TELEFONO</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl disabled value={proveedor.telefono} />
                            </InputGroup>
                        </Col>
                        <Col xs={12}>
                            <InputGroup style={{ width: '100%' }} className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>PERSONA DE CONTACTO</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl disabled value={proveedor.contacto} />
                            </InputGroup>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <b>DICECCION</b>
                            <FormControl rows={3} as='textarea' disabled value={proveedor.direccion} />
                        </Col>
                    </Row>
                </Accordion.Collapse>
            </Card.Body>
        </Card>
    }
    seleccion(c: Proveedor) {
        this.set();
        this.props.add(c.codigo);
        if (this.nombre.current)
            this.nombre.current.value = c.nombre;
        if (this.codigo.current)
            this.codigo.current.value = c.codigo;
        this.setState({ proveedor: c, buscarCodigo: c.codigo, buscarNumbre: c.nombre, nuevo: false });
    }
    setBuscarNombre(e: React.ChangeEvent<HTMLInputElement>) {
        let b = e.target.value.toUpperCase()
        this.setState({ buscarNumbre: b });
        this.context.Proveedores.forEach((c: Proveedor) => {
            if (c.nombre === b) this.props.add(c.codigo);
        });
    }
    setBuscarCodigo(e: React.ChangeEvent<HTMLInputElement>) {
        let b = e.target.value.toUpperCase()
        this.setState({ buscarCodigo: b });
        this.context.Proveedores.forEach((c: Proveedor) => {
            if (c.codigo === b) this.props.add(c.codigo);
        });
    }
    limpiarNombre(e: React.KeyboardEvent<HTMLInputElement>) {
        console.log(e.keyCode);
        console.log(this.context.Proveedores);

        if (e.keyCode === 8) {
            e.currentTarget.value = '';
            this.limpiar();
        }
    }
    limpiarCodigo(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.keyCode === 8) {
            e.currentTarget.value = '';
            this.limpiar();
        }
    }
    limpiar() {
        this.setState({ proveedor: ProveedorNull, buscarCodigo: '', buscarNumbre: '' })
        if (this.nombre.current) this.nombre.current.value = "";
        if (this.codigo.current) this.codigo.current.value = "";
    }
    getList(lista: Proveedor[], label: 'codigo' | 'nombre') {
        return lista.map(c => <option value={c[label]} key={label + c.codigo}></option>)
    }
    componentWillReceiveProps(p: Props) {
        if (this.state.proveedor.codigo !== p.Proveedor)
            this.context.Proveedores.forEach((c: Proveedor) => {
                if (c.codigo === p.Proveedor) {
                    if (this.nombre.current) this.nombre.current.value = c.nombre;
                    if (this.codigo.current) this.codigo.current.value = c.codigo;
                    this.setState({ proveedor: c, buscarCodigo: c.codigo, buscarNumbre: c.nombre });
                }
            });
    }
    componentDidMount() { this.set(); }
    close() { this.setState({ nuevo: false }) }
    set() { this.context.actProveedor(); }
}
ProveedorSelector.contextType = ProveedoresContext;
export default ProveedorSelector