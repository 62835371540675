import { useState, useCallback } from 'react';
import { Buscador } from './Buscador';
import { conMedia, Media as iMedia, arrayToMedia } from '../../Models/Media';
import { Card, Row } from 'react-bootstrap';
import { ButtonImage } from './Utils/UpdateButton';
import { GetImagen } from './Utils/CardIndex';
const con = conMedia();
export function Media() {
    const [imgs, setImgs] = useState<iMedia[]>([]);
    const [size, setSize] = useState(0);
    const [max, setMax] = useState(104857600);
    const actualizar = useCallback(() => {
        conMedia().setDisk(({ max, size }) => { setMax(max); setSize(size); })
        con.cargarListaWeb((m) => setImgs(arrayToMedia(m)))
    }, [])
    return <Card style={{ height: 'calc(100vh - 135px)', marginBottom: 0 }}>
        <Card.Header className='bg-info'>
            <Row>
                <h3>GALERIA DE IMAGENES</h3>
                <ButtonImage size={size} max={max} multi={true} key='uploadImage' actualizar={actualizar} />
            </Row>
        </Card.Header>
        <Card.Body>
            <Buscador imgs={imgs} actualizar={actualizar} GetImagen={(i: any) => <GetImagen {...i} actualizar={actualizar} />} />
        </Card.Body>
    </Card>
}
export { ButtonImage };