import React from 'react';
import { defaultText, BlockElement, setText, getText } from './DefaultData';
import SlateProvider from '../Component/SlateCustom'
import { SizeViewConteiner, sizeWidth } from '../Component';
import { conPanel } from '../../../Models/Panel';
import { Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import { PanelContext } from '../../../Context/Panel';
export interface StateS {
    id?: number;
    size: sizeWidth;
    title: string;
    html: BlockElement[];
}
export interface Props { data?: any }
class Single<s> extends React.Component<Props, (StateS & s)> {
    block = true;
    init = (p: Props, def: s) => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state = { ...def, id: undefined, size: '1440px', title: '', html: defaultText };
        setTimeout(() => {
            if (p.data !== undefined) {
                const { props, data, title, id } = p.data;
                const html = setText(p.data.html);
                this.setData({ ...props, ...data, html, title, id })
            }
        }, 500);
    }
    constructor(p: { data?: StateS & s }) {
        super(p);
        this.init(p, {} as StateS & s);
    }
    render() {
        const { Config, Panel, setTexto, Top, Left, state: { html, id }, props: { data } } = this;
        if (data === undefined && id !== undefined) return <Redirect to={'/panel/edit/' + id} />
        return <div style={{ height: '100%', maxHeight: 'calc(100vh - 180px)' }}>
            <SlateProvider key={`panel${id}`} value={html} setValue={setTexto}>
                {Config()}
                <SizeViewConteiner top={Top()} left={Left()} size={this.state.size}>
                    {Panel()}
                </SizeViewConteiner>
            </SlateProvider>
        </div>
    }
    Config = () => <></>
    Panel = () => <></>
    setTitle = (title: string) => this.setData({ title })
    setTexto = (text: BlockElement[]) => { this.setData({ html: getText(text) }); }
    Top = () => <></>
    Left = () => <></>
    setData = (d: any) => this.setState(d);
    procesar = true;
    save = async () => {
        if (this.procesar) {
            this.procesar = false;
            if (this.props.data !== undefined) conPanel().actualizar(this.props.data.id, this.getDataSave2(), () => {
                toast('PANEL ACTUALIZADO', { type: toast.TYPE.SUCCESS });
                this.procesar = true;
            }, () => this.procesar = true)
            else conPanel().nuevo(this.getDataSave2(), ({ id }) => {
                this.setState({ id });
                toast('PANEL CREADO', { type: toast.TYPE.SUCCESS });
                this.procesar = true;
            }, () => this.procesar = true);
        } else toast('SUBIENDO DATOS, POR FAVOR ESPERE', { type: toast.TYPE.WARNING });
    }
    getDataSave = (): any => { }
    getDataSave2 = (): any => {
        const a = this.getDataSave();
        const { page } = this.context;
        return { ...a, page };
    }
    onBlock = (f: () => void, n = 1) => {
        if (this.block) {
            this.block = false;
            setTimeout(() => { f(); this.block = true; }, n);
        }
    }
}
Single.contextType = PanelContext;
export { Single };