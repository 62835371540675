import './editor.css'
import { Config } from './Config';
import { Playback } from './Config/Grabber';
import { Row } from 'react-bootstrap';
import { VideoContext } from './Context/Video';
import { useContext } from "react";

interface Props {
    saveVideo: (d: any) => void,
    videoUrl: string,
    type: string,
    close: () => void
};


export default function Main({ saveVideo, videoUrl, type, close }: Props) {
    // const [video, setVideo] = useState('');
    const { mute } = useContext(VideoContext);
    // const init = async () => {
    //     try {
    //         const video = await fetch(`/api/Media/bruto/${videoUrl}`);
    //         const data = await video.text();
    //         setVideo(`data:${type};base64,${data}`);
    //     } catch (e) { }
    // }
    // useEffect(() => { init() }, []) 
    return <div className='d-flex' style={{ height: '95vh', }} >
        <Config id={videoUrl} save={saveVideo} close={close} />
        <div className="flex-grow-1 m-1 p-1" style={{ width: 'calc(100vw - 350px)', height: 'calc(100vh - 40px)', border: 'orange 5px solid' }}>
            <Row style={{ height: 'calc(100vh - 150px)' }} className='d-flex m-0 p-0'>
                <video id='video' src={`/api/Media/ver/${videoUrl}`}
                    muted={mute} className='w-100 h-100' />
            </Row>
            <Playback />
        </div>
    </div>
}