import React from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { ProductosContext, ProductosType } from "../../Context/Producto";
import { conCotizacion } from "../../Models/Ventas";
import { ConConfig } from "../../Models/Config";
import { GeneralData, generalNull, tipoData } from "../../Models/General";
import AuxiliarNuevo from "../Prototipos/AuxiliarNuevo";
import ModalAlert from "./ModalAlert";
import PrintCompnent from "./PrintCompnent";
interface State extends GeneralData {
    procesar: boolean
    cod_proveedor?: string
    cod_cliente?: string
}
class CreateOperacion extends React.Component<any, State>{
    tasa = true;
    impuesto = 0;
    con = conCotizacion();
    procesar = true;
    tipo: tipoData | 'Ajuste' = 'Cotizacion';
    title = ''
    msn = '';
    setData: (d: any) => void;
    constructor(p: any) {
        super(p);
        this.setData = this.setState.bind(this);
        this.setState = this.setState.bind(this);
        this.Productos = this.Productos.bind(this);
        this.PDF = this.PDF.bind(this);
        this.Top = this.Top.bind(this);
        this.Procesar = this.Procesar.bind(this);
        this.state = { ...generalNull, procesar: false, cod_cliente: '', cod_proveedor: '' };
    }
    render() {
        this.dolar();
        const { state: { codigo, procesar }, setState, Top, PDF, Productos, msn, title } = this;
        if (codigo > 0) return <PDF />
        return <Card>
            <ModalAlert variant='info' title='CONFIRMAR' open={procesar} msn={'DESEA PROCESAR ' + msn} close={() => setState({ procesar: false })} accion={this.Procesar} />
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> NUEVO {title}</h4>
            </Card.Header>
            <Card.Body>
                <Top />
                <Productos />
            </Card.Body>
        </Card>
    }
    Top() {
        return <></>
    }
    Productos() {
        return <AuxiliarNuevo setData={this.setState} data={this.state} />
    }
    PDF() {
        const { codigo, dolar } = this.state;
        return <PrintCompnent id={codigo} tipo={this.tipo} dolar={dolar ? true : false} />
    }
    Procesar() {
    }
    validacionGenerica(procesar = true) {
        if (this.state.detalles.length === 0) {
            toast('DEBE SELECCIONAR AL MENOS UN PRODUCTO', { type: toast.TYPE.ERROR });
            procesar = false;
        }
        if (procesar)
            if (this.procesar && procesar) {
                this.procesar = false;
            } else {
                toast('YA SE ESTA PROCESANDO ' + this.msn, { type: toast.TYPE.ERROR });
                procesar = false;
            }
        return procesar;
    }
    valdacionPrecio(procesar = true) {
        const { data: { User: { nivel } }, findProVen } = this.context as ProductosType;
        if (this.state.id_nota === undefined)
            this.state.detalles.forEach(p => {
                const pr = findProVen(p, false);
                const cos = (nivel === 0) ? pr.costo : (pr.costo * (1 + pr.precio1 / 100));
                if (cos > p.precio) {
                    toast(p.descripcion + ' ESTA POR DEBAJO DE SU ' + ((nivel === 0) ? 'COSTO' : 'PRECIO DE VENTA MAS BAJO') + ' REGISTRADO', { type: toast.TYPE.ERROR });
                    procesar = false;
                }
            })
        return procesar;
    }
    validacionExixtencia(procesar = true) {
        const { findProVen } = this.context as ProductosType;
        if (this.state.id_nota === undefined)
            this.state.detalles.forEach(p => {
                const pr = findProVen(p, false);
                if (p.unidades > pr.cantidad) {
                    if (p.inventario > 1) {
                        toast('NO HAY SUFICENTE ' + p.descripcion + ' PARA COMPLETAR LA OPERACION', { type: toast.TYPE.ERROR });
                        procesar = false;
                    }
                }
            })
        return procesar;
    }
    getData(): any {
        return this.state;
    }
    dolar() {
        const { tasa } = this.context;
        if ((tasa !== 0) && this.tasa) {
            this.setState({ dolar: this.context.dolar });
            this.tasa = false;
        }
    }
    componentDidMount() {
        let s = localStorage.getItem(this.tipo + 'Cache');
        if (s !== null) {
            let data = JSON.parse(s);
            this.setState(data);
        }
        this.setConfig();
    }
    setConfig() {
        if (this.state.nota !== '')
            ConConfig(this.tipo.toLowerCase()).pull(this.setData)
    }
    componentDidUpdate() {
        if (!this.state.procesar)
            localStorage.setItem(this.tipo + 'Cache', JSON.stringify(this.state));
    }
    componentWillUnmount() {
        localStorage.removeItem(this.tipo + 'Cache');
    }
}
CreateOperacion.contextType = ProductosContext;
export default CreateOperacion;