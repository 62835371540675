import React from "react"
import { Link } from "react-router-dom"
interface State {
    clientes: number,
    proveedores: number,
    compras: number,
    ordenes: number,
    notas: number,
    cotizaciones: number,
    facturas: number,
    inventario: number
}
export default class Contadores extends React.Component<{ p: string[] }, State>{
    state = { clientes: 0, proveedores: 0, compras: 0, ordenes: 0, notas: 0, cotizaciones: 0, facturas: 0, inventario: 0 }
    render() {
        const { state: { clientes, proveedores, compras, ordenes, notas, cotizaciones, facturas, inventario }, props: { p } } = this;
        return <div className="Contadores col-12">
            <Count p={p} permiso='ORDEN' to='/orden/lista' ico='paperclip' label="ORDENES DE COMPRA" color="info" cont={ordenes} />
            <Count p={p} permiso='COMPRA' to='/compra/lista' ico='shopping-cart' label="COMPRAS" color="primary" cont={compras} />
            <Count p={p} permiso='COTIZACION' to='/cotizacion/lista' ico='calculator' label="COTIZACIONES" color="danger" cont={cotizaciones} />
            <Count p={p} permiso='NOTA' to='/nota/lista' ico='paper-plane' label="NOTAS DE ENTREGA" color="warning" cont={notas} />
            <Count p={p} permiso='FACTURA' to='/factura/lista' ico='dollar-sign' label="FACTURAS" color="success" cont={facturas} />
            <Count p={p} permiso='CLIENTE' to='/clientes' ico='user' label="CLIENTES" color="teal" cont={clientes} />
            <Count p={p} permiso='PROVEEDOR' to='/proveedores' ico='truck' label="PROVEEDORES" color="lightblue" cont={proveedores} />
            <Count p={p} permiso='PRODUCTO' to='/producto' ico='laptop' label="INVENTARIO" color="orange" cont={inventario} />
        </div>
    }
    async send() {
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch('/api/Config/contadores', { headers: { 'Authorization': 'Bearer ' + token } });
            let datos = await response.json();
            this.setState(datos);
        } catch (error) {
            console.log(error);
        }
    }
    componentDidMount() { this.send() }
}
function Count({ p, permiso, ico, color, label, cont, to }: { p: string[], permiso: string, to: string, ico: string, color: string, label: string, cont: number }) {
    if (p.every(d => d !== permiso))
        return <></>
    return <div className={"small-box bg-" + color}>
        <div className="inner">
            <h3>{cont}</h3>
            <p>{label}</p>
        </div>
        <div className="icon">
            <i className={"fa fa-" + ico}></i>
        </div>
        <Link to={to} className="small-box-footer">
            VER MAS <i className="fa fa-arrow-circle-right"></i>
        </Link>
    </div>

}