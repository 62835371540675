import { isArray } from "util";
import { zfill } from "../Utiles/Formatos";


export class localData {
    url: string;
    data: any;
    persistencia: number = 0;
    constructor(url: string) {
        this.url = url;

        let stringCache = localStorage.getItem(url);
        if (stringCache !== null) {
            let data: any = JSON.parse(stringCache);
            if (isArray(data.data))
                this.data = data.data.map((d: any) => d);
            this.persistencia = data.persistencia;
        }
    }
    setData(data: any) {
        localStorage.setItem(this.url, JSON.stringify({
            data, persistencia: this.tiempoNumber()
        }))
    }
    renovar(persistencia?: number) {
        if (persistencia !== undefined && (this.persistencia + persistencia) > this.tiempoNumber()) return false;
        return true;
    }
    tiempoNumber() {
        let date = new Date();
        let año = date.getFullYear(),
            mes = date.getMonth() + 1,
            dia = date.getDate() + 1,
            hora = date.getHours(),
            minuto = date.getMinutes(),
            segundo = date.getSeconds(),
            text = año + zfill(mes, 2) + zfill(dia, 2) + zfill(hora, 2) + zfill(minuto, 2) + zfill(segundo, 2);
        return parseInt(text);
    }
}