/* eslint-disable jsx-a11y/anchor-is-valid */
import MenuIten from './MenuIten'
import { Link } from "react-router-dom"
import { Accordion } from 'react-bootstrap';
import Treeview from './Treeview';

export default function Menu() {
    return <aside className="main-sidebar sidebar-dark-primary" style={{ maxHeight: 'calc(100vh - 170px)' }}>
        <Link to="/" className="brand-link bg-success" style={{ width: '100%', height: '57px' }}>
            <img width={33} height={33} src="/logos/logo.svg" alt="logo" className="brand-image img-circle" />
            <img src="/logos/texto.svg" alt="letras" className="brand-image brand-text" style={{ transitionDelay: '.2s' }} />
        </Link>
        <div className="sidebar">
            <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">
                            <i className="nav-icon fas fa-home"></i> <p>INICIO</p>
                        </Link>
                    </li>
                    <Accordion>
                        <Treeview event={0} permisos={['COMPRA', 'ORDEN', 'PROVEEDOR']} title={
                            <a className="nav-link" href='#' onClick={() => 0}>
                                <i className="nav-icon fas fa-shopping-cart"></i> <p> COMPRAS</p> <i className='fa fa-angle-down float-right'></i>
                            </a>}>
                            <MenuIten to="/compra/lista" permiso="COMPRA">
                                <i className="nav-icon fas fa-tag"></i> <p> COMPRA </p>
                            </MenuIten>
                            <MenuIten to="/orden/lista" permiso="ORDEN">
                                <i className="nav-icon fas fa-paperclip"></i> <p style={{ width: "200px" }}> ORDENES DE COMPRA </p>
                            </MenuIten>
                            <MenuIten to="/proveedores" permiso="PROVEEDOR">
                                <i className="nav-icon fas fa-truck"></i> <p> PROVEEDORES</p>
                            </MenuIten>
                            {/* <MenuIten to="/compra/credito/lista" permiso="PROVEEDOR">
                                <i className="nav-icon fas fa-money-check"></i> <p> NOTAS DE CREDITO</p>
                            </MenuIten>
                            <MenuIten to="/proveedores" permiso="PROVEEDOR">
                                <i className="nav-icon fas fa-money-check-alt"></i> <p> NOTAS DE DEBITO</p>
                            </MenuIten> */}
                        </Treeview>
                        <Treeview event={1} permisos={['FACTURA', 'NOTA', 'COTIZACION', 'CLIENTE']} title={
                            <a className="nav-link" href='#' onClick={() => 1}>
                                <i className="nav-icon fas fa-tags"></i> <p className='row'>VENTAS</p><i className='fa fa-angle-down float-right'></i>
                            </a>}>
                            <MenuIten to="/factura/lista" permiso="FACTURA">
                                <i className="nav-icon fas fa-file"></i> <p>FACTURA</p>
                            </MenuIten>
                            <MenuIten to="/nota/lista" permiso="NOTA">
                                <i className="nav-icon fas fa-paper-plane"></i> <p>NOTAS DE ENTREGA</p>
                            </MenuIten>
                            <MenuIten to="/cotizacion/lista" permiso="COTIZACION">
                                <i className="nav-icon fas fa-calculator"></i> <p>COTIZACIONES</p>
                            </MenuIten>
                            <MenuIten to="/clientes" permiso="CLIENTE">
                                <i className="nav-icon fas fa-user"></i><p> CLIENTES</p>
                            </MenuIten>
                            {/* <MenuIten to="/clientes" permiso="CLIENTE">
                                    <i className="nav-icon fas fa-money-check"></i> <p> NOTAS DE CREDITO</p>
                                </MenuIten>
                                <MenuIten to="/clientes" permiso="CLIENTE">
                                    <i className="nav-icon fas fa-money-check-alt"></i> <p> NOTAS DE DEBITO</p>
                                </MenuIten> */}
                        </Treeview>
                        <Treeview event={2} permisos={['PRODUCTO', 'AJUSTE']} title={
                            <a className="nav-link" href='#' onClick={() => 2}>
                                <i className="nav-icon fas fa-boxes"></i> <p className='row'>PRODUCTOS</p> <i className='fa fa-angle-down float-right'></i>
                            </a>}>
                            <MenuIten to="/producto" permiso="PRODUCTO">
                                <i className="nav-icon fas fa-laptop"></i> <p> INVENTARIO</p>
                            </MenuIten>
                            <MenuIten to="/producto/ajuste/lista" permiso="AJUSTE">
                                <i className="nav-icon fas fa-th-large"></i> <p>AJUSTES</p>
                            </MenuIten>
                        </Treeview>
                        <MenuIten to="/panel" permiso="PANEL">
                            <i className="nav-icon fas fa-browser"></i> <p>HAZTULANDING</p>
                        </MenuIten>
                        <MenuIten to="/Contacto/subs" permiso="PANEL">
                            <i className="nav-icon fas fa-comment-alt-smile"></i> <p>CONTACTOS</p>
                        </MenuIten>
                        <MenuIten to="/media" permiso="MEDIA">
                            <i className="nav-icon fas fa-image"></i> <p>MEDIA</p>
                        </MenuIten>
                        <MenuIten to="/usuarios" permiso="USUARIO">
                            <i className="nav-icon fas fa-users"></i><p> USUARIOS</p>
                        </MenuIten>
                        <MenuIten to="/reporte" permiso="REPORTE">
                            <i className="nav-icon fas fa-chart-line"></i><p> REPORTES</p>
                        </MenuIten>
                        <MenuIten to="/config" permiso="CONFIG">
                            <i className="nav-icon fas fa-wrench"></i><p> CONFIGURACION</p>
                        </MenuIten>
                    </Accordion>
                </ul>
            </nav>
        </div>
    </aside >
}
