import { Paralax1 } from './EditPanel/Paralax1';
import { Paralax2 } from './EditPanel/Paralax2';
import { Video } from './EditPanel/Video';
import { Galeria } from './EditPanel/Galeria';
import { Carrusel } from './EditPanel/Carrusel';
import { Cargos } from './EditPanel/Cargos';
import { Servicio } from './EditPanel/Servicio';
import { Servicio2 } from './EditPanel/Servicio2';
import { Preguntas } from './EditPanel/Preguntas';
import { Title } from './EditPanel/Title';
import { Imagen } from './EditPanel/Imagen';
import { Pestanas } from './EditPanel/Pestanas';
import { Mapa } from './EditPanel/Mapa';
import { Contact } from './EditPanel/Contact';
import { Footer } from './EditPanel/Footer';
import { Suscribir } from './EditPanel/Suscribir';
import { Menu } from './EditPanel/Menu';
import { Chats } from './EditPanel/Chats';
import { Ver } from './Ver';
import Shorts from './Shorts';
import ShortsNuevo from './Shorts/Nuevo';
import { Edit } from './Shorts/Nuevo/Edit';
import { EditarPanel } from './Ver/Editar';
import { Route } from "react-router-dom";
import Selector from './Ver/Selector';
import PanelProvider from '../../Context/Panel'
export function Crear() {
    return <PanelProvider>
        <Route path="/panel/edit/:id" exact><EditarPanel key={`panel${Math.random()}`} /></Route>
        <Route path="/panel/suscribir" exact><Suscribir /></Route>
        <Route path="/panel/titulo" exact><Title /></Route>
        <Route path="/panel/paralax1" exact><Paralax1 /></Route>
        <Route path="/panel/paralax2" exact><Paralax2 /></Route>
        <Route path="/panel/video" exact ><Video /></Route>
        <Route path="/panel/imagen" exact><Imagen /></Route>
        <Route path="/panel/galeria" exact><Galeria /></Route>
        <Route path="/panel/carrusel" exact><Carrusel /></Route>
        <Route path="/panel/cargos" exact><Cargos /></Route>
        <Route path="/panel/servicio" exact><Servicio /></Route>
        <Route path="/panel/servicio2" exact><Servicio2 /></Route>
        <Route path="/panel/preguntas" exact><Preguntas /></Route>
        <Route path="/panel/pestanas" exact><Pestanas /></Route>
        <Route path="/panel/mapa" exact><Mapa /></Route>
        <Route path="/panel/pie" exact><Footer /></Route>
        <Route path="/panel/menu" exact><Menu /></Route>
        <Route path="/panel/chats" exact><Chats /></Route>
        <Route path="/panel/contacto" exact><Contact /></Route>
        <Route path="/panel/crear" exact><Selector /></Route>
        <Route path="/panel/short" exact><Shorts /></Route>
        <Route path="/panel/short/nueva" exact><ShortsNuevo /></Route>
        <Route path="/panel/short/edit/:id" exact component={Edit} />
        <Route path="/panel" exact component={Ver} />
    </PanelProvider>
}