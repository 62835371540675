import React from "react"
import Gauge from "../../../Utiles/Charts/Gauge";
export default class Utilidad extends React.Component<{}, { utilidad: number }>{
    state = { utilidad: 0 };
    render() {
        const { utilidad } = this.state;
        return <div className="center Utilidad">
            <div>
                <Gauge label='%' value={utilidad}
                    red={0} yellow={10} green={30} />
                <h5>PROMEDIO DE UTILIDAD</h5>
            </div>
        </div>
    }
    componentDidMount() {
        this.send()
    }
    async send() {
        let date = new Date();
        let año = date.getFullYear(),
            mes = date.getMonth() + 1;
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch('/api/Factura/utilidad/' + año + '/' + mes, { headers: { 'Authorization': 'Bearer ' + token } });
            let datos = await response.json();
            this.setState({ utilidad: parseFloat(datos) });
        } catch (error) {
            console.log(error);
            this.setState({ utilidad: 0 });
        }
    }
}