import { CardMain, SizeViewSelector, Title, ButtonColor, TitleCard, Guardar, CardConfig, RowConfig } from '../../Component';
import { CropModal } from '../../../Media/CropModal';
import { TBElement } from '../../Component/SlateCustom';
import { NotaMenu } from '../../Component/Commons';
import { State } from './index'
interface Props { save: () => void, title: string; config: State; setTitle: (d: string) => void; setConfig: (d: any) => void }
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, backgroundColor, texto, img } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='256px' >
            <RowConfig>
                <Title setData={setTitle} title={title} width='250px' />
            </RowConfig>
            <RowConfig top={2}>
                <CropModal aspect={1} img={img} setImg={(img) => setConfig({ img })} />
                <ButtonColor tip='COLOR DE FONDO DEL PANEL' className='ml-1' style={{ width: 32 }} color={backgroundColor} setData={(backgroundColor) => setConfig({ backgroundColor })}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <NotaMenu config={config} setConfig={setConfig} />
        {texto ? <TBElement /> : <></>}
        <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
    </CardMain >
}