import { ButtonColor, RowConfig, ButtonConfig, AddItens } from '../index'
import { ButtonGroup } from 'react-bootstrap';
import { CropModal } from '../../../Media/CropModal';
import { CardConfig, TitleCard } from '../Cards';
import { ColorButton as ColorText, FontStyle, MarkMenu } from '../../../../Utiles/Texto/MarkProps';
import { AlingMenu, ListMenu, TabsMenu, TypeMenu } from '../../../../Utiles/Texto/BlockProps';
import { Inserts } from '../SlateCustom';
interface Props {
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void,
    addItem: () => void,
    cont: number,
    iten: any,
}
interface PImangen extends Props {
    aspect: number
    setImagen: (img: string) => void
}
export function MoveRemove({ itenUp, itenDown, removeItem, iten: { id } }: Props) {
    return <>
        <RowConfig top={2}>
            <ButtonGroup>
                <ButtonConfig tip='MOVER IMAGEN A LA IZQUIERDA' icon='fas fa-arrow-left' action={() => itenUp(id)} />
                <ButtonConfig tip='MOVER IMAGEN A LA DERECHA' icon='fas fa-arrow-right' action={() => itenDown(id)} />
            </ButtonGroup>
            <ButtonConfig className='ml-1' tip='QUITAR' icon='fas fa-trash' action={() => removeItem(id)} />
        </RowConfig>
    </>
}
export function ItenImagen(p: PImangen) {
    const { iten: { img, id }, cont, addItem, setImagen, aspect } = p
    return <CardConfig width='105px'>
        <RowConfig>
            <CropModal aspect={aspect} img={img} setImg={img => setImagen(img)} />
            <AddItens tip='AGREGAR UNA IMAGEN' add={addItem} />
        </RowConfig>
        <MoveRemove {...p} />
        <RowConfig top={2}>
            <span className='ml-auto mr-auto'>
                {id} DE {cont}
            </span>
        </RowConfig>
        <TitleCard>IMAGEN {id}</TitleCard>
    </CardConfig>
}
interface TBElementProps {
    back: string;
    setBack: (value: string) => void;
    alfaBack?: boolean;
    disableBack?: boolean;
}
export const TBElement = ({ back, setBack, alfaBack, disableBack = false }: TBElementProps) => {
    return <CardConfig width='301px'>
        <RowConfig>
            <MarkMenu />
            <ColorText left={1} />
            <AlingMenu left={1} />
            <ButtonColor tip='COLOR DE FONDO' style={{ width: 32 }} disableAlpha={!alfaBack}
                color={back} setData={setBack} className='ml-1' disable={disableBack}>
                <i className="fas fa-ad" />
            </ButtonColor>
        </RowConfig>
        <RowConfig top={2}>
            <TabsMenu left={0} />
            <ListMenu left={1} />
            <TypeMenu left={1} />
            <Inserts />
        </RowConfig>
        <RowConfig top={2}>
            <FontStyle left={0} width='100%' />
        </RowConfig>
        <TitleCard>FUENTE Y TEXTO</TitleCard>
    </CardConfig>
}


