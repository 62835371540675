import React from 'react';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import ModalBusqueda from '../../../Utiles/Buscadores/Modal';
import { InputText } from '../../../Utiles/Inputs';
import { conShort } from '../../../Models/Panel';
import { EstadoNull } from '../../../Models/General';
import { formatoHora } from '../../../Utiles/Formatos';
import THFila from '../../../Utiles/Componentes/THFila';
import { PanelContext } from '../../../Context/Panel'
class BuscarShort extends ModalBusqueda<{}> {
    con = conShort();
    buscarEn = ['clave', 'descripcion'];
    estado = EstadoNull;
    Title() { return <h4 className='mr-auto'>BUSCAR ATAJO</h4> }
    Buscador() {
        return <Row style={{ marginBottom: '15px' }}>
            <Col md={9}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {this.buscarLabel}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText className='form-control' upper setData={this.setState} update='buscar' value={this.state.buscar} />
                    <InputGroup.Append>
                        <Button variant="primary">
                            <span className="fa fa-search"></span> BUSCAR
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            {this.Actualizar()}
        </Row>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='clave' width='200px'>CLAVE</Th>
            <Th label='descripcion' width='330px'>DESCRIPCION</Th>
            <Th width='calc(100% - 600px)'>CAMBIO</Th>
            <Th width='70px'> </Th>
        </tr>
    }
    Row(c: any) {
        return <tr>
            <THFila width='200px'>{c.clave}</THFila>
            <THFila width='330px'>{c.descripcion}</THFila>
            <td style={{ width: 'calc(100% - 600px)' }} dangerouslySetInnerHTML={{ __html: c.html }} />
            <td style={{ width: '70px' }}>
                <Button variant='success' onClick={() => { this.props.add(c); this.props.close(); }}>
                    <i className='fas fa-check' data-tip='Editar' />
                </Button>
            </td>
        </tr>
    }
    getItens() { return this.context.shors; }
    set() {
        this.estado = { status: 2, hora: '...ACTUALIZANDO' };
        this.context.updateShort(() => this.estado = { status: 3, hora: formatoHora(undefined) })
    }
    Actualizar() {
        const { hora } = this.getEstado();
        return <Col md={3} className='mr-auto d-flex flex-row-reverse'>
            <div style={{ fontSize: '12px', width: "200px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> <br />
                {hora}
            </div>
        </Col>
    }
    getEstado() {
        return this.estado;
    }
    dolar() { }
}
BuscarShort.contextType = PanelContext;
export default BuscarShort