import {  RowConfig, ButtonConfig } from '../index'
import { ButtonGroup, Button, InputGroup } from 'react-bootstrap';
import { InputNumber } from '../../../../Utiles/Inputs';
interface BackIconProps {
    setConfig: (d: any) => void
    config: any 
}
function BackIcon({ config: { back }, setConfig }: BackIconProps) {
    return <RowConfig top={2}>
        <ButtonGroup>
            <ButtonGroup>
                <Button data-tip="SIN FONDO PARA EL ICONO" size='sm' active={back === 'none'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ back: 'none' })} variant='outline-dark'>
                    <i className='fas fa-question' style={{ fontSize: 12, width: 16, height: 16, paddingTop: 1 }} />
                </Button>
                <Button data-tip="FONDO RECTANGULAR" size='sm' active={back === 'rectangle'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ back: 'rectangle' })} variant='outline-dark'>
                    <i className='fas fa-question' style={{ fontSize: 10, width: 16, height: 16, paddingTop: 1, border: 'solid 2px currentColor' }} />
                </Button>
                <Button data-tip="FONDO CON ESQUINAS REDONDEADAS" size='sm' active={back === 'rounded'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ back: 'rounded' })} variant='outline-dark'>
                    <i className='fas fa-question' style={{ fontSize: 10, width: 16, height: 16, paddingTop: 1, border: 'solid 2px currentColor', borderRadius: 5 }} />
                </Button>
                <Button data-tip="FONDO CIRCULAR" size='sm' active={back === 'circle'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ back: 'circle' })} variant='outline-dark'>
                    <i className='fas fa-question' style={{ fontSize: 10, width: 16, height: 16, paddingTop: 1, border: 'solid 2px currentColor', borderRadius: 10 }} />
                </Button>
            </ButtonGroup>
        </ButtonGroup>
    </RowConfig>
}
interface ConfigIconBasicProps extends BackIconProps {
    top: any
    down?: any
}
export function ConfigIconBasic(p: ConfigIconBasicProps) {
    const { config: { back, border }, top, down, setConfig } = p;
    return <>
        <RowConfig key={`borderIcon${border ? 'SI' : 'NO'}`}>
            {top}
            <ButtonConfig tip={`COLOCAR BORDE PARA EL FONDO DEL ICONO: ${border ? 'SI' : 'NO'}`} className='ml-auto' disabled={back === 'none'} active={border} action={() => setConfig({ border: !border })} icon='far fa-question-circle' />
        </RowConfig>
        <BackIcon {...p} />
        <RowConfig top={2}>
            {down}
        </RowConfig>
    </>
}
interface ConfigIconFontSizeProps extends BackIconProps {
    max: number
    min: number
    step: number
}
export function ConfigIconFontSize(p: ConfigIconFontSizeProps & { children: any }) {
    const { config: { font }, children, max, min, step, setConfig } = p;
    return <ConfigIconBasic {...p}
        top={<InputGroup data-tip='TAMAÑO DEL ICONO' className='input-group-sm' style={{ height: '24px', width: 75 }}>
            <InputGroup.Prepend style={{ height: '24px', width: '35px' }}>
                <InputGroup.Text style={{ height: '24px', width: '35px' }}>
                    <i className="fas fa-text-height"></i>
                </InputGroup.Text>
            </InputGroup.Prepend>
            <InputNumber max={max} step={step} min={min} decimal={0} style={{ height: 24 }} value={font} setData={font => setConfig({ font })} />
        </InputGroup>}
        down={children} />
}
export function ConfigPosicion(p: ConfigIconFontSizeProps) {
    const { config: { orientation }, setConfig } = p;
    return <ConfigIconFontSize {...p}>
        <ButtonGroup>
            <ButtonConfig tip='ICONO A LA IZQUIERDA' active={orientation === 'row'} action={() => setConfig({ orientation: 'row' })} icon='fas fa-arrow-left' />
            <ButtonConfig tip='ICONO ARRIBA' active={orientation === 'column'} action={() => setConfig({ orientation: 'column' })} icon='fas fa-arrow-up' />
            <ButtonConfig tip='ICONO DERECHA' active={orientation === 'row-reverse'} action={() => setConfig({ orientation: 'row-reverse' })} icon='fas fa-arrow-right' />
            <ButtonConfig tip='ICONO ABAJO' active={orientation === 'column-reverse'} action={() => setConfig({ orientation: 'column-reverse' })} icon='fas fa-arrow-down' />
        </ButtonGroup>
    </ConfigIconFontSize>
}
export function ConfigCard({ config: { card, border1 }, setConfig }: BackIconProps) {
    return <>
        <ButtonGroup className='ml-1'>
            <Button data-tip='SIN FONDO' size='sm' active={card === 'none'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ card: 'none' })} variant='outline-dark'>
                <i className="fas fa-font" style={{ fontSize: 12, width: 16, height: 16, paddingTop: 1 }} />
            </Button>
            <Button data-tip='FONDO DE LA CARTA CUADRADO' size='sm' active={card === 'rectangle'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ card: 'rectangle' })} variant='outline-dark'>
                <i className="fas fa-font" style={{ fontSize: 10, width: 16, height: 16, paddingTop: 1, border: 'solid 2px currentColor' }} />
            </Button>
            <Button data-tip='FONDO DE LA CARTA REDONDEADO' size='sm' active={card === 'rounded'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ card: 'rounded' })} variant='outline-dark'>
                <i className="fas fa-font" style={{ fontSize: 10, width: 16, height: 16, paddingTop: 1, border: 'solid 2px currentColor', borderRadius: 5 }} />
            </Button>
        </ButtonGroup>
        <ButtonConfig tip={`COLOCAR BORDE A LA CARTA ${border1 ? 'SI' : 'NO'}`} disabled={card === 'none'} className='ml-1' active={border1} action={() => setConfig({ border1: !border1 })} icon='fal fa-credit-card-front' />
    </>
}
