import React from "react"
import { PieProps } from "../../../Utiles/Charts/Torta";
import Chart from "react-google-charts"
import { Row } from "react-bootstrap";

export interface rank {
    cantidad: number,
    estatus: number
}
export default class Dipositivos extends React.Component<{ rango: string, no: string }, PieProps>{
    constructor(p: any) {
        super(p);
        this.state = { title: '', values: [] };
    }
    render() {
        const { title, values } = this.state;
        return <Row>
            <div style={{ textAlign: 'center' }}>
                {(values.length > 0) ? <Chart
                    width='25rem'
                    height='15rem'
                    chartType="PieChart"
                    loader={<div style={{ gridAutoRows: '18rem' }} className='center'>
                        <div style={{ gridAutoRows: '12rem' }} className='no-data bg-black center'>
                            <b>SIN REGISTROS<br /> EN EL {this.props.no}</b>
                        </div>
                    </div>}
                    data={[['DISPOSITIVOS', 'CANT'], ...values]}
                    options={{ title }}
                    rootProps={{ 'data-testid': '3' }}
                /> : <div style={{ gridAutoRows: '18rem' }} className='center'>
                    <div style={{ gridAutoRows: '12rem' }} className='no-data bg-black center'>
                        <b>SIN REGISTROS<br /> EN EL {this.props.no}</b>
                    </div>
                </div>}
                <h5>DISPOSITIVOS</h5>
            </div>
        </Row>
    }
    componentDidMount() {
        this.send()
    }
    componentDidUpdate({ rango }: any) {
        if (rango !== this.props.rango)
            this.send();
    }
    async send() {
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch('/api/Metricas/Dispositivos/' + this.props.rango
                .replace('mes/', '').replace('ano/', ''),
                { headers: { 'Authorization': 'Bearer ' + token } });
            let values = await response.json();
            this.setState({ values });
        } catch (error) {
            console.log(error);
            this.setState({ values: [] });
        }
    }
}