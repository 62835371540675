/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useContext, useCallback } from 'react'
import { AuthSome } from '../../Config/Permisos';
import { conOrden } from '../../Models/Compra';
import { Estado, EstadoNull, GeneralFila, updateOrPush } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { ProveedoresType, ProveedoresContext } from './Proveedor';
import { ProductosContext, ProductosType } from '../Producto';
import { semaforo } from '../../Utiles/Semaforo';
export interface OrdenesType extends ProveedoresType, ProductosType {
    estOrdenes: Estado
    Ordenes: GeneralFila[]
    actOrden: () => void
}
export const OrdenesContext = createContext<OrdenesType | null>(null)
export default function OrdenesProvider({ children }: { children: any }) {
    const [Ordenes, setOrdenes] = useState<GeneralFila[]>([]);
    const proveedores = useContext(ProveedoresContext) as ProveedoresType;
    const pruducto = useContext(ProductosContext) as ProductosType;
    const [est, setest] = useState(EstadoNull);
    const s = semaforo();
    const update = useCallback(() => {
        if (s.isTurn('Ordenes')) {
            if (AuthSome(['COMPRA', 'ORDEN'])) conOrden().cambios((data: any[]) => {
                setest({ status: 3, hora: formatoHora(conOrden().state) });
                setOrdenes(updateOrPush(data, Ordenes, conOrden()))
            }, s.next)
            else s.next()
        }
    }, [Ordenes])
    useEffect(() => {
        if (conOrden().state !== '') setest({ status: 3, hora: formatoHora(conOrden().state) });;
        if (AuthSome(['COMPRA', 'ORDEN'])) conOrden().cargarListaCache(setOrdenes);
        s.add('Ordenes');
        return () => s.clean('Ordenes');
    }, []);
    useEffect(() => {
        let interval = setInterval(update, 100)
        return () => clearInterval(interval)
    }, [Ordenes]);
    return <OrdenesContext.Provider value={{
        ...proveedores, ...pruducto, actOrden: update,
        estOrdenes: est, Ordenes,
    }}>
        {children}
    </OrdenesContext.Provider>
}
export const SeccionConsumer = OrdenesContext.Consumer;