import React from 'react'
import { Col, Button } from 'react-bootstrap';
import Ajuste from '../../../../Models/Ajuste';
import { Detalles } from '../../../../Models/General';
import AuxiliarNuevo, { ProductosModal } from '../../../../Utiles/Prototipos/AuxiliarNuevo';
import FilaEntrada from './FilaEntrada';
import FilaSalida from './FilaSalida';
import FilaPrecio from './FilaPrecio';
import FilaUtilidad from './FilaUtilidad';
import CategoriaBuscar from '../../Departamento/CategoriaBuscar';
import ReactTooltip from 'react-tooltip';

export default class AuxiliarAjuste extends AuxiliarNuevo {
    Row({ data }: { data: Detalles }) {
        const { tipo, dolar } = this.props.data as Ajuste;
        switch (tipo) {
            case 'ENTRADA':
                return <FilaEntrada key={'USD' + data.codigo} producto={data} edit={this.addProductos.bind(this)} remove={this.removeProductos.bind(this)} />;
            case 'SALIDA':
                return <FilaSalida key={'USD' + data.codigo} producto={data} edit={this.addProductos.bind(this)} remove={this.removeProductos.bind(this)} />;
            case 'COSTO':
                return <FilaPrecio dolar={dolar} key={'USD' + data.codigo} producto={data} edit={this.addProductos.bind(this)} remove={this.removeProductos.bind(this)} />;
            case 'UTILIDAD':
                return <FilaUtilidad dolar={dolar} key={'USD' + data.codigo} producto={data} edit={this.addProductos.bind(this)} remove={this.removeProductos.bind(this)} />;
        }
        return <></>
    }
    BotonesLeft() {
        const { tipo } = this.props.data as Ajuste;
        if (tipo !== '') return <Col md={6}><b>TIPO DE AJUSTE: </b> {tipo}</Col>
        return <Col md={6}>
            <select data-tip="FILTRAR POR EL ESTADO" onChange={this.setStatus.bind(this)} className="form-control" value={tipo}>
                <option>SELECCIONE TIPO DE AJUSTE</option>
                <option>ENTRADA</option>
                <option>SALIDA</option>
                <option>COSTO</option>
                <option>UTILIDAD</option>
            </select>
        </Col>
    }
    BotonesRight() {
        const { tipo, dolar } = this.props.data as Ajuste;
        if (tipo === '') return <></>;
        return <Col xs={6} style={{ textAlign: 'right' }} className="float-right">
            <ReactTooltip />
            <Button data-tip="PROCESAR" style={{ marginRight: "15px" }} variant="success"
                className="float-right" onClick={() => this.setData({ procesar: true })} >
                <span className="fa fa-print" />
            </Button>
            {(tipo === 'COSTO' || tipo === 'UTILIDAD') ? <>
                <Button data-tip={"CAMBIAR A " + (dolar ? 'BOLIVARES' : 'DOLARES')} style={{ marginRight: "15px" }}
                    variant={dolar ? 'warning' : "primary"} onClick={() => this.setData({ dolar: !dolar })} size='sm'>
                    {dolar ? <span className="fa fa-dollar-sign" /> : <b>BS</b>} DOCUMENTO EN {dolar ? 'DOLARES' : 'BOLIVARES'}
                </Button>
                <CategoriaBuscar type={tipo} open={this.state.aux} add={this.addGrup.bind(this)} close={this.close.bind(this)} />
                <Button style={{ marginRight: "15px" }} data-tip="APLICAR A DEPARTAMENTO"
                    onClick={() => { this.setState({ aux: true }) }}
                    variant="warning" className="float-right" >
                    <i className="fa fa-university"></i>
                </Button>
            </> : <></>
            }
            <Button style={{ marginRight: "15px" }} data-tip="BUSCAR PRODUCTO"
                onClick={() => { this.setState({ buscarProducto: true }) }}
                variant="primary" className="float-right" >
                <span className="fa fa-search"></span>
            </Button>
        </Col >
    }
    THead() {
        return <tr className='info'>
            <th style={{ width: "100px", textAlign: 'center' }}>CODIGO</th>
            <th style={{ textAlign: 'center' }}>DESCRIPCIÓN</th>
            {this.Th()}
            <th style={{ width: "110px", textAlign: 'center' }}>ACCIONES</th>
        </tr>
    }
    Th() {
        const { tipo } = this.props.data as Ajuste;
        switch (tipo) {
            case 'ENTRADA':
                return <>
                    <th style={{ width: "100px", textAlign: 'center' }}>CANTIDAD</th>
                </>;
            case 'SALIDA':
                return <>
                    <th style={{ width: "100px", textAlign: 'center' }}>CANTIDAD</th>
                    <th style={{ width: "150px", textAlign: 'center' }}>DISPONIBLE</th>
                </>;
            case 'COSTO':
                return <>
                    <th style={{ width: "300px", textAlign: 'center' }}>COSTO</th>
                </>;
            case 'UTILIDAD':
                return <>
                    <th style={{ width: "200px", textAlign: 'center' }}>% UTILIDAD 1</th>
                    <th style={{ width: "200px", textAlign: 'center' }}>% UTILIDAD 2</th>
                    <th style={{ width: "200px", textAlign: 'center' }}>% UTILIDAD 3</th>
                </>;
        }
        return <></>
    }
    setStatus(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setData({ tipo: e.currentTarget.value })
    }
    Calcular() { return <></> }
    addGrup(pr: Detalles[]) {
        let { props: { data: { detalles } } } = this;
        let data = detalles.filter(p => pr.some(d => p.codigo !== d.codigo));
        this.setData({ detalles: [...data, ...pr] });
    }
    componentWillReceiveProps(p: any) {
        const { tipo } = p.data as Ajuste;
        if (this.productos !== tipo) this.productos = tipo as ProductosModal;
    }

}