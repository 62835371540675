import { css } from '@emotion/css';
import { ToRGB } from '../../../../Utiles/Formatos';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import { Invert } from '../Utils/Invert';
export function Contact({ size, data }: any) {
    const { props: { input, inputText, button, buttonText } } = data;
    return <Invert data={data} size={size}>
        <FormGroup className={getCSS(input, inputText, button, buttonText)}>
            <FormControl style={{ gridArea: 'a' }} placeholder="Ingresa su nombre" />
            <FormControl style={{ gridArea: 'b' }} placeholder="Ingresa su correo" />
            <FormControl style={{ gridArea: 'c' }} placeholder="Ingresa el asunto" />
            <FormControl style={{ gridArea: 'd' }} placeholder="Ingresa su telefono" />
            <FormControl style={{ gridArea: 'e' }} as='textarea' placeholder="Por favor, escriba su mensaje aqui" />
            <Button style={{ gridArea: 'f' }} block>Enviar</Button>
        </FormGroup>
    </Invert>
}
function getCSS(color1: string, color2: string, color3: string, color4: string) {
    return css`
    padding: 0 0 0 0 !important;
    margin: 0 0 0 0 !important;
    display: grid;
    gap: 0;
    grid-auto-flow: dense;
    grid-template-areas:    "a a b b"
                            "c c d d"
                            "e e e e"
                            "f f f f";
    &> input, &> textarea{
        width: 100% !important;
        border-radius: 0 !important;
        &:first-child{border-radius: .3rem 0 0 0 !important;}
        &:first-child + input{border-radius: 0 .3rem 0 0 !important;}
        ${toCSS(color1, color2, 'focus')}
    }
    &> button {
        ${toCSS(color3, color4, 'active')}
        border: 0 !important;
        border-radius: 0 0 .3rem .3rem !important;
    }
    `;
}
function toCSS(back: string, text: string, active: string) {
    const { r: br, g: bg, b: bb, a: ba } = ToRGB(back);
    const { r: tr, g: tg, b: tb, a: ta } = ToRGB(text);
    return `
    background-color: ${toColor(br, bg, bb, ba)} !important;
    color: ${toColor(tr, tg, tb, ta)} !important;
    border: 2px solid ${toPlaceholder(br, bg, bb, ba)} !important;
    &:hover{
        background-color: ${toHover(br, bg, bb, ba)} !important;
        color: ${toActive(tr, tg, tb, ta)} !important;
    }
    &:${active}{
        background-color: ${toActive(br, bg, bb, ba)} !important;
        color: ${toHover(tr, tg, tb, ta)} !important;
    }
    &::placeholder{
        color: ${toPlaceholder(br, bg, bb, ba)} !important;
    }`

}
function toPlaceholder(r: number, g: number, b: number, a: number) {
    let r1 = r >= 128 ? 0 : 255;
    let g1 = g >= 128 ? 0 : 255;
    let b1 = b >= 128 ? 0 : 255;
    const max = Math.max(r1, g1, b1);
    const min = Math.min(r1, g1, b1);
    if (max !== min) {
        if (r1 === g1) b1 = b1 === max ? Math.max(r, g, b) : Math.min(r, g, b);
        if (r1 === b1) g1 = g1 === max ? Math.max(r, g, b) : Math.min(r, g, b);
        if (b1 === g1) r1 = r1 === max ? Math.max(r, g, b) : Math.min(r, g, b);
    }
    return toColor(r1, g1, b1, .7);
}
function toHover(r: number, g: number, b: number, a: number) {
    return toColor(r - 30, g - 30, b - 30, a);
}
function toActive(r: number, g: number, b: number, a: number) {
    return toColor(r + 30, g + 30, b + 30, a);
}
function toColor(r: number, g: number, b: number, a: number) {
    r = r > 255 ? 255 : r < 0 ? 0 : r;
    b = b > 255 ? 255 : b < 0 ? 0 : b;
    g = g > 255 ? 255 : g < 0 ? 0 : g;
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

