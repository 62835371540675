
import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Detalles } from '../../../../Models/General'
import { InputNumber } from '../../../../Utiles/Inputs'
import { Producto } from '../../../../Models/Productos'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'
import { ProductosContext, ProductosType } from '../../../../Context/Producto'

interface Props {
    producto: Producto,
    add: (iten: Detalles) => void
    dolar?: boolean
}
export default function FilaPrecio({ producto, add, dolar = false }: Props) {
    const { findProVen, tasa } = useContext(ProductosContext) as ProductosType;
    const { codigo, descripcion, costo } = findProVen(producto, dolar);
    const [precio, setPrecio] = useState((dolar) ? costo * tasa : costo);
    const pre = (dolar) ? precio / tasa : precio;
    return <tr>
        <THFila width='90px'>{codigo}</THFila>
        <THFila width='auto'>{descripcion}</THFila>
        <THInput width='180px'>
            <InputNumber value={pre} step='0.01' setData={(p: number) => setPrecio((dolar) ? p * tasa : p)} />
        </THInput>
        <THInput width='70px'>
            <Button data-tip="AGREGAR PRODUCTO" onClick={() => add({ ...producto, precio, comentario: '', unidades: 0 })} variant="primary">
                <i className="fa fa-plus" />
            </Button>
        </THInput>
    </tr>
}