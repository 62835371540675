/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useCallback } from 'react';
import Usuario, { conUsuario, userNull, UsuarioFila } from '../../Models/Usuario';
import { Estado, EstadoNull } from '../../Models/General'
import { setLineFun } from '../../Config/OnLineCheker';
import { formatoHora } from '../../Utiles/Formatos';
import { don } from '../../Config/Coneccion';
import { setPermisos } from '../../Config/Permisos';
export interface SeccionType {
    data: {
        User: Usuario
        Permisos: Array<any>
        usuarios: UsuarioFila[]
        estUsuarios: Estado
        online: boolean
        cargando: boolean
        seccion: boolean
    }
    atcUsuario: () => void
    logout: () => void,
    usuarios: UsuarioFila[]
    user: () => void
}
export const SeccionContext = createContext<SeccionType | null>(null)
export function SeccionProvider({ children }: { children: any }) {
    const [usuarios, setUsuarios] = useState<UsuarioFila[]>([]);
    const [estUsuarios, setestUsuarios] = useState(EstadoNull);
    const [User, setUser] = useState(userNull);
    const [Permisos, setPermiso] = useState<string[]>([]);
    const [online, setOnline] = useState(false);
    const [cargando, setCargando] = useState(true);
    const [seccion, setSeccion] = useState(true);
    setLineFun(setOnline);
    const user = useCallback(async () => {
        try {
            // //solicitar nuevos datos
            let token = localStorage.getItem('Authorization');
            let response = await fetch('/api/Auth/me', { headers: { 'Authorization': 'Bearer ' + token } });
            let data: Usuario = await response.json();
            setSeccion(data.id_usuario !== 0);
            setCargando(false);
            setUser(data);
            setPermiso(data.permisos);
            //Actualizadar nuevos datos en cache
        } catch (err) {
            //console.log(err);
        }
    }, [])
    const logout = useCallback(async () => {
        try {
            //solicitar nuevos datos
            let token = localStorage.getItem('Authorization');
            let response = await fetch(don + '/api/Auth/close', { headers: { 'Authorization': 'Bearer ' + token } });
            localStorage.removeItem('Authorization');
            await response.json();
            //cargar nuevos datos
            user();
            //Actualizadar nuevos datos en cache
        } catch (err) {
            //console.log(err);
        }
    }, [])
    const atcUsuario = useCallback(() => {
        if (seccion) {
            setestUsuarios({ status: 2, hora: '...ACTUALIZANDO' });
            conUsuario().cargarListaWeb((d) => {
                setUsuarios(d);
                setestUsuarios({ status: 3, hora: formatoHora(undefined) });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    setPermisos(useCallback((per: string[]) => per.some(p => Permisos.some(a => a === p)), [Permisos]))
    useEffect(() => {
        conUsuario().cargarListaWeb(setUsuarios);
        atcUsuario();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seccion]);
    useEffect(() => { user() }, []);
    return <SeccionContext.Provider value={{
        data: { usuarios, User, Permisos, online, cargando, seccion, estUsuarios },
        user, logout, atcUsuario, usuarios
    }}>{children}</SeccionContext.Provider>
}
export const SeccionConsumer = SeccionContext.Consumer;