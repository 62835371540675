import { useContext, useState } from 'react';
import { ButtonGroup, Button, Row, FormControl } from 'react-bootstrap';
import { conPanel } from '../../../../Models/Panel';
import ModalAlert from '../../../../Utiles/Componentes/ModalAlert';
import ReactTooltip from 'react-tooltip';
import { Link } from "react-router-dom";
import { PanelContext } from '../../../../Context/Panel';
const con = conPanel();
export function Opciones({ panel: { id, pagina, estado, type }, actualizar }: { panel: any, actualizar: () => void }) {
    const [open, setOpen] = useState(false);
    return <Row className='m-0 p-0' style={{ position: 'absolute', right: 20, left: 20, zIndex: 500 }}>
        <ButtonGroup>
            <Button data-tip='EDITAR PANEL' as={Link} to={`/panel/edit/${id}`} variant="default">
                <i className='fas fa-edit' />
            </Button>
            {estado === 2 ? <></> : <>
                <Button data-tip='SUBIR PANEL' onClick={() => con.up(id, actualizar, () => { })} variant="default">
                    <i className='fas fa-arrow-up' />
                </Button>
                <Button data-tip='BAJAR PANEL' onClick={() => con.down(id, actualizar, () => { })} variant="default">
                    <i className='fas fa-arrow-down' />
                </Button>
            </>}
        </ButtonGroup>
        {estado === 2 ? <></> : <>
            <SelectPage panel={{ id, pagina }} />
            <ReactTooltip key={`tooltipsPanel${id}${estado}`} />
            <ModalAlert open={open} close={() => setOpen(false)}
                title={`ELIMINAR PANEL ${id}`}
                msn={`DESEA ELIMINAR PANEL ${id} de tipo ${type}`}
                accion={() => con.delete(id, actualizar, () => { })} />
            <ButtonGroup className='ml-auto'>
                <Button data-tip={`${estado === 0 ? 'VER' : 'OCULTAR'} PANEL`} onClick={() => con.cancelar(id, actualizar)} variant={estado === 0 ? "warning" : "success"}>
                    <i className={`fas fa-eye${estado === 0 ? '-slash' : ''}`} />
                </Button>
                <Button data-tip='ELIMINAR PANEL' onClick={() => setOpen(true)} variant="danger">
                    <i className='fas fa-trash' />
                </Button>
            </ButtonGroup>
        </>}
    </Row>
}
export function Slect({ panel, select }: { panel: any, select: (d: any) => void }) {
    return <Row className='w-100' style={{ marginBottom: -30, zIndex: 500 }}>
        <Button variant="success" onClick={() => select(panel)}>
            <i className='fas fa-edit' />
        </Button>
    </Row>
}
function SelectPage({ panel: { id, pagina } }: { panel: any }) {
    const { pages, updatePanel } = useContext(PanelContext);
    const set = (e: React.ChangeEvent<HTMLSelectElement>) => { con.page(id, e.currentTarget.value, updatePanel, updatePanel) }
    return pages.length === 1 ? <></> :
        <FormControl as='select' style={{ width: '200px' }} onChange={set} value={pagina}>
            {pages.map(c => <option key={"user" + c.id} value={c.id}>{c.title}</option>)}
        </FormControl>
}
