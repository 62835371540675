import { Row, Container } from 'react-bootstrap';
import { Html } from '../../Component/Commons';
import { getOrientacionSize } from './WidthSize';


export function Invert({ size, data: { props: { lg, md, ori, sm, textColor, backgroundColor, texto, xl, xs, xxl }, html }, children }: { data: any, children: any, size: string }) {
    const invert = (orie: boolean) => {
        if (!texto || orie)
            return <div className={getOrientacionSize(ori, xs, sm, md, lg, xl, xxl, size)}
                style={{ width: texto ? undefined : '100%', position: 'relative', float: 'left' }}>
                {children}
            </div >
        return <div className={getOrientacionSize(!ori, xs, sm, md, lg, xl, xxl, size)}
            style={{ backgroundColor: textColor, position: 'relative', float: 'left', }}>
            <Html style={{ padding: '30px', margin: 0, position: 'relative', height: '100%', float: 'left' }} html={html} />
        </div>
    }
    return <div style={{ padding: '60px 0', backgroundColor }}>
        <Container>
            <Row className='d-flex'>
                {invert(ori)}
                {texto ? invert(!ori) : <></>}
            </Row>
        </Container>
    </div>
}