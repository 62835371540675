import FilaShort from './FilaShort';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EstadoNull } from '../../../Models/General';
import { formatoHora } from '../../../Utiles/Formatos';
import { conShort, Shor } from '../../../Models/Panel';
import CardBuscador from '../../../Utiles/Buscadores/Card';
import { PanelContext } from '../../../Context/Panel'
class ListShorts extends CardBuscador {
    con = conShort();
    placeholder = "BUSCAR CLAVE";
    buscarEn = ["html", "clave", 'descripcion'];
    estado = EstadoNull;
    Title() {
        return <>
            <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR ATAJO</h4>
            <Link to="/panel/short/nueva" className="btn btn-primary"><span className="fa fa-plus"></span>NUEVA ATAJO</Link>
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='clave' width='150px'>CLAVE</Th>
            <Th label='descripcion' width='300px'>DESCRIPCION</Th>
            <Th width='calc(100% - 520px)'>CAMBIO</Th>
            <Th width='70px'> </Th>
        </tr>
    }
    Row(p: Shor) {
        return <FilaShort key={"cot" + p.clave + p.estatus} data={p} />
    }
    getItens() { return this.context.shors; }
    set() {
        this.estado = { status: 2, hora: '...ACTUALIZANDO' };
        this.context.updateShort(() => this.estado = { status: 3, hora: formatoHora(undefined) })
    }
    Actualizar() {
        const { hora } = this.getEstado();
        return <Col md={3} className='mr-auto d-flex flex-row-reverse'>
            <div style={{ fontSize: '12px', width: "200px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> <br />
                {hora}
            </div>
        </Col>
    }
    getEstado() {
        return this.estado;
    }
    dolar() { }
}
ListShorts.contextType = PanelContext;
export default ListShorts
