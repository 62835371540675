import React from "react"
import { FormControl, InputGroup } from "react-bootstrap"

import moment from 'moment';
interface Props {
    year: number
    mont: number
    setData: (d: any) => void
}

export default function SetMes({ year, mont, setData }: Props) {
    const setChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => setData({ year: parseInt(e.currentTarget.value) })
    const setChangeMont = (e: React.ChangeEvent<HTMLInputElement>) => setData({ mont: parseInt(e.currentTarget.value) })
    function getYear() {
        let yearActual = parseInt(moment().format("YYYY"));
        let years: any[] = [];
        for (let i = 2017; i < yearActual; i++) {
            years.push(<option value={i}>{i}</option>);
        }
        years.push(<option value={yearActual}>{yearActual}</option>);
        return years;
    }
    return <InputGroup>
        <InputGroup.Prepend>
            <InputGroup.Text>seleccionar mes y año</InputGroup.Text>
            <InputGroup.Text>año</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl onChange={setChangeYear} as="select" defaultValue={year}>
            {getYear()}
        </FormControl>
        <InputGroup.Append>
            <InputGroup.Text>mes</InputGroup.Text>
            <div />
        </InputGroup.Append>
        <FormControl onChange={setChangeMont} as="select" defaultValue={mont}>
            <option value={0}>Todo el año </option>
            <option value={1}>ENERO </option>
            <option value={2}>FEBRERO </option>
            <option value={3}>MARZO </option>
            <option value={4}>ABRIL </option>
            <option value={5}>MAYO </option>
            <option value={6}>JUNIO </option>
            <option value={7}>JULIO </option>
            <option value={8}>AGOSTO </option>
            <option value={9}>SEPTIEMBRE </option>
            <option value={10}>OCTUBRE </option>
            <option value={11}>NOVIEMBRE </option>
            <option value={12}>DICIEMBRE </option>
        </FormControl>
    </InputGroup>
}