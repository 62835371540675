import { Panel } from './Panel';
import { Config } from './Config';
import { animatedList } from './Itens/props';
import { GalIten, StateS, Iten, defaultIten } from '../../ProtoPanes/GalIten';
import { getText } from '../../ProtoPanes/DefaultData';
interface I {
    col: number;
    row: number;
    margin: number;
    img: string;
}
interface S {
    color: string;
    width: number;
    gap: number;
    animated: animatedList;
}
export type State = StateS<I> & S;
export type iten = Iten & I;
const aux: I = {
    col: 1, row: 1, margin: 10,
    img: '/public/panels/1-1.jfif',
}
export const dIten: Iten & I = { ...aux, ...defaultIten }
export class Galeria extends GalIten<S, I> {
    constructor(p: any) {
        super(p);
        this.initGal(p, { animated: 'simple', color: '#f0f0f0', width: 180, gap: 5 }, [
            { ...dIten, id: 1, row: 3, col: 4, img: '/public/panels/3-4.jfif' },
            { ...dIten, id: 2, row: 2, img: '/public/panels/r2.jfif' },
            { ...dIten, id: 3 },
            { ...dIten, id: 4 },
            { ...dIten, id: 5, col: 2, img: '/public/panels/c2.jfif' }
        ]);
    }
    Config = () => <Config
        save={this.save}
        key='config'
        iten={this.getIten(aux)}
        setIten={this.setIten}
        setConfig={this.setData}
        title={this.state.title}
        setTitle={this.setTitle}
        removeItem={this.removeItem}
        itenUp={this.itenUp}
        itenDown={this.itenDown}
        addIten={() => this.addIten(aux)}
        config={this.state} />
    Panel = () => <Panel key='panel' config={this.state} seled={this.seleted} />
    saveItens = () => this.state.itens.map(({ img, col, row, margin, color, id, html, back }) => {
        const data = JSON.stringify({});
        const props = JSON.stringify({ img, col, row, margin, color, id, back });
        return { data, props, html: JSON.stringify(getText(html)), id };
    })
    getDataSave = () => {
        const { title, animated, color, width, gap } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ animated, color, width, gap });
        return { title, html: '', data, props, itens: this.saveItens(), type: 'galeria' };
    }

}


