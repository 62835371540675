
export const Leaf = ({ attributes, children, leaf }: any) => {
    const { color, fontFamily, fontSize, bold, italic, underline } = leaf;
    const style = {
        color, fontFamily,
        fontSize: fontSize ? `${fontSize / 10}rem` : undefined,
        fontWeight: bold ? 'bold' : 'normal',
        fontStyle: italic ? 'italic' : 'normal',
        textDecoration: underline ? 'underline' : 'none',
    };
    return <span style={style} {...attributes}>{children}</span>
}