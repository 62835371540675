import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
import { Icon2 } from '../../../Component/Icons/IconSelector';
import { TextToHTML } from '../../../../../Utiles/Texto';

export function Itens({
    config: { seleted, card, border1, border2, back: iback, orientation, font },
    iten, seled }: { iten?: iten, config: State, seled: (i: number) => void }) {
    if (iten === undefined) return <></>;
    const { id, html, icon, color, back, aux, fill } = iten;
    const seletedMe = () => {
        seled(id);
        setTimeout(() => {
            const div = document.getElementById("selectedMe")
            if (div) div.scrollIntoView();
        }, 10)
    }
    return <div id={seleted === id ? 'selectedMe' : undefined} onClick={seletedMe} style={{
        border: seleted === id ? '5px solid #FF0000' : (border1) ? `5px solid ${fill}` : undefined,
        backgroundColor: card !== 'none' ? back : undefined,
        borderRadius: card === 'rounded' ? `${font / 3}rem` : undefined,
        padding: '10px 10px 10px 10px',
    }} className={`d-flex flex-${((orientation === '' && !(id % 2)) || (orientation !== '' && id % 2)) ? 'row' : 'row-reverse'}`}>
        <div className="d-flex justify-content-center">
            <Icon2 icon={icon} font={font} fill={fill} back={iback} border={border2} aux={aux} />
        </div>
        {seleted === id ?
            <div style={{ color }} className="caption  d-flex align-items-center w-100 h-100 p-1"><EditArea key={'edit' + id} /></div> :
            <div style={{ color }} className="caption  d-flex align-items-center w-100 h-100 p-1"><TextToHTML text={html} /></div>
        }
    </div>
}