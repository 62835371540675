import { Conexion } from "../../Config/Coneccion";

export type productoLabel = "codigo" | "descripcion" | "departamento" | "estatus" | "enser" | "tipo" | "costo" | "precio1" | "precio3" | "cantidad" | "unidad" | "fecha_creacion";
export interface Producto {
    codigo: string,
    departamento: string,
    descripcion: string,
    estatus: number,
    enser: number,
    tipo: number,
    costo: number,
    precio1: number,
    precio2: number,
    precio3: number,
    cantidad: number,
    unidad: number,
    exento: boolean,
    fecha_creacion: string
    tasa: number
    medida: string
    inventario: number
    imagen: string
}
export interface ProductoVenta extends Producto {
    monto1: number,
    monto2: number,
    monto3: number
}
export const ProductoNull: Producto = {
    codigo: "",
    departamento: "TODO",
    descripcion: "",
    estatus: 1,
    enser: 0,
    tipo: -1,
    costo: 1,
    precio1: 1,
    precio2: 1,
    precio3: 1,
    cantidad: 0,
    exento: false,
    unidad: -1,
    fecha_creacion: "",
    tasa: 1,
    inventario: 3,
    medida: '',
    imagen: '',
}
export type labelAux = 'codigo' | 'descripcion';
export interface Departamento {
    codigo: string
    descripcion: string
    estatus: number
}
export interface Unidad {
    codigo: number
    descripcion: string
    estatus: number
}
export interface Tipo {
    codigo: number
    descripcion: string
    inventario: number
    estatus: number
}

class ProductoConexion extends Conexion {
    clase = 'Producto';
    persistencia = 1000;
}
var coProd: ProductoConexion;
export function conProducto() {
    if (coProd === undefined) coProd = new ProductoConexion();
    return coProd;
}

class DepartamentoConexion extends Conexion {
    clase = 'Departamento';
    persistencia = 10000;
}
export function conDepartamento() {
    return new DepartamentoConexion();
}

class UnidadConexion extends Conexion {
    clase = 'Unidad';
    persistencia = 10000;
}
export function conUnidad() {
    return new UnidadConexion();
}

class TipoConexion extends Conexion {
    clase = 'Tipo';
    persistencia = 10000;
}
export function conTipo() {
    return new TipoConexion();
}