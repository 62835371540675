import React from 'react';
import { Button, Card } from 'react-bootstrap';
import ModalReport from '../../../Utiles/Componentes/ModalReport';
import Fechas from '../Fechas';
interface State {
    date1: string
    date2: string
    aux: string
    modal: boolean
}

let date = new Date();
export default class Ventas extends React.Component<any, State> {
    state = { aux: "mes", date1: date.getFullYear() + '', date2: (date.getMonth() + 1) + '', modal: false }
    render() {
        let { aux, date2, date1, modal } = this.state;
        return (<Card>
            <ModalReport title={"REPORTE DE VENTAS"} open={modal}
                url={"/api/Factura/reporte/" + aux + '/' + date1 + '/' + date2}
                close={() => { this.setState({ modal: !modal }) }} />
            <Card.Header className='bg-info'>
                <h3>REPORTE DE VENTAS</h3>
            </Card.Header>
            <Card.Body>
                <Fechas aux={aux} date2={date2} date1={date1} setDate={this.setState.bind(this)} />
                <Button className="float-right" onClick={() => { this.setState({ modal: !modal }) }} variant='primary'>
                    <span className="glyphicon glyphicon-print"></span> IMPRIMIR
                </Button>
            </Card.Body>
        </Card>)
    }
}