import { useState, useEffect } from 'react';
import { RowConfig, CardConfig, TitleCard, ButtonColor, ButtonConfig } from '../../../Component';
import { Props } from './index';
import { InputText } from '../../../../../Utiles/Inputs';
import { InputGroup, ButtonGroup, FormControl } from "react-bootstrap";
import { IconSelector, icon } from '../../../Component/Icons/IconSelector';
import { SetLink } from './Link';
import Slider from '@material-ui/core/Slider'
export function Boton({ data: { clave, data }, setData }: Props) {
    const [text, setText] = useState(data.text ? data.text : '');
    const [textWidth, setTextWidth] = useState(data.textWidth ? data.textWidth : 1);
    const [link, setLink] = useState(data.link ? data.link : '');
    const [target, setTarget] = useState(data.target ? data.target : '');
    const [aling, setAling] = useState(data.aling ? data.aling : '');
    const [border, setBorder] = useState(data.border ? data.border : 0);
    const [esquina, setEsquina] = useState(data.esquina ? data.esquina : 2);
    const [bs, setBS] = useState(data.bs ? data.bs : '#28a745');
    const [bh, setBH] = useState(data.bh ? data.bh : '#5FdF7F');
    const [ba, setBA] = useState(data.ba ? data.ba : '#007010');
    const [fs, setFS] = useState(data.fs ? data.fs : '#000000');
    const [fh, setFH] = useState(data.fh ? data.fh : '#000000');
    const [fa, setFA] = useState(data.fa ? data.fa : '#FFFFFF');
    const [i, setI] = useState(data.i ? data.i : false);
    const [icon, setIcon] = useState<icon>(data.icon ? data.icon : { width: 0, height: 0, path: '' });
    const [iconWidth, setIconWidth] = useState(data.iconWidth ? data.iconWidth : 1);
    const [iconPosition, setIconPosition] = useState(data.iconPosition ? data.iconPosition : 1);
    useEffect(() => setData({
        data: {
            clave, textWidth, text, link, border,
            bh, bs, ba, fs, fh, fa, aling, esquina,
            i, icon, iconWidth, iconPosition, target
        },
        html: getHTML(clave, textWidth, text, link, border,
            bh, bs, ba, fs, fh, fa, aling, esquina,
            i, icon, iconWidth, iconPosition, target)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [clave, textWidth, text, link, border,
        bh, bs, ba, fs, fh, fa, aling, esquina,
        i, icon, iconWidth, iconPosition, target]);
    console.log({
        clave, textWidth, text, link, border,
        bh, bs, ba, fs, fh, fa, aling, esquina,
        i, icon, iconWidth, iconPosition, target
    });
    return <>
        <CardConfig marginNull width="100%">
            <RowConfig>
                <InputGroup className='input-group-sm' data-tip='TEXTO QUE SE MOSTRARA EN EL BOTON'
                    style={{ height: '24px', width: 'calc(100% - 187px - .25rem)' }}>
                    <InputGroup.Prepend style={{ height: '24px', width: '65px' }}>
                        TEXTO:
                    </InputGroup.Prepend>
                    <InputText style={{ height: '24px' }} className='form-control-sm'
                        setData={setText} value={text} />
                </InputGroup>
                <div data-tip='TAMAÑO DEL TEXTO EN EL BOTON ' style={{ width: '187px', height: '24px', marginTop: '-2px' }} className='ml-1'>
                    <Slider onChange={(e: any, d: any) => setTextWidth(d)} max={4} min={.5}
                        step={.01} value={textWidth} style={{ width: '100%' }} />
                </div>
            </RowConfig>
            <SetLink data={link} setData={(a, b) => { setLink(a); setTarget(b) }} />
            <TitleCard>TEXTO Y LINK</TitleCard>
        </CardConfig>
        <CardConfig marginNull width="100%">
            <RowConfig>
                <IconSelector nullValue={true} value={icon}
                    onChange={(i, s) => {
                        setIcon(i);
                        setI(s ? s : false);
                        if (s) {
                            setIconPosition(1);
                            setIconWidth(1)
                        }
                    }} />
                <ButtonGroup className='ml-1'>
                    <ButtonConfig tip='ICONO A LA IZQUIERDA' active={iconPosition === 1} disabled={!i} action={() => setIconPosition(1)} icon='fas fa-arrow-left' />
                    <ButtonConfig tip='ICONO ARRIBA' active={iconPosition === 2} disabled={!i} action={() => setIconPosition(2)} icon='fas fa-arrow-up' />
                    <ButtonConfig tip='ICONO DERECHA' active={iconPosition === 3} disabled={!i} action={() => setIconPosition(3)} icon='fas fa-arrow-right' />
                </ButtonGroup>
                <div data-tip='TAMAÑO DEL ICONO EN EL BOTON ' style={{ width: '345px', height: '24px', marginTop: '-2px' }} className='ml-1 pl-1'>
                    <Slider disabled={!i} onChange={(e: any, d: any) => setIconWidth(d)} max={12} min={1}
                        step={.1} value={iconWidth} style={{ width: '100%' }} />
                </div>
            </RowConfig>
            <TitleCard>ICONO</TitleCard>
        </CardConfig>
        <RowConfig top={1}>
            <CardConfig style={{ margin: '0 0 0 0' }} width="284px">
                <RowConfig>
                    <div className='w-25'>
                        ESTADO:
                    </div>
                    <div className='w-25 text-center'>
                        NORMAL
                    </div>
                    <div className='w-25 text-center'>
                        SOBRE
                    </div>
                    <div className='w-25 text-center'>
                        CLICK
                    </div>
                </RowConfig>
                <RowConfig top={2}>
                    <div className='w-25'>
                        FONDO:
                    </div>
                    <div className='w-25  text-center'>
                        <ButtonColor tip='COLOR DE FONDO DEL BOTON' setData={setBS} color={bs} >
                            <i className="fas fa-palette"></i>
                        </ButtonColor>
                    </div>
                    <div className='w-25 text-center'>
                        <ButtonColor tip='COLOR DE FONDO DEL BOTON CUANDO EL RATON ESTA SOBRE EL' setData={setBH} color={bh} >
                            <i className="fas fa-palette"></i>
                        </ButtonColor>
                    </div>
                    <div className='w-25 text-center'>
                        <ButtonColor tip='COLOR DE FONDO DEL BOTON CUANDO SE HACE CLICK' setData={setBA} color={ba} >
                            <i className="fas fa-palette"></i>
                        </ButtonColor>
                    </div>
                </RowConfig>
                <RowConfig top={2}>
                    <div className='w-25'>
                        FUENTE:
                    </div>
                    <div className='w-25  text-center'>
                        <ButtonColor tip='COLOR DE FUENTE DEL BOTON' invert={true} setData={setFS} color={fs} >
                            <i className="fas fa-font"></i>
                        </ButtonColor>
                    </div>
                    <div className='w-25 text-center'>
                        <ButtonColor tip='COLOR DE FUENTE DEL BOTON CUANDO EL RATON ESTA SOBRE EL' invert={true} setData={setFH} color={fh} >
                            <i className="fas fa-font"></i>
                        </ButtonColor>
                    </div>
                    <div className='w-25 text-center'>
                        <ButtonColor tip='COLOR DE FUENTE DEL BOTON CUANDO SE HACE CLICK' invert={true} setData={setFA} color={fa} >
                            <i className="fas fa-font"></i>
                        </ButtonColor>
                    </div>
                </RowConfig>
                <TitleCard>COLORES</TitleCard>
            </CardConfig>
            <CardConfig width="223px">
                <RowConfig>
                    ESTILO:
                    <Estilos value={esquina} change={setEsquina} />
                </RowConfig>
                <RowConfig top={2}>
                    BORDE:
                    <div data-tip='GROSOR DEL BORDE DEL BOTON' style={{ width: '130px', height: '24px', marginTop: '-2px' }} className='ml-auto mr-1'>
                        <Slider onChange={(e: any, d: any) => setBorder(d)} max={5} min={0}
                            step={.1} value={border} style={{ width: '100%' }} />
                    </div>
                </RowConfig>
                <RowConfig top={2}>
                    POSICION:
                    <ButtonGroup className='ml-auto'>
                        <ButtonConfig tip='TEXTO ALINEADO A LA IZQUIERDA' style={{ width: '28px' }} icon="fas fa-align-left" active={aling === ''} action={() => setAling('')} />
                        <ButtonConfig tip='TEXTO CENTRADO' style={{ width: '28px' }} icon="fas fa-align-center" active={aling === 'text-center'} action={() => setAling('text-center')} />
                        <ButtonConfig tip='TEXTO ALINEADO A LA DERECHA' style={{ width: '28px' }} icon="fas fa-align-right" active={aling === 'text-right'} action={() => setAling('text-right')} />
                        <ButtonConfig tip='TEXTO JUSTIFICADO' style={{ width: '28px' }} icon="fas fa-align-justify" active={aling === 'btn-block'} action={() => setAling('btn-block')} />
                    </ButtonGroup>
                </RowConfig>
                <TitleCard>POSICION Y ESTILO</TitleCard>
            </CardConfig>
        </RowConfig>
    </>
}
export default function Estilos({ change, value }: { change: (d: number) => void, value: number }) {
    const set = (e: React.ChangeEvent<HTMLSelectElement>) => { change(parseInt(e.currentTarget.value)) }
    const s = { fontSize: '.8rem' }
    return <FormControl data-tip='ESTILO O DISEÑO DEL BOTON' as='select' className='ml-auto' onChange={set} value={value}
        style={{ height: '24px', width: '150px', padding: '.2rem .2rem .2rem .2rem', ...s }} >
        <option style={s} value={1}>RECTANGULAR</option>
        <option style={s} value={2}>ESQUINAS SUAVES</option>
        <option style={s} value={10}>ESQUINAS SUAVES ARRIBA</option>
        <option style={s} value={9}>ESQUINAS SUAVES ABAJO</option>
        <option style={s} value={3}>SEMI-CIRCULO</option>
        <option style={s} value={8}>SEMI-CIRCULO ARRIBA</option>
        <option style={s} value={7}>SEMI-CIRCULO ABAJO</option>
        <option style={s} value={4}>OVALADO</option>
        <option style={s} value={5}>BANDERA 1</option>
        <option style={s} value={6}>BANDERA 2</option>
    </FormControl>
}

function getHTML(clave: string, textWidth: number, text: string, link: string, border: number,
    bh: string, bs: string, ba: string, fs: string, fh: string, fa: string, aling: string, esquina: number,
    i: boolean, icon: icon, iconWidth: number, iconPosition: number, target: string) {
    const radius = iconWidth + textWidth + border;
    const styleRadius = esquina === 1 ? 0 :
        esquina === 2 ? (radius / 8) + 'rem' :
            esquina === 3 ? radius + 'rem' :
                esquina === 4 ? '100%' :
                    esquina === 5 ? `0 ${radius}rem 0 ${radius}rem ` :
                        esquina === 6 ? `${radius}rem 0 ${radius}rem 0` :
                            esquina === 7 ? `0 0 ${radius}rem ${radius}rem ` :
                                esquina === 8 ? `${radius}rem ${radius}rem 0 0` :
                                    esquina === 9 ? `0 0 ${(radius / 8)}rem ${(radius / 8)}rem ` :
                                        esquina === 10 ? `${(radius / 8)}rem ${(radius / 8)}rem 0 0` : '';
    const padding = (esquina === 5 || esquina === 6) ? (iconPosition === 2) ? radius / 2 : radius / 4 : radius / 8;
    return `
    <style>
        .${clave} {
            background-color: ${bs};
            border-color: ${fs};
            color: ${fs};
            border-radius: ${styleRadius};
            border: ${fs} solid ${border}px;
            padding: 0 ${padding}rem 0 ${padding}rem;
        }
        .${clave}:hover {
            background-color: ${bh};
            border-color: ${fh};
            color: ${fh};
            border-color: ${fh};
        }
        .${clave}:active {
            background-color: ${ba};
            border-color: ${fa};
            color: ${fa};
            border-color: ${fa};
        }
    </style>  
    <div class='w-100  ${aling !== 'btn-block' ? aling : ''}'>
        <a href="${link}" target="${target}" class='btn ${clave} ${aling === 'btn-block' ? `${aling} d-flex justify-content-center` : ''}  '>
            <div class='d-flex flex-${i ? iconPosition === 1 ? 'row' : iconPosition === 2 ? 'column' : 'row-reverse' : ''}'>
                ${i ? getIcon(icon, iconWidth) : ''}
                <div class='align-self-center'
                    style='font-size: ${textWidth}rem;font-weight:bolder;'> ${text}</div> 
            </div> 
        </a>
    </div>`
}
function getIcon(icon: icon, iconWidth: number) {
    return `<div class='d-flex justify-content-center align-self-center'>
        <svg width='${iconWidth}rem' height='${iconWidth}rem' xmlns="http://www.w3.org/2000/svg" viewBox='0 0 ${icon.width} ${icon.height}'>
            ${typeof icon.path === 'string' ? `<path style='fill:currentColor;' d='${icon.path}' />` :
            (icon.path as string[]).map((t, i) =>
                `<path style='fill:currentColor;opacity:${i === 0 ? .7 : 1};' d='${t}' />`)}
        </svg>
    </div>`
}

