import { useState, createContext, useEffect, useCallback } from 'react'
import { ConConfig } from '../../Models/Config';
export interface ConfigType {
    dolar: boolean
    impuesto: number
    precio1: number
    precio2: number
    precio3: number
    impuesto1: number
    contribuyente: string
    actConfig: () => void
}
export const ConfigContext = createContext<ConfigType | null>(null)
export default function ConfigProvider({ children }: { children: any }) {
    const [precio1, setprecio1] = useState(1);
    const [precio2, setprecio2] = useState(1);
    const [precio3, setprecio3] = useState(1);
    const [impuesto, setimpuesto] = useState(1);
    const [impuesto1, setimpuesto1] = useState(0);
    const [contribuyente, setcontribuyente] = useState('NORMAL');
    const [dolar, setdolar] = useState(false);
    const actConfig = useCallback(() => {
        ConConfig('costo').pull(d => setdolar(d.tasa));
        ConConfig('utilidades').pull(d => { setprecio1(d.precio1); setprecio2(d.precio2); setprecio3(d.precio3); });
        ConConfig('Region').pull(d => { setimpuesto(d.impuesto); setimpuesto1(d.impuesto1); setcontribuyente(d.contribuyente); });
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(actConfig, [])
    return <ConfigContext.Provider value={{ impuesto1, contribuyente, dolar, impuesto, precio1, precio2, precio3, actConfig }}>
        {children}
    </ConfigContext.Provider>
}
export const SeccionConsumer = ConfigContext.Consumer;