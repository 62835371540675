import React from "react";
import { Col, Card, Accordion, Button, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { ComprasContext, ComprasType } from "../../../../Context/Compras";
import { ConConfig } from "../../../../Models/Config";
import { Compra, compraNull, conCompra, sentCompra } from "../../../../Models/Compra";
import CreateOperacion from "../../../../Utiles/Componentes/CreateOperacion";
import { ControlText } from "../../../../Utiles/FormControl";
import { InputArea } from "../../../../Utiles/Inputs";
import ProveedorSelector from "../../proveedores/Selector";
import AuxiliarCompra from "./AuxiliarCompra";
class NuevaCompra extends CreateOperacion {
    con = conCompra();
    title = 'COMPRA'
    msn = 'LA COMPRA';
    constructor(p: any) {
        super(p);
        this.state = { ...compraNull, procesar: false };
        this.tipo = 'Compra';
    }
    Top() {
        const { cod_proveedor, cod_documento, nun_control, fecha_documento, nota } = this.getData() as Compra;
        return <>
            <ProveedorSelector Proveedor={cod_proveedor} add={this.setProveedor.bind(this)} />
            <Accordion>
                <Accordion.Toggle eventKey='0' as={Button} variant='primary'>DATOS OPCIONALES</Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col data-tip="NUMERO DE FACTURA" xs={4}>
                                    <ControlText maxLength={50} before="N° FACTURA" value={cod_documento} setData={this.setState} update="cod_documento" />
                                </Col>
                                <Col data-tip="NUMERO DE CONTROL" xs={4}>
                                    <ControlText maxLength={50} before="N° CONTROL" value={nun_control} setData={this.setState} update="nun_control" />
                                </Col>
                                <Col data-tip="FECHA DE LA FACTURA" xs={4}>
                                    <ControlText type='date' before="FECHA" value={fecha_documento} setData={this.setState} update="fecha_documento" />
                                </Col>
                                <Col xs={12}><br />
                                    <b>NOTA</b>
                                    <InputArea name='nota' rows={4} maxLength={600} value={nota} setData={this.setState} update="nota" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Accordion.Collapse>
            </Accordion>
        </>
    }
    Productos() {
        return <AuxiliarCompra key="auxCompra" setData={this.setState} data={this.state} />
    }
    setConfig = () => ConConfig('compra').pull(({ nota }) => this.setState({ nota }));
    Procesar() {
        let procesar = true;
        const { impuesto } = this.context as ComprasType;
        const data = this.getData() as Compra;
        if (data.cod_proveedor === "") {
            toast('DEBE SELECCIONAR UN PROVEEDOR', { type: toast.TYPE.ERROR });
            procesar = false;
        }
        procesar = this.validacionGenerica(procesar);
        if (procesar)
            this.con.nuevo(sentCompra(data, impuesto),
                data => this.setState({ codigo: data }),
                () => this.procesar = true);
    }
    setProveedor(cod_proveedor: string) { this.setState({ cod_proveedor }); }

}
NuevaCompra.contextType = ComprasContext;
export default NuevaCompra;