import React from 'react';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { InputText } from '../../../Utiles/Inputs';
import Estados from '../../../Utiles/Componentes/Estados';
import { conNota } from '../../../Models/Ventas';
import { GeneralFila } from '../../../Models/General';
import ModalBusqueda from '../../../Utiles/Buscadores/Modal';
import FilaGeneraModal from '../../../Utiles/Prototipos/FilaGeneraModal';
import { NotasContext } from '../../../Context/Ventas/Notas';
class BuscarNota extends ModalBusqueda {
    con = conNota();
    buscarEn = ['codigo', 'nombre', 'fecha',];
    status = -1;

    Title() {
        return <h4 className='mr-auto'>BUSCAR NOTA DE ENTREGA</h4>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='100px'>NUMERO</Th>
            <Th label='fecha' width='90px'>FECHA</Th>
            <Th label='nombre' width='calc(100% - 585px)'>CLIENTE</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='135px'>ACCIONES</Th>
        </tr>
    }
    Row(c: GeneralFila) {
        return <FilaGeneraModal key={c.codigo + c.status} data={c} type='Nota' add={this.add.bind(this)} />
    }
    add(c: GeneralFila) {
        this.props.add(c);
        this.props.close();
    }
    Buscador() {
        return <Row style={{ paddingBottom: '15px' }}>
            <Col className="col-md-9 mr-auto">
                <InputGroup style={{ paddingBottom: '15px' }}>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {this.buscarLabel}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                    <Estados status={this.status} estatus={['CANCELADO', 'ENTREGADA', 'FACTURADA']} setData={d => { this.status = d; this.forceUpdate() }} />
                    <InputGroup.Append>
                        <Button variant="primary">
                            <i className="fa fa-search" /> BUSCAR
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            {this.Actualizar()}
        </Row>
    }
    filter() {
        let itens = this.ordenar();
        return itens.filter((c: GeneralFila) => {
            if (c.status !== this.status && this.status !== -1) return false;
            return true;
        });
    }
    getItens() { return this.context.Notas; }
    set() { this.context.actNota(); }
    getEstado() { return this.context.estNota; }
    reset(){this.context.resetVenta()}
}
BuscarNota.contextType = NotasContext;
export default BuscarNota