import { useEffect, useContext } from "react";
import { CardConfig, RowConfig, ButtonConfig, TitleCard } from '../../../Panel/Component';
import { VideoContext } from '../Context/Video';
export function Play() {
    const { play, loop, mute, setMute, setLoop, load } = useContext(VideoContext);
    let video = document.getElementById('video') as HTMLVideoElement;
    useEffect(() => {
        if (video !== null && load) video.onclick = () => {
            if (play) video.pause(); else video.play();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [play, load]);
    return <CardConfig marginNull width="140">
        <RowConfig>
            <ButtonConfig icon={`fas fa-${play ? "pause" : "play"}`}
                action={() => (play) ? video.pause() : video.play()} />
            <ButtonConfig className='m-auto' action={() => setMute(!mute)}
                icon={`fas fa-volume-${mute ? "mute" : "up"}`} />
            <ButtonConfig action={() => setLoop(!loop)}
                icon={`fas fa-${loop ? "sync" : "redo"}`} />
        </RowConfig>
        <TitleCard>t</TitleCard>
    </CardConfig>
}