import { InputGroup } from 'react-bootstrap';
import { InputNumber } from '../../../../Utiles/Inputs';
import { CardMain, SizeViewSelector, Title, Guardar, ButtonColor, TitleCard, CardConfig, RowConfig } from '../../Component';
import { State, iten } from './index'
import { ItenImagen, TBElementItens } from '../../Component/Commons';
interface Props {
    save: () => void
    iten: iten;
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void,
    setIten: (d: Partial<iten>) => void
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    addIten: () => void
    removeItem: (d: number) => void
}
export function Config({ save, title, config, setTitle, setConfig, setIten, iten, itenUp, itenDown, addIten, removeItem }: Props) {
    const { size, gap, color, itens: { length } } = config;
    const { back } = iten;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="227px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <ButtonColor disableAlpha tip='COLOR DE FONDO DEL PANEL' setData={color => setConfig({ color })} color={color}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <InputGroup data-tip='SEPARACION ENTRE CARTAS' className='input-group-sm ml-1' style={{ height: '24px', width: 70 }}>
                    <InputGroup.Prepend style={{ height: '24px', width: '38px' }}>
                        <InputGroup.Text style={{ height: '24px', width: '38px' }}>
                            <i style={{ borderLeft: 'solid 3px currentColor', borderRight: 'solid 3px currentColor' }} className="fas fa-arrows-h"></i>
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputNumber max={50} step={1} min={0} decimal={0} style={{ height: 24 }} value={gap} setData={gap => setConfig({ gap })} />
                </InputGroup>
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <ItenImagen
            itenDown={itenDown} removeItem={removeItem}
            setImagen={(img) => setIten({ img })}
            cont={length} iten={iten} aspect={1}
            addItem={addIten} itenUp={itenUp} />
        <TBElementItens back={back} setBack={back => setIten({ back })} />
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}