import { conMedia, Tags, Media } from '../../../Models/Media';
import { Col, Button, Row, Modal } from 'react-bootstrap';
import { ControlText } from '../../../Utiles/FormControl';
import { InputText } from '../../../Utiles/Inputs';
import ReactTooltip from 'react-tooltip';
import { useState } from 'react';
interface Props {
    tagsList: Tags[],
    updateTags: () => void,
    img: Media,
    update: () => void
}
export function Nombre({ img: { id, nombre: n, tags: t }, update, tagsList, updateTags }: Props) {
    const [nombre, setNombre] = useState(n);
    const [tagName, setTagName] = useState('');
    const [newTag, setNewTag] = useState('');
    const [open, setOpen] = useState(false);
    const [tags, setTags] = useState(t);
    const selected = (id: number) => (tags.some(p => p === id)) ? "success" : "default";
    const selected2 = (id: number) => (tags.some(p => p === id)) ? "QUITAR ETIQUETA" : "AGREGAR ETIQUETA";
    const click = (i: number) => setTags(tags.some(p => p === i) ? tags.filter(p => p !== i) : [...tags, i]);
    const actualizar = () => { conMedia().actualizar(id, { tags, nombre }, update); setOpen(false) }
    return <>
        <Button data-tip='CAMBIAR NOMBRE Y ETIQUETAS' style={{ borderRadius: 0 }}
            size='sm' variant='info' onClick={() => setOpen(true)} className='ml-auto'>
            <i className='fas fa-tag' />
        </Button>
        <Modal size="lg" animation centered show={open} onHide={() => setOpen(false)}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center" >
                    CAMBIAR NOMBRE A LA IMAGEN {id}
                </h6>
            </Modal.Header>
            <Modal.Body>
                <ReactTooltip key={`tooltipMedia${id}tag${tags.reduce((a, v) => a + v, 0)}`} />
                <Row>
                    <Col md={12}>
                        <ControlText data-tip='CAMBIAR NOMBRE' maxLength={20} upper before="NOMBRE" setData={setNombre} value={nombre} /><br />
                        <Row className="mb-1 pl-2 pr-2">
                            <Row style={{ width: '240px' }} className="mr-auto pb-1 pt-1">
                                <small className='m-auto'>BUSCAR ETIQUETAS</small>
                                <InputText className='form-control-sm' style={{ width: '120px' }} maxLength={20} upper setData={setTagName} value={tagName} /><br />
                            </Row>
                            <Row style={{ width: '271px' }} className="ml-auto pb-1 pt-1">
                                <small className='m-auto'>NUEVA ETIQUETA</small>
                                <InputText className='form-control-sm' style={{ width: '120px' }} maxLength={20} upper setData={setNewTag} value={newTag} /><br />
                                <Button onClick={() => { if (newTag !== '') conMedia().newTag(newTag, updateTags); setNewTag('') }} size='sm' variant='success' >
                                    <i className='fas fa-save' />
                                </Button>
                            </Row>
                        </Row>
                        <Row style={{ height: '45px', overflowY: 'auto' }}>
                            {tagsList.filter(i => i.id < 0)
                                .filter(i => (tags.some(p => p === i.id)))
                                .map((p) => <Button data-tip={selected2(p.id)}
                                    className="ml-1 mb-1"
                                    style={{ borderRadius: '1rem', height: 40 }}
                                    variant={selected(p.id)}>{p.nombre}</Button>)}
                        </Row>
                        <Row style={{ height: '300px', overflowY: 'auto' }}>
                            {tagsList
                                .filter(i => i.id > 0)
                                .filter(i => {
                                    const en = tagName.split(" ");
                                    const con = en.filter(e => i.nombre.toUpperCase().indexOf(e) > -1);
                                    return en.length === con.length;
                                }).map((p) => <Button data-tip={selected2(p.id)} className="ml-1 mb-1" style={{ borderRadius: '1rem', height: 40 }} onClick={() => click(p.id)} variant={selected(p.id)}>{p.nombre}</Button>)}
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={actualizar} className="float-right" variant="primary">
                    <span className="fa fa-floppy-o" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal >
    </>
}