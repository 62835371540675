import React from "react";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import { Conexion } from "../../Config/Coneccion";
import { conCotizacion } from "../../Models/Ventas";
import { EstadoNull } from "../../Models/General";
import Tabla from "../Tabla";
interface Props {
    title?: string
    open: boolean,
    close: () => void
    add: (d: any) => void
}
export default class ModalBusqueda<p = {}> extends Tabla<Props & p>{
    con: Conexion = conCotizacion();
    variant = 'bg-info';
    constructor(p: any) {
        super(p);
        this.Title = this.Title.bind(this);
    }
    Title() {
        return <></>;
    }
    render() {
        let { Body, Buscador, Title, props: { open, close } } = this;
        return <Modal show={open} onHide={close} size='xl'>
            <Modal.Header className={this.variant} closeButton>
                <Title />
            </Modal.Header>
            <Modal.Body>
                <div style={{ padding: "10px 10px 10px 10px", overflowX: 'scroll' }}>
                    <Buscador />
                    <Body />
                </div>
            </Modal.Body>
        </Modal>
    }
    reset() { }
    set() { this.con.cargarListaWeb((itens) => { this.setState({ itens }) }); }
    componentDidMount() { this.set(); }
    getEstado() { return EstadoNull; }
    Actualizar() {
        const { status, hora } = this.getEstado();
        return <Col md={3} className='mr-auto d-flex flex-row-reverse'>
            <Button size='sm' onClick={this.reset.bind(this)} disabled={status === 2} data-tip="ACTUALIZAR LISTA" variant="info">
                {(status === 2) ? <><Spinner animation="border" size='sm' role="status" /></> : <><i className="fa fa-refresh"></i> ACTUALIZAR</>}
            </Button>
            <div style={{ fontSize: '12px', width: "200px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> <br />
                {hora}
            </div>
        </Col>
    }
}