import { css } from '@emotion/css';
import { ToRGB } from '../../../../Utiles/Formatos';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import { Invert } from '../Utils/Invert';
export function Suscribir({ size, data }: any) {
    const { props: { input, inputText, button } } = data;
    return <Invert data={data} size={size}>
        <div className={getCSS(input, inputText, button)}>
            <FormGroup>
                <FormControl size='lg' placeholder="Ingresa tu nombre" />
                <FormControl size='lg' placeholder="Ingresa tu correo" />
                <Button block size='lg'>Subscribirse</Button>
            </FormGroup>
        </div>
    </Invert>
}
function getCSS(color1: string, color2: string, color3: string) {
    const { r: r2, g: g2, b: b2, a: a3 } = ToRGB(color1);
    const { r: fr, g: fg, b: fb, a: fa } = ToRGB(color2);
    const { r: ir, g: ig, b: ib, a: ia } = ToRGB(color3);
    const n1 = `rgba(${r2}, ${g2}, ${b2}, ${a3})`;
    const n2 = `rgba(${r2 - 50}, ${g2 - 50}, ${b2 - 50}, ${a3})`;
    const a1 = `rgba(${fr}, ${fg}, ${fb}, ${fa})`;
    const a2 = `rgba(${fr + 50}, ${fg + 50}, ${fb + 50}, ${fa})`;
    const a4 = `rgba(${fr}, ${fg}, ${fb}, .7)`;
    const c1 = `rgba(${ir}, ${ig}, ${ib}, ${ia})`;
    const c2 = `rgba(${ir - 50}, ${ig - 50}, ${ib - 50}, ${ia})`;
    return css`
    &> div{
        padding: 10px 10px 10px 10px !important;
        margin: 0 0 0 0 !important;
    }
    &> div> input {
        width: 100% !important;
        background-color: ${n1} !important;
        color: ${a1} !important;
        border-radius: 0 !important;
        &:first-child{border-radius: .3rem .3rem 0 0 !important;}
        &:focus{
            background-color: ${n2} !important;
            color: ${a2} !important;
        }
        &::placeholder{
            color: ${a4} !important;
        }
    }
    &> div> button {
        background-color: ${c1} !important;
        color: ${a1} !important;
        &:hover{
            background-color: ${c2} !important;
            color: ${a2} !important;
        }
        border-radius: 0 0 .3rem .3rem !important;
    }
    `;
}

