import { Conexion } from "../../../Config/Coneccion";
class FacturaConexion extends Conexion {
    clase = 'Factura';
    persistencia = 1000;
    commadUpdate() {
        this.updater(this.clase)
        this.updater('Producto')
    }
}
var conFac: FacturaConexion;
export default function conFactura() {
    if (conFac === undefined) conFac = new FacturaConexion();
    return conFac;
}