import { Panel } from './Panel';
import { Config } from './Config';
import { icon } from '../../Component/Icons/IconSelector';
import { GalIten, StateS, Iten, defaultIten } from '../../ProtoPanes/GalIten';
import { getText } from '../../ProtoPanes/DefaultData';
interface I {
    icon: icon;
    aux: string;
    fill: string;
}
interface S {
    color: string;
    gap: number;
    card: 'none' | 'rounded' | 'rectangle';
    border1: boolean;
    border2: boolean;
    back: 'none' | 'circle' | 'rounded' | 'rectangle';
    font: number,
    orientation: '' | 'reverse',
}
export type State = StateS<I> & S;
export type iten = Iten & I;
const aux: I = {
    icon: { width: 384, height: 512, path: "M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z" },
    aux: '#cccc00', fill: '#00bbbb',
}
export const dIten: Iten & I = { ...aux, ...defaultIten }
export class Servicio2 extends GalIten<S, I> {
    constructor(p: any) {
        super(p);
        this.initGal(p, {
            color: '#f0f0f0', gap: 5, card: 'rounded',
            border1: true, border2: true, back: 'circle',
            font: 7, orientation: ''
        }, [
            { ...dIten, id: 1 },
            { ...dIten, id: 2 },
            { ...dIten, id: 3 },
            { ...dIten, id: 4 },
            { ...dIten, id: 5 },
            { ...dIten, id: 6 }
        ]);
    }
    Config = () => <Config
        save={this.save}
        key='config'
        iten={this.getIten(aux)}
        setIten={this.setIten}
        setConfig={this.setData}
        title={this.state.title}
        setTitle={this.setTitle}
        removeItem={this.removeItem}
        itenUp={this.itenUp}
        itenDown={this.itenDown}
        addIten={() => this.addIten(aux)}
        config={this.state} />
    Panel = () => <Panel key='panel' config={this.state} seled={this.seleted} />
    getDataSave = () => {
        const { title, color, gap, card, border1, border2, back, font, orientation } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color, gap, card, border1, border2, back, font, orientation });
        return { title, html: '', data, props, itens: this.saveItens(), type: 'servivio2' };
    }
    saveItens = () => this.state.itens.map(({ icon, aux, fill, color, id, html, back }) => {
        const data = JSON.stringify({});
        const props = JSON.stringify({ icon, aux, fill, color, id, back });
        return { data, props, html: JSON.stringify(getText(html)), id };
    })
}