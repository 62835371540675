import { Conexion } from "../../../Config/Coneccion";
import { Proveedor, ProveedorNull } from "../../Compra";
export type ClienteLabel = 'codigo' | 'nombre' | 'telefono' | 'correo' | 'contacto' | 'direccion' | 'estatus' | 'tipo_contribuyente' | 'retencion'
export interface Cliente extends Proveedor {
    tipo_contribuyente: string,
    retencion: number
}
export var ClienteNull: Cliente = {
    ...ProveedorNull,
    tipo_contribuyente: "ORDINARIO",
    retencion: 0
}

class CienteConexion extends Conexion {
    clase = 'Cliente';
    persistencia = 1000;
}
var conCl: CienteConexion;
export function conCliente() {
    if (conCl === undefined) conCl = new CienteConexion();
    return conCl;
}