import { useEffect, useContext } from 'react'
import { Card, Button, Modal, Row } from 'react-bootstrap';
import { Link } from "react-router-dom"
import { Slect } from './Utils';
import { PreviewSize } from './PreviewSize';
import { PanelContext } from '../../../Context/Panel'
import { Panel } from '../../../Models/Panel'
export default function Selector() {
    return <Card style={{ height: '100%', maxHeight: 'calc(100vh - 180px)' }}>
        <Card.Header className='bg-info'><h4><b>SECCIONAR EL TIPO DE PANEL</b></h4></Card.Header>
        <Card.Body>
            <Seled to="/panel/carrusel" src="/static/panels/var/1.jpg" title="CARRUSEL BANNER" />
            <Seled to="/panel/paralax1" src="/static/panels/pax/3.jpg" title="PARALAX A" />
            <Seled to="/panel/paralax2" src="/static/panels/pax/1.jpg" title="PARALAX B" />
            <Seled to="/panel/titulo" src="/static/panels/pax/9.jpg" title="TITULO" />
            <Seled to="/panel/video" src="/static/panels/gal/5.jpg" title="VIDEO" />
            <Seled to="/panel/imagen" src="/static/panels/gal/5.jpg" title="IMAGEN" />
            <Seled to="/panel/galeria" src="/static/panels/gal/1.jpg" title="GALERIA" />
            <Seled to="/panel/cargos" src="/static/panels/var/4.jpg" title="CARRUSEL CARTA" />
            <Seled to="/panel/servicio" src="/static/panels/ser/1.jpg" title="CARTA CON ICONO A" />
            <Seled to="/panel/servicio2" src="/static/panels/ser/5.jpg" title="CARTA CON ICONO B" />
            <Seled to="/panel/preguntas" src="/static/panels/var/5.jpg" title="PREGUNTAS" />
            <Seled to="/panel/pestanas" src="/static/panels/tab/5.jpg" title="PESTAÑAS" />
            <Seled to="/panel/mapa" src="/static/panels/var/mapa.png" title="MAPA" />
            <Seled to="/panel/suscribir" src="/static/panels/var/suscribir.png" title="SUSCRIBIR" />
            <Seled to="/panel/contacto" src="/static/panels/var/contact.png" title="CONTACTO" />
        </Card.Body>
    </Card>
}
export function Seled({ to, src, title }: { to: string, src: string, title: string }) {
    return <Button as={Link} className='m-1' variant='outline-primary' to={to}>
        <img alt='select' width={144} height={96} src={src} /><br />
        <small><b>{title}</b></small>
    </Button>
}
export function SelectorPanel({ set, filter }: { set: (da: Panel) => void, filter?: (p: Panel) => boolean }) {
    const { panels, updatePanel } = useContext(PanelContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(updatePanel, [])
    const p = filter ? panels.filter(filter) : panels;
    return <>{p.map(p => <>
        <Slect panel={p} select={set} />
        <div className='w-100'>
            <PreviewSize key={p.type + p.id} panel={p} size='1080px' />
        </div>
    </>)}</>
}
export function SelecPanelModal({ set, open, close, filter }: { filter?: (p: Panel) => boolean, set: (d: any) => void, open: boolean, close: () => void }) {
    return <Modal size='xl' show={open} onHide={close} centered className=' modal-warning' >
        <Modal.Header style={{ borderRadius: 0 }} closeButton>
            <h4 className="m-0 font-weight-bold mr-auto align-self-center">
                <span><b>SELECCIONAR PANEL</b></span>
            </h4>
        </Modal.Header>
        <Modal.Body >
            <Row style={{ padding: "10px 10px 10px 10px" }}>
                <SelectorPanel set={p => { set(p); close() }} filter={filter} />
            </Row>
        </Modal.Body>
    </Modal>
}