import { useContext, useState, useEffect, memo } from 'react';
import { Button, ButtonGroup, FormControl } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip';
import { ProductosContext, ProductosType } from '../../../../Context/Producto';
import { SeccionContext, SeccionType } from '../../../../Context/Seccion';
import { Detalles } from '../../../../Models/General';
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila';
import { numberFormat } from '../../../../Utiles/Formatos';
import { ControlArea } from '../../../../Utiles/FormControl';
import { InputNumber } from '../../../../Utiles/Inputs';
interface Props {
    data: Detalles
    edit: (d: Detalles) => void
    remove: (iten: Detalles) => void
    dolar: boolean
    open: boolean
}
function ProductosVentas({ data, edit, remove, dolar, open }: Props) {
    const { findProVen, tasa } = useContext(ProductosContext) as ProductosType;
    const { data: { User: { nivel } } } = useContext(SeccionContext) as SeccionType;
    const { codigo, descripcion, costo, cantidad, monto1, monto2, monto3, inventario } = findProVen(data, dolar);
    const [unidades, setUnidades] = useState(data.unidades);
    const [precio, setPrecio] = useState(data.precio);
    const [comentario, setComentario] = useState(data.comentario);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { edit({ ...data, unidades, precio, comentario }) }, [unidades, precio, comentario]);
    const pre = (dolar) ? precio / tasa : precio;
    const valStock = ((unidades > cantidad) && (inventario !== 1));
    const valPrecio = (monto1 > pre);
    const className = valStock ? 'bg-danger' : valPrecio ? 'bg-warning' : undefined;
    const tip = valStock ? 'NO TIENES DISPONIBILIDAD SUFICIENTE' : valPrecio ? 'ESTAS POR DEBAJO DEL PRECIO MINIMO' : undefined;
    return <>
        <tr className={className}>
            <THFila tip={tip} width='90px'>{codigo}</THFila>
            <THFila tip={tip} width='auto'>{descripcion}</THFila>
            <THInput tip={tip} width='120px'><InputNumber min={0.01} setData={setUnidades} value={unidades} /></THInput>
            <THInput tip={tip} width='120px'><FormControl style={{ textAlign: 'right' }} disabled value={(inventario === 1) ? 'NO APLICA' : numberFormat(cantidad)} /></THInput>
            <THInput tip={tip} width='180px'>
                <InputNumber value={pre} min={(nivel === 0) ? costo : monto1} step='0.01'
                    list={costo + 'list'} setData={(p: number) => setPrecio((dolar) ? p * tasa : p)}
                    data-tip={'MINIMO: ' + numberFormat((nivel === 0) ? costo : monto1)} />
                <datalist id={costo + 'list'} key={costo + 'list' + dolar}>
                    <option>{monto1.toFixed(2)}</option>
                    <option>{monto2.toFixed(2)}</option>
                    <option>{monto3.toFixed(2)}</option>
                </datalist>
            </THInput>
            <THInput tip={tip} width='230px'><InputNumber disabled setData={() => { }} value={parseFloat(pre.toFixed(2)) * unidades} update="p" /></THInput>
            <THInput tip={tip} width='110px'>
                <ReactTooltip />
                <ButtonGroup>
                    <Button variant="danger" onClick={() => remove(data)} data-tip="QUITAR PRODUCTO">
                        <i className="fa fa-ban"></i>
                    </Button>
                </ButtonGroup>
            </THInput>
        </tr><tr></tr>
        {open ? <tr className={className}>
            <td colSpan={7} >
                <ControlArea before="Seriales" setData={setComentario} value={comentario} />
            </td>
        </tr> : <tr></tr>}
    </>
}
export default memo(ProductosVentas);