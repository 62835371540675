/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useContext, useCallback } from 'react'
import { Auth } from '../../Config/Permisos';
import { conCompra } from '../../Models/Compra';
import { Estado, EstadoNull, GeneralFila, updateOrPush } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { semaforo } from '../../Utiles/Semaforo';
import { OrdenesContext, OrdenesType } from './Ordenes';
export interface ComprasType extends OrdenesType {
    estCompras: Estado
    Compras: GeneralFila[]
    actCompra: () => void
}
export const ComprasContext = createContext<ComprasType | null>(null)
export default function ComprasProvider({ children }: { children: any }) {
    const [compras, setCompras] = useState<GeneralFila[]>([]);
    const ordenes = useContext(OrdenesContext) as OrdenesType;
    const [est, setest] = useState(EstadoNull);
    const s = semaforo();
    const update = useCallback(() => {
        if (s.isTurn('compras')) {
            if (Auth('COMPRA')) conCompra().cambios((data: any[]) => {
                setest({ status: 3, hora: formatoHora(conCompra().state) });
                setCompras(updateOrPush(data, compras, conCompra()))
            }, s.next)
            else s.next()
        }
    }, [compras])
    useEffect(() => {
        if (conCompra().state !== '') setest({ status: 3, hora: formatoHora(conCompra().state) });
        if (Auth('COMPRA')) conCompra().cargarListaCache(setCompras);
        s.add('compras');
        return () => s.clean('compras');
    }, []);
    useEffect(() => {
        let interval = setInterval(update, 100);
        return () => clearInterval(interval);
    }, [compras]);
    return <ComprasContext.Provider value={{
        ...ordenes,
        estCompras: est,
        Compras: compras,
        actCompra: update
    }}>
        {children}
    </ComprasContext.Provider>
}
export const SeccionConsumer = ComprasContext.Consumer;