/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useCallback } from 'react';
import { conPanel, conShort, Panel, Shor } from '../../Models/Panel';
import { ConConfig } from '../../Models/Config';
import { semaforo } from '../../Utiles/Semaforo';
export interface PanelType {
    colores: string[]
    panels: Panel[];
    shors: Shor[];
    pages: Page[];
    updatePanel: () => void;
    updateShort: () => void;
    page: number
    setPage: (d: number) => void
    setPages: (p: any) => void
}
interface Page {
    title: string;
    visible: boolean;
    id: number;
    ocultar: number[];
}
export const PanelContext = createContext<PanelType>({ page: 1, setPage: () => { }, setPages: () => { }, pages: [], colores: [], panels: [], shors: [], updatePanel: () => { }, updateShort: () => { } })
export default function PanelProvider({ children }: { children: any }) {
    const s = semaforo();
    const [colores, setColores] = useState<string[]>([]);
    const [pages, setPages] = useState<Page[]>([]);
    const [panels, setPanels] = useState<Panel[]>([]);
    const [shors, setShorts] = useState<Shor[]>([]);
    const [page, setPage] = useState(1);
    const updatePanel = useCallback((next?: () => void) => {
        conPanel().cargarListaWeb(p => {
            if (JSON.stringify(p) !== JSON.stringify(panels)) setPanels(p);
            if (next) next();
        })
        ConConfig('page').pull(d => {
            if (d !== null && JSON.stringify(d) !== '[]') {
                if (JSON.stringify(pages) !== JSON.stringify(d.paginas)) setPages(d.paginas);
                console.log(JSON.stringify(pages) !== JSON.stringify(d.paginas))
            } else setPages([{ title: 'Inicio', visible: true, id: 1, ocultar: [] }]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPanels, setPages, pages, panels]);
    const updateShort = useCallback((next?: () => void) =>
        conShort().cargarListaWeb(s => {
            if (JSON.stringify(s) !== JSON.stringify(shors)) setShorts(s);
            if (next) next();
        }), [setShorts, shors]);
    const update = () => {
        if (s.isTurn('Panel')) updatePanel(() => s.next());
        if (s.isTurn('Shor')) updateShort(() => s.next());
    }
    let interval: any;
    useEffect(() => {
        interval = setInterval(update, 100);
        return () => clearInterval(interval);
    }, [shors, panels])
    useEffect(() => {
        ConConfig('colores').pull(({ colores }) => setColores(colores));
        s.add('Panel'); s.add('Shor');
        return () => { s.clean('Panel'); s.clean('Shor'); }
    }, []);
    return <PanelContext.Provider value={{ setPages, page, setPage, pages, colores, panels, updatePanel, shors, updateShort }}>{children}</PanelContext.Provider>
}
export const SeccionConsumer = PanelContext.Consumer;