import { Conexion } from "../../Config/Coneccion"
export interface Panel {
    id: number
    index: number
    pagina: number
    title: string
    type: string
    data: any
    text: string
    estado: number
    items: Items[]
}
export interface Items {
    id: number
    panel_id: number
    index: number
    type: string
    data: any
    text: string
}
export const panelNull: Panel = {
    id: 0,
    index: 0,
    pagina: 1,
    type: '',
    title: '',
    data: {},
    text: '',
    estado: 0,
    items: []
}
export const itemNull: Items = {
    id: 0,
    panel_id: 0,
    index: 0,
    type: '',
    data: {},
    text: ''
}
export interface PanelProps {
    data: any
    text: string
    setText: (s: string) => void,
    removeItems?: (n: number) => void,
    items?: Items[]
    Items: JSX.Element
}
class PanelConeccion extends Conexion {
    clase = 'Panel';
    persistencia = 1000;
    page = (id: number, page: any, setData: () => void, setError: () => void) =>
        this.forceGetWeb(`/api/Panel/page/${id}/${page}`, setData, setError)
    up = (indice: number, setData: () => void, setError: () => void) =>
        this.forceGetWeb(`/api/Panel/up/${indice}`, setData, setError)
    down = (indice: number, setData: () => void, setError: () => void) =>
        this.forceGetWeb(`/api/Panel/down/${indice}`, setData, setError)
    delete = (id: number, setData: () => void, setError: () => void) =>
        this.forceGetWeb(`/api/Panel/delete/${id}`, setData, setError)
}
var conPl: PanelConeccion;
export function conPanel() {
    if (conPl === undefined) conPl = new PanelConeccion();
    return conPl;
}
class ShortConeccion extends Conexion {
    clase = 'Panel/short';
    persistencia = 1000;
    delete = (id: number, setData: () => void, setError: () => void) => {
        this.forceGetWeb(`/api/Panel/short/delete/${id}`, setData, setError)
    }
}
var conSh: ShortConeccion;
export function conShort() {
    if (conSh === undefined) conSh = new ShortConeccion();
    return conSh;
}
export interface Shor {
    id: number
    type: string
    descripcion: string
    html: string
    clave: string
    estatus: number
    data: any
}
export interface Sub {
    id: number
    nombre: string
    correo: string
}
export interface Contact {
    id: number
    nombre: string
    correo: string
    asunto: string
    telefono: string
    mensaje: string
}

export function arrayToSub(f: any[]): Sub[] {
    return f.map(([a, b, c]) => { return { id: a, nombre: b, correo: c } });
}
export function arrayToContact(f: any[]): Contact[] {
    return f.map(([a, b, c, e, f, g]) => { return { id: a, nombre: b, correo: c, asunto: e, telefono: f, mensaje: g } });
}
class SubConeccion extends Conexion {
    clase = 'Contacto/suscribirse';
    persistencia = 1000;
    delete = (id: number, setData: () => void, setError: () => void) => {
        this.forceGetWeb(`/api/Contacto/suscribirse/delete/${id}`, setData, setError)
    }
}
var conS: SubConeccion;
export function conSub() {
    if (conS === undefined) conS = new SubConeccion();
    return conS;
}
class ContactConeccion extends Conexion {
    clase = 'Contacto/me';
    persistencia = 1000;
    delete = (id: number, setData: () => void, setError: () => void) => {
        this.forceGetWeb(`/api/Contacto//delete/${id}`, setData, setError)
    }
}
var conC: ContactConeccion;
export function conContac() {
    if (conC === undefined) conC = new ContactConeccion();
    return conC;
}