import React, { useContext, useState } from 'react'
import { Button, FormControl } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { ProductosContext, ProductosType } from '../../../../Context/Producto'
import { SeccionContext, SeccionType } from '../../../../Context/Seccion'
import { Detalles } from '../../../../Models/General'
import { Producto } from '../../../../Models/Productos'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'
import { numberFormat } from '../../../../Utiles/Formatos'
import { InputNumber } from '../../../../Utiles/Inputs'

interface Props {
    producto: Producto,
    add: (iten: Detalles) => void
    dolar?: boolean
    venta: boolean
}
export default function FilaVentas({ producto, add, dolar = false, venta }: Props) {
    const { findProVen, tasa } = useContext(ProductosContext) as ProductosType;
    const { data: { User: { nivel } } } = useContext(SeccionContext) as SeccionType;
    const { codigo, descripcion, costo, monto1, monto2, monto3, inventario, cantidad } = findProVen(producto, dolar);
    const [unidades, setUnidades] = useState(1);
    const [precio, setPrecio] = useState(parseFloat(((dolar) ? monto2 * tasa : monto2).toFixed(2)));
    const pre = (dolar) ? precio / tasa : precio;
    const GetB = () => {
        if (venta && inventario !== 1)
            if (cantidad < 0.01) return <></>
        return <Button data-tip="AGREGAR PRODUCTO" variant="primary"
            onClick={() => add({ ...producto, precio, unidades, comentario: '' })}>
            <i className="fa fa-plus" />
        </Button>
    }
    return <tr>
        <THFila width='90px'>{codigo}</THFila>
        <THFila width='auto'>{descripcion}</THFila>
        <THInput width='120px' tip={(inventario === 1) ? 'NO APLICA' : 'DISPONIBLE: ' + numberFormat(cantidad)}>
            <InputNumber min={.01} step='0.01' setData={setUnidades} value={unidades}
                max={(venta && inventario !== 1) ? cantidad : undefined} />
        </THInput>
        <THInput width='180px' tip={'MINIMO: ' + numberFormat((nivel === 0) ? costo : monto1)}>
            <InputNumber list={costo + 'list'} setData={(p: number) => setPrecio((dolar) ? p * tasa : p)}
                value={pre} min={(nivel === 0) ? costo : monto1} step='0.01' />
            <datalist id={costo + 'list'}>
                <option>{monto1.toFixed(2)}</option>
                <option>{monto2.toFixed(2)}</option>
                <option>{monto3.toFixed(2)}</option>
            </datalist>
            <ReactTooltip />
        </THInput>
        <THInput width='230px'><FormControl style={{ textAlign: 'right' }} disabled value={numberFormat((parseFloat(pre.toFixed(2)) * unidades))} /> </THInput>
        <THInput width='70px'>
            <GetB />
        </THInput>
    </tr>
}