import { Invert } from '../../Component/Commons';
import { FormControl, FormGroup, Button } from 'react-bootstrap';
import { State } from './index'
import { getCSS } from './props';
export function Panel({ config, width }: { config: State, width: number }) {
    const {  backgroundColor, input, inputText, buttonText, button } = config;
    return <div style={{ padding: '60px 0', backgroundColor }}>
        <div className="container">
            <Invert width={width}  config={config}>
                <FormGroup className={getCSS(input, inputText, button, buttonText)}>
                    <FormControl style={{ gridArea: 'a' }} placeholder="Ingresa su nombre" />
                    <FormControl style={{ gridArea: 'b' }} placeholder="Ingresa su correo" />
                    <FormControl style={{ gridArea: 'c' }} placeholder="Ingresa el asunto" />
                    <FormControl style={{ gridArea: 'd' }} placeholder="Ingresa su telefono" />
                    <FormControl style={{ gridArea: 'e' }} as='textarea' placeholder="Por favor, escriba su mensaje aqui" />
                    <Button style={{ gridArea: 'f' }} block>Enviar</Button>
                </FormGroup>
            </Invert>
        </div>
    </div>
}