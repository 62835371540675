/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useCallback } from 'react'
import { AuthSome } from '../../Config/Permisos';
import { conProveedor, Proveedor, ProveedorNull } from '../../Models/Compra';
import { Estado, EstadoNull } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { semaforo } from '../../Utiles/Semaforo';
export interface ProveedoresType {
    estProveedores: Estado
    Proveedores: Proveedor[]
    actProveedor: () => void
    findProveedor: (p: string) => Proveedor
}
function arrayToProveedor(p: any[]): Proveedor[] {
    return p.map(a => {
        return {
            codigo: a[0],
            nombre: a[1],
            telefono: a[2],
            correo: a[3],
            contacto: a[4],
            direccion: a[5],
            estatus: a[6],
            pais: a[7],
            ciudad: a[8],
            estado: a[9]
        }
    })
}
function setCambioProveedores(data: Proveedor[], p: Proveedor[]) {
    const push = arrayToProveedor(data).filter((d => {
        const i = p.findIndex(p => p.codigo === d.codigo);
        if (i > -1) {
            p[i] = d;
            return false;
        }
        return true;
    }));
    const Proveedores = [...p, ...push];
    conProveedor().saveLista(Proveedores);
    return Proveedores;
}
export const ProveedoresContext = createContext<ProveedoresType | null>(null)
export default function ProveedoresProvider({ children }: { children: any }) {
    const [Proveedores, setProveedores] = useState<Proveedor[]>([]);
    const [est, setest] = useState(EstadoNull);
    const s = semaforo();
    const update = useCallback(() => {
        if (s.isTurn('Proveedores')) {
            if (AuthSome(['COMPRA', 'ORDEN', 'PROVEEDOR'])) conProveedor().cambios((data: any[]) => {
                let pro = setCambioProveedores(data, Proveedores);
                setProveedores(pro);
                setest({ status: 3, hora: formatoHora(conProveedor().state) });
            }, s.next)
            else s.next()
        }
    }, [Proveedores])
    useEffect(() => {
        if (conProveedor().state !== '') setest({ status: 3, hora: formatoHora(conProveedor().state) });
        if (AuthSome(['COMPRA', 'ORDEN', 'PROVEEDOR'])) conProveedor().cargarListaCache(setProveedores);
        s.add('Proveedores');
        return () => s.clean('Proveedores');
    }, []);
    useEffect(() => {
        let interval = setInterval(update, 100)
        return () => clearInterval(interval)
    }, [Proveedores]);
    const findProveedor = useCallback((p: string) => {
        const pro = Proveedores.find(Pro => Pro.codigo === p);
        if (pro !== undefined) return pro;
        return ProveedorNull;
    }, [Proveedores])
    return <ProveedoresContext.Provider value={{
        estProveedores: est,
        Proveedores: Proveedores,
        actProveedor: update,
        findProveedor: findProveedor
    }}>
        {children}
    </ProveedoresContext.Provider>
}
export const SeccionConsumer = ProveedoresContext.Consumer;