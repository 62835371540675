import { Conexion } from "../../../Config/Coneccion";
import { CondicionesData, condicionesNull, getSubtotal, sentDetalles } from "../../General";

export interface Orden extends CondicionesData {
    cod_proveedor: string
}
export function sentOrden(e: Orden, iva: number) {
    let subtotal = getSubtotal(e.detalles),
        impuesto = subtotal * (iva / 100),
        total = subtotal + impuesto;
    let { cod_proveedor, validez, tiempo_entrega, forma_pago, nota } = e;
    return {
        cod_proveedor,
        validez,
        tiempo_entrega,
        forma_pago,
        nota,
        total,
        impuesto,
        subtotal,
        detalles: sentDetalles(e.detalles)
    }
}
export var ordenNull: Orden = {
    ...condicionesNull,
    cod_proveedor: '',
};

class OrdenConexion extends Conexion {
    clase = 'Orden';
    persistencia = 1000;
}
var conOr: OrdenConexion;
export function conOrden() {
    if (conOr === undefined) conOr = new OrdenConexion();
    return conOr;
}