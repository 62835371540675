import { CSSProperties } from 'react';
import { css } from '@emotion/css';
import { ToRGB } from '../../../../../Utiles/Formatos';
export const imagen: CSSProperties = { display: 'block', width: '100%', height: '100%', };

export function content(
    color: string, font: string, fill: string, back: string, border: boolean, iback: string) {
    const { r, g, b, a } = ToRGB(color);
    const { r: r2, g: g2, b: b2, a: a2 } = ToRGB(font);
    const { r: fr, g: fg, b: fb, a: fa } = ToRGB(fill);
    const { r: ir, g: ig, b: ib, a: ia } = ToRGB(iback);
    return css`
        margin: 0 0 0 0 !important;
        background-color: rgba(${r}, ${g}, ${b}, ${a});
        color: rgba(${r2}, ${g2}, ${b2}, ${a2});
        &>.card-header{
            &>.row>.icon-content {
                width: 3rem; height: 3rem; padding-top:${border ? '.2rem' : '.5rem'};
                ${back === 'circle' ? 'border-radius: 3rem;' : back === 'rounded' ? 'border-radius: 1rem;' : ''}
                ${border && back !== 'none' ? `border: .35rem solid rgba(${fr}, ${fg}, ${fb}, ${fa});` : ''}
                ${back !== 'none' ? `background-color: rgba(${ir}, ${ig}, ${ib}, ${ia});` : ''}
                &>svg>path { fill: rgba(${fr}, ${fg}, ${fb}, ${fa}); }
            }
            &:hover {
                background-color: rgba(${r - 10}, ${g - 10}, ${b - 10}, ${a});
                &>.row{
                    color: rgba(${r2 + 10}, ${g2 + 10}, ${b2 + 10}, ${a2}) !important;
                    &>.icon-content {
                        ${border && back !== 'none' ? `border: .35rem solid rgba(${fr + 10}, ${fg + 10}, ${fb + 10}, ${fa});` : ''}
                        ${back !== 'none' ? `background-color: rgba(${ir - 10}, ${ig - 10}, ${ib - 10}, ${ia});` : ''}
                        &>svg>path {fill: rgba(${fr + 10}, ${fg + 10}, ${fb + 10}, ${fa});}
                    }
                }
            }
            &:active {
                background-color: rgba(${r + 10}, ${g + 10}, ${b + 10}, ${a});
                &>.row{
                    color: rgba(${r2 - 10}, ${g2 - 10}, ${b2 - 10}, ${a2});
                    &>.icon-content {
                        ${border && back !== 'none' ? `border: .35rem solid rgba(${fr - 10}, ${fg - 10}, ${fb - 10}, ${fa});` : ''}
                        ${back !== 'none' ? `background-color: rgba(${ir + 10}, ${ig + 10}, ${ib + 10}, ${ia});` : ''}
                        &>svg>path {fill: rgba(${fr - 10}, ${fg - 10}, ${fb - 10}, ${fa});}
                    }
                }
            }
            
        }
        `;
}