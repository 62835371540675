import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { ConConfig } from '../../../Models/Config';
import Formulario from './Formulario';
import { Impreciones, imprecionNull } from '../../../Models/General';
export default class Reporte extends React.Component<any, Impreciones> {
    con = ConConfig('reporte');
    state = imprecionNull;
    render() {
        let setState = this.setState.bind(this);
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE REPORTES </h4>
            </Card.Header>
            <Card.Body>
                <Formulario version='reporte' data={this.state} setData={setState} />
                <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> Guardar
                </Button>
            </Card.Body>
        </Card>) 
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}