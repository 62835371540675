import React from 'react';
import { Button, Col, Card, InputGroup, Row } from 'react-bootstrap';
import { ConConfig } from '../../Models/Config';
import InputTime from '../../Utiles/Inputs/InputTime';
interface State {
    lunes: [string, string];
    martes: [string, string];
    miercoles: [string, string];
    jueves: [string, string];
    viernes: [string, string];
    sabado: [string, string];
    domingo: [string, string];
}
export default class Horarios extends React.Component<any, State> {
    con = ConConfig('horario');
    constructor(p: any) {
        super(p);
        this.state = {
            lunes: ['', ''],
            martes: ['', ''],
            miercoles: ['', ''],
            viernes: ['', ''],
            jueves: ['', ''],
            sabado: ['', ''],
            domingo: ['', '']
        }
    }
    setData = this.setState.bind(this);
    render() {
        const { lunes: [l1, l2], martes: [m1, m2], miercoles: [m3, m4], viernes: [v1, v2], jueves: [j1, j2], sabado: [s1, s2], domingo: [d1, d2] } = this.state;
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE HORARIO </h4>
            </Card.Header>
            <Card.Body>
                LIMITAR LOS HORARIOS DE TRABAJO EN EL SISTEMA
                <Row>
                    <Col md={6}><br />
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>LUNES</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={l1} setData={l1 => this.setData({ lunes: [l1, l2] })} />
                            <InputGroup.Prepend><InputGroup.Text>A</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={l2} setData={l2 => this.setData({ lunes: [l1, l2] })} />
                        </InputGroup>
                    </Col>
                    <Col md={6}><br />
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>MARTES</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={m1} setData={m1 => this.setData({ martes: [m1, m2] })} />
                            <InputGroup.Prepend><InputGroup.Text>A</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={m2} setData={m2 => this.setData({ martes: [m1, m2] })} />
                        </InputGroup>
                    </Col>
                    <Col md={6}><br />
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>MIERCOLES</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={m3} setData={m3 => this.setData({ miercoles: [m3, m4] })} />
                            <InputGroup.Prepend><InputGroup.Text>A</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={m4} setData={m4 => this.setData({ miercoles: [m3, m4] })} />
                        </InputGroup>
                    </Col>
                    <Col md={6}><br />
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>JUEVES</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={j1} setData={j1 => this.setData({ jueves: [j1, j2] })} />
                            <InputGroup.Prepend><InputGroup.Text>A</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={j2} setData={j2 => this.setData({ jueves: [j1, j2] })} />
                        </InputGroup>
                    </Col>
                    <Col md={6}><br />
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>VIERNES</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={v1} setData={v1 => this.setData({ viernes: [v1, v2] })} />
                            <InputGroup.Prepend><InputGroup.Text>A</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={v2} setData={v2 => this.setData({ viernes: [v1, v2] })} />
                        </InputGroup>
                    </Col>
                    <Col md={6}><br />
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>SABADO</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={s1} setData={s1 => this.setData({ sabado: [s1, s2] })} />
                            <InputGroup.Prepend><InputGroup.Text>A</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={s2} setData={s2 => this.setData({ sabado: [s1, s2] })} />
                        </InputGroup>
                    </Col>
                    <Col md={6}><br />
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>DOMINGO</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={d1} setData={d1 => this.setData({ domingo: [d1, d2] })} />
                            <InputGroup.Prepend><InputGroup.Text>A</InputGroup.Text></InputGroup.Prepend>
                            <InputTime value={d2} setData={d2 => this.setData({ domingo: [d1, d2] })} />
                        </InputGroup>
                    </Col>
                    <Col md={6}><br />
                        <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                            <span className="fa fa-floppy-o" /> Guardar
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>)
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}