import React from 'react';
import { Button, InputGroup, Card } from 'react-bootstrap';
import { Producto, ProductoNull } from '../../../Models/Productos';
import ModalReport from '../../../Utiles/Componentes/ModalReport';
import BuscarProducto from '../../Inventario/Productos/BuscarProducto';
import Fechas from '../Fechas';
interface State {
    date1: string
    date2: string
    aux: string
    producto: Producto
    modal: boolean
    modalProductos: boolean
}

let date = new Date();
export default class HistoricoCompra extends React.Component<any, State> {
    state = { producto: ProductoNull, aux: "mes", date1: date.getFullYear() + '', date2: (date.getMonth() + 1) + '', modal: false, modalProductos: false }
    render() {
        let { aux, date2, date1, modal, producto, modalProductos } = this.state;
        return (<Card>
            <ModalReport title={"COMPRAS DE UN PRODUCTO"} open={modal}
                url={"/api/Compra/de/" + producto.codigo + '/' + aux + '/' + date1 + '/' + date2}
                close={() => { this.setState({ modal: !modal }) }} />
            <Card.Header className='bg-info'>
                <h3>COMPRAS DE UN PRODUCTO</h3>
            </Card.Header>
            <Card.Body>
                <Card>
                    <Card.Header className='bg-info'>
                        <h6>SELECCIONAR PRODUCTO</h6>
                    </Card.Header>
                    <Card.Body>
                        <BuscarProducto type='Seleccion' open={modalProductos} add={this.addProducto.bind(this)} close={() => { this.setState({ modalProductos: false }) }} />
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <span style={{ width: '140px', position: 'relative', backgroundColor: "#eee" }} className="form-control">
                                {producto.codigo}
                            </span>
                            <span style={{ width: 'calc(100% - 243px)', position: 'relative', backgroundColor: "#eee" }} className="form-control">
                                {producto.descripcion}
                            </span>
                            <Button style={{ position: 'relative', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onClick={() => { this.setState({ modalProductos: true }) }} variant="primary">
                                <span className="glyphicon glyphicon-search"></span> BUSCAR
                            </Button>
                        </InputGroup>
                    </Card.Body>
                </Card>
                <Fechas aux={aux} date2={date2} date1={date1} setDate={this.setState.bind(this)} />
                <Button className="float-right" onClick={() => { this.setState({ modal: !modal }) }} variant='primary'>
                    <span className="glyphicon glyphicon-print"></span> IMPRIMIR
                </Button>
            </Card.Body>
        </Card>)
    }
    addProducto(p: Producto) {
        this.setState({ producto: p, modalProductos: false });
    }
}