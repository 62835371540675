import React from 'react'
import { Button, Modal, Row, Col, FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { SeccionContext } from '../../Context/Seccion'
import { UsuarioFila, conUsuario } from '../../Models/Usuario'
import { ControlText } from '../../Utiles/FormControl'
import RolSelector from '../Config/Rol/Selector'
interface Props {
    open: boolean
    user: UsuarioFila | null
    title: string
    close: () => void
    add?: (c: UsuarioFila) => void
}
class FormUsuario extends React.Component<Props, UsuarioFila> {
    con = conUsuario();
    error: any = [];
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this);
        if (p.user !== null) {
            this.state = p.user
        } else {
            this.state = {
                clave: '',
                codigo: 0,
                nombre: '',
                correo: '',
                rol: '',
                nivel: -1,
                estado: 1
            };
        }
    }
    render() {
        let { setState, state: { clave, nombre, correo, nivel }, props: { open, close, title } } = this
        return <Modal animation show={open} onHide={close}>
            <Modal.Header closeButton>
                <h4 className='mr-auto'>{title}</h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={this.error} before="NOMBRE" maxLength={150}
                            value={nombre} setData={setState} update="nombre" />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={this.error} before="CORREO" maxLength={150}
                            value={correo} setData={setState} update="correo" />
                    </Col>
                    {(this.props.user === null) ? <Col as={FormGroup} xs={12}>
                        <ControlText error={this.error} maxLength={120} type='password'
                            before="CLAVE" value={clave ? clave : ''} setData={setState} update="clave" />
                    </Col> : <></>}
                    <Col as={InputGroup} hasValidation={this.error['nivel'] ? true : false} xs={12}>
                        <RolSelector isInvalid={this.error['nivel'] ? true : false} defaultValue={nivel}
                            change={(nivel) => { setState({ nivel }) }} />
                        <FormControl.Feedback type="invalid">
                            {this.error['nivel'] ? this.error['nivel'] : ''}
                        </FormControl.Feedback>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.get.bind(this)} variant="primary">
                    <span className="fa fa-floppy-disk" /> Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    }
    get() {
        this.error = [];
        if (this.state.nombre === "") {
            this.error['nombre'] = 'DEBE COLOCAR EL RASON SOCIAL';
        }
        if (this.state.correo === "") {
            this.error['correo'] = 'DEBE COLOCAR UN CORREO';
        }
        if (this.state.nivel === -1) {
            this.error['nivel'] = 'DEBE SELECCINAR UN ROL PARA EL USUARIO';
        }
        if (this.props.user === null) {
            if (this.state.clave === "") {
                this.error['clave'] = 'DEBE COLOCAR UNA CLAVE';
            }
            if (Object.keys(this.error).length === 0) {
                this.con.nuevo(this.state, data => {
                    if (this.props.add) this.props.add(data);
                    toast('GUARDADO EXITOSAMENTE', { type: toast.TYPE.SUCCESS });
                    this.context.atcUsuario();
                    this.props.close();
                }, this.setError.bind(this));
            }
        } else if (Object.keys(this.error).length === 0) {
            this.con.actualizar(this.props.user.codigo,
                this.state, data => {
                    if (this.props.add) this.props.add(data);
                    this.context.atcUsuario();
                    toast('GUARDADO EXITOSAMENTE', { type: toast.TYPE.SUCCESS });
                    this.props.close();
                }, this.setError.bind(this));
        }
        this.forceUpdate();
    }

    setError(err: any) {
        if (err.response) {
            let { errores } = err.response;
            errores.forEach((err: any) => {
                if (err.sql) {
                    err.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
                } else {
                    Object.keys(err).forEach(t => this.error[t] = err[t])
                }
            })
            console.log(this.error);
            this.forceUpdate();
        }
    }
}
FormUsuario.contextType = SeccionContext;
export default FormUsuario;