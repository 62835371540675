import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { NotasContext } from "../../../../Context/Ventas/Notas";
import { conNota, Nota, sentNota } from "../../../../Models/Ventas";
import { generalNull } from "../../../../Models/General";
import CreateOperacion from "../../../../Utiles/Componentes/CreateOperacion";
import { InputArea } from "../../../../Utiles/Inputs";
import ClienteSelector from "../../Clientes/Selector";
import PrintComponent from "../PrintCompinent";
import AuxiliarNotas from "./AuxiliarNotas";
class NuevaNota extends CreateOperacion {
    title = 'NOTA DE ENTREGA';
    msn = 'LA NOTA DE ENTREGA';
    con = conNota();
    constructor(p: any) {
        super(p);
        this.tipo = 'Nota';
        this.state = { ...generalNull, cod_cliente: '', procesar: false };
    }
    setCliente(p: string) { this.setState({ cod_cliente: p }); }
    Top() {
        const { cod_cliente, nota } = this.getData() as Nota;
        return <>
            <ClienteSelector Cliente={cod_cliente} add={this.setCliente.bind(this)} />
            <Accordion>
                <Accordion.Toggle eventKey='0' as={Button} variant='primary'>NOTA</Accordion.Toggle>
                <Accordion.Collapse eventKey='0'><Card>
                    <Card.Body>
                        <InputArea rows={4} maxLength={600} value={nota} setData={this.setState} update="nota" />
                    </Card.Body>
                </Card>
                </Accordion.Collapse>
            </Accordion>
        </>
    }

    PDF() {
        return <PrintComponent codigo={this.state.codigo + ''} />
    }
    Productos() {
        return <AuxiliarNotas key="auxFactura" setData={this.setState} data={this.state} />
    }
    Procesar() {
        let procesar = true;
        if (this.state.cod_cliente === "") {
            toast('DEBE SELECCIONAR UN CLIENTE', { type: toast.TYPE.ERROR });
            procesar = false;
        }
        procesar = this.validacionGenerica(procesar);
        procesar = this.validacionExixtencia(procesar);
        procesar = this.valdacionPrecio(procesar);
        if (procesar)
            this.con.nuevo(sentNota(this.getData(), this.context.impuesto),
                data => this.setState({ codigo: data }),
                data => this.procesar = true);
        else this.procesar = false
    }
}
NuevaNota.contextType = NotasContext;
export default NuevaNota;