import React from 'react';
import { Panel } from './Panel';
import { Config } from './Config';
import { animatedList } from './props';
import { GalIten, StateS, Iten, defaultIten } from '../../ProtoPanes/GalIten';
import { getText } from '../../ProtoPanes/DefaultData';
export interface I {
    img: string;
    borde: number;
    bordeColor: string;
    redondius: number;
    position: number
    disable: boolean
    color: string;
}
export interface S {
    animated: animatedList;
}
export const aux: I = {
    img: '/public/panels/default.jpg',
    color: '#ffffff',
    borde: 5,
    bordeColor: '#ffffff',
    redondius: 20,
    position: 4,
    disable: false
}
export type State = StateS<I> & S;
export type iten = Iten & I;
export const dIten: Iten & I = { ...defaultIten, ...aux }
export class Carrusel extends GalIten<S, I> {
    constructor(p: any) {
        super(p);
        this.initGal(p, { animated: 'translateX' }, [
            { ...dIten, id: 1 },
            { ...dIten, id: 2 },
            { ...dIten, id: 3 }
        ]);
    }
    Config = () => <Config
        save={this.save}
        key='config'
        iten={this.getIten(aux)}
        setIten={this.setIten}
        setConfig={this.setData}
        title={this.state.title}
        setTitle={this.setTitle}
        removeItem={this.removeItem}
        itenUp={this.itenUp}
        itenDown={this.itenDown}
        addIten={() => this.addIten(aux)}
        config={this.state} />
    Panel = () => <Panel key='panel' config={this.state} seled={this.seleted} />
    saveItens = () => {
        return this.state.itens.map(({ img, borde, bordeColor, redondius, position, disable, color, id, html, back }: any) => {
            const data = JSON.stringify({});
            const props = JSON.stringify({ img, borde, bordeColor, redondius, position, disable, color, id, back });
            return { data, props, html: JSON.stringify(getText(html)), id };
        })
    }
    getDataSave = () => {
        const { title, animated } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ animated });
        return { title, html: '', data, props, itens: this.saveItens(), type: 'carrusel' };
    }
}