import React from 'react';
import { Detalles } from '../../../../Models/General';
import { Departamento, Producto } from '../../../../Models/Productos';
import FilaPrecio from './FilaPrecio';
import FilaUtilidad from './FilaUtilidad';
import ModalBusqueda from '../../../../Utiles/Buscadores/Modal';
import { UTDContext } from '../../../../Context/Producto/UTD'; 
interface Props {
    type: 'COSTO' | 'UTILIDAD';
    add: (p: Detalles[]) => void
}
class CategoriaBuscar extends ModalBusqueda<Props> {
    variant = 'bg-warning';
    buscarEn = ['codigo', 'descripcion'];
    Title() { return <h4 className='mr-auto'>BUSCAR DEPARTAMENTO</h4> }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='90px'>COD</Th>
            <Th label='descripcion' width='auto'>DESCRIPCION</Th>
            {this.head()}
            <Th width='70px'> </Th>
        </tr>
    }
    Row(c: Departamento) {
        switch (this.props.type) { 
            case 'COSTO':
                return <FilaPrecio key={c.codigo + 'COSTO'} departamento={c} add={this.addPrecio.bind(this)} />;
            case 'UTILIDAD':
                return <FilaUtilidad key={c.codigo + 'UTILIDAD'} departamento={c} add={this.addUtilidad.bind(this)} />;
        }
    }
    filter() {
        let itens = this.ordenar();
        return itens.filter((c: Producto) => {
            if (c.estatus === 0) return false;
            return true;
        });
    }
    head() {
        const { Th } = this;
        switch (this.props.type) {
            case 'COSTO':
                return <>
                    <Th width='150px'>OPERACION</Th>
                    <Th width='180px'>COSTO</Th>
                </>;
            case 'UTILIDAD':
                return <>
                    <Th width='150px'>% UTILIDAD 1</Th>
                    <Th width='150px'>% UTILIDAD 2</Th>
                    <Th width='150px'>% UTILIDAD 3</Th>
                </>;
        }
    }
    addPrecio({ codigo, precio, operacion }: { codigo: string, precio: number, operacion: string }) {
        let d: Detalles[] = [];
        this.props.close();
        this.context.Productos.forEach((c: Producto) => {
            if (codigo === 'AAAA' || c.departamento === codigo) {
                let costo = c.costo;
                switch (operacion) {
                    case 'mas': costo += precio; break;
                    case 'menos': costo -= precio; break;
                    case 'por': costo *= precio; break;
                    case 'entre': costo /= precio; break;
                }
                let k: Detalles = { ...c, precio: costo, unidades: 1, comentario: '' };
                d.push(k);
            }
        });
        this.props.add(d);
    }
    addUtilidad({ codigo, precio1, precio2, precio3 }: { codigo: string, precio1: number, precio2: number, precio3: number }) {
        let d: Detalles[] = [];
        this.props.close();
        this.context.Productos.forEach((c: Producto) => {
            if (codigo === 'AAAA' || c.departamento === codigo) {
                let k: Detalles = { ...c, precio: c.costo, unidades: 1, comentario: '' };
                k.precio1 = precio1;
                k.precio2 = precio2;
                k.precio3 = precio3;
                d.push(k);
            };
        });
        this.props.add(d);
    }
    getItens() { return [{ codigo: 'AAAA', descripcion: 'TODOS LO PRODUCTOS EN EL INVENTARIO', estatus: 1 }, ...this.context.Departamentos]; }
    set() { this.context.actDepartamento(); }
    getEstado() { return this.context.estDepartamentos; }
}
CategoriaBuscar.contextType = UTDContext;
export default CategoriaBuscar;