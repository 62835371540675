import { Conexion } from "../Config/Coneccion";
import { Detalles, GeneralData, GeneralFila, generalNull } from "./General";

export default interface Ajuste extends GeneralData {
    status: 0 | 1,
    tipo: "" | 'SALIDA' | 'ENTRADA' | 'COSTO' | 'UTILIDAD'
    detalles: DetallesAjuste[]
}
export interface Costo {
    costo: number
    tasa: number
}
interface UtilidadDetalle {
    precio1: number
    precio2: number
    precio3: number
}
export interface Utilidad {
    previo: UtilidadDetalle
    actual: UtilidadDetalle
}
export interface DetallesAjuste extends Detalles {
    data: Costo | Utilidad
}
export interface AjusteFila extends GeneralFila {
    tipo: string
}

export const AjusteNull: Ajuste = {
    ...generalNull,
    tipo: '',
    status: 0,
    detalles: [],
}
class AjusteConexion extends Conexion {
    commadUpdate() {
        this.updater(this.clase)
        this.updater('Producto')
    }
    clase = 'Ajuste';
    persistencia = 1000;
}

function detallesSend({ unidades, precio, precio1, precio2, precio3, codigo, comentario }: Detalles, tipo: "" | 'SALIDA' | 'ENTRADA' | 'COSTO' | 'UTILIDAD') {
    switch (tipo) {
        case 'COSTO':
            return { codigo, precio, comentario };
        case 'UTILIDAD':
            return { codigo, precio1, precio2, precio3, comentario };
        default:
            return { codigo, unidades, comentario };
    }
}
export function ajusteSend({ detalles, nota, tipo }: Ajuste) {
    return { nota, tipo, detalles: detalles.map(d => { return detallesSend(d, tipo) }) }
}
var conAj: AjusteConexion;
export function conAjuste() {
    if (conAj === undefined) conAj = new AjusteConexion();
    return conAj;
}