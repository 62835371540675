/* eslint-disable react-hooks/exhaustive-deps */
import  { useState, createContext, useEffect, useCallback } from 'react'
import { AuthSome } from '../../Config/Permisos';
import { Estado, EstadoNull } from '../../Models/General';
import { Cliente, conCliente, ClienteNull } from '../../Models/Ventas';
import { formatoHora } from '../../Utiles/Formatos';
import { semaforo } from '../../Utiles/Semaforo';

export interface ClientesType {
    estCliente: Estado
    Clientes: Cliente[]
    actCliente: () => void
    findCliente: (c: string) => Cliente
}
function arrayToClientes(c: any[]): Cliente[] {
    return c.map(a => {
        return {
            codigo: a[0],
            nombre: a[1],
            telefono: a[2],
            correo: a[3],
            contacto: a[4],
            direccion: a[5],
            tipo_contribuyente: a[6],
            retencion: a[7],
            estatus: a[8],
            pais: a[9],
            ciudad: a[10],
            estado: a[11]
        }
    })
}
function setCambioClientes(data: Cliente[], c: Cliente[]) {
    const push = arrayToClientes(data).filter(((d: Cliente) => {
        const i = c.findIndex(c => c.codigo === d.codigo);
        if (i > -1) {
            c[i] = d;
            return false;
        }
        return true;
    }));
    const Clientes = [...c, ...push];
    conCliente().saveLista(Clientes);
    return Clientes;
}
export const ClientesContext = createContext<ClientesType | null>(null)
export default function ClientesProvider({ children }: { children: any }) {
    const [Clientes, setClientes] = useState<Cliente[]>([]);
    const [est, setest] = useState(EstadoNull);
    const update = useCallback(() => {
        const s = semaforo();
        if (s.isTurn('Clientes')) {
            if (AuthSome(['FACTURA', 'NOTA', 'COTIZACION', 'CLIENTE'])) conCliente().cambios((data: any[]) => {
                setClientes(setCambioClientes(data, Clientes))
                setest({ status: 3, hora: formatoHora(conCliente().state) });
            }, s.next)
            else s.next()
        }
    }, [Clientes])
    useEffect(() => {
        if (conCliente().state !== '') setest({ status: 3, hora: formatoHora(conCliente().state) });
        if (AuthSome(['FACTURA', 'NOTA', 'COTIZACION', 'CLIENTE'])) conCliente().cargarListaCache(setClientes);
        semaforo().add('Clientes');
        return () => semaforo().clean('Clientes');
    }, []);
    useEffect(() => {
        let interval = setInterval(update, 100)
        return () => clearInterval(interval)
    }, [Clientes]);
    const findCliente = useCallback((p: string) => {
        const pro = Clientes.find(Pro => Pro.codigo === p);
        if (pro !== undefined) return pro;
        return ClienteNull;
    }, [Clientes])
    return <ClientesContext.Provider value={{
        estCliente: est,
        Clientes: Clientes,
        actCliente: update,
        findCliente: findCliente
    }}>
        {children}
    </ClientesContext.Provider>
}
export const SeccionConsumer = ClientesContext.Consumer;