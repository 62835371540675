import React from 'react'
import { Col, Button, Table, ButtonGroup, Row as Fila } from 'react-bootstrap'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import BuscarProducto from '../../App/Inventario/Productos/BuscarProducto'
import FormProductos from '../../App/Inventario/Productos/FormProductos'
import { ProductosContext } from '../../Context/Producto'
import { Detalles, GeneralData } from '../../Models/General'
import { Producto } from '../../Models/Productos'
import CalcularImpuesto from './CalcularImpuesto'
interface State {
    nuevoProducto: boolean
    buscarProducto: boolean
    aux: boolean
    aux2: boolean
    aux3: boolean
    aux4: boolean
    aux5: boolean
}
interface Props {
    data: GeneralData
    setData: (data: any) => void
}
export type ProductosModal = 'Cotizacion' | 'Compra' | 'Factura' | 'Nota' | 'Orden' | 'SALIDA' | 'ENTRADA' | 'COSTO' | 'UTILIDAD' | 'Seleccion';
class AuxiliarNuevo<p = {}> extends React.Component<Props & p, State> {
    setData: (data: any) => void;
    nuevo = false;
    productos: ProductosModal = 'Compra';
    constructor(p: Props & p) {
        super(p);
        this.setData = p.setData;
        this.Row = this.Row.bind(this);
        this.THead = this.THead.bind(this);
        this.precio = this.precio.bind(this);
        this.Calcular = this.Calcular.bind(this);
        this.BotonesLeft = this.BotonesLeft.bind(this);
        this.BotonesRight = this.BotonesRight.bind(this);
        this.addProductos = this.addProductos.bind(this);
        this.removeProductos = this.removeProductos.bind(this);
        this.createProducto = this.createProducto.bind(this);
        this.state = { nuevoProducto: false, buscarProducto: false, aux: false, aux2: false, aux3: false, aux4: false, aux5: false };
    }
    addProductos(pr: Detalles) {
        let { props: { data: { detalles } } } = this;
        pr.precio = parseFloat(pr.precio.toFixed(2));
        if (detalles.some(p => p.codigo === pr.codigo)) this.setData({ detalles: detalles.map(p => { if (p.codigo === pr.codigo) return pr; return p; }) })
        else this.setData({ detalles: [...detalles, pr] })

    }
    removeProductos(pr: Detalles) {
        let { props: { data: { detalles } } } = this;
        this.setData({ detalles: detalles.filter(p => p.codigo !== pr.codigo) })
        toast('PRODUCTO ' + pr.descripcion + " QUITADO", { type: toast.TYPE.SUCCESS })
    }
    createProducto(p: Producto) {
        this.addProductos({ ...p, unidades: 1, precio: p.costo, comentario: "" });
    }
    Row({ data, add, remove }: { data: Detalles, add: (pr: Detalles) => void, remove: (pr: Detalles) => void }) {
        return <></>;
    }
    BotonesRight() {
        const { props: { setData, data: { dolar } } } = this;
        return <Col xs={6} style={{ textAlign: 'right' }} className="float-right">
            <Button data-tip={"CAMBIAR A " + (dolar ? 'BOLIVARES' : 'DOLARES')} style={{ marginRight: "15px" }}
                variant={dolar ? 'warning' : "primary"} onClick={() => setData({ dolar: !dolar })} size='sm'>
                {dolar ? <span className="fa fa-dollar-sign" /> : <b>BS</b>} DOCUMENTO EN {dolar ? 'DOLARES' : 'BOLIVARES'}
            </Button>
            <Button data-tip="PROCESAR" style={{ marginRight: "15px" }} variant="success"
                className="float-right" onClick={() => this.setData({ procesar: true })} >
                <span className="fa fa-print" />
            </Button>
            <ButtonGroup className="float-right" style={{ marginRight: "15px" }}>
                {this.createProductoValidation() ? <Button data-tip="NUEVO PRODUCTO" onClick={() => { this.setState({ nuevoProducto: true }) }} variant="info">
                    <i className="fa fa-edit"></i>
                </Button> : <></>}
                <Button data-tip="BUSCAR PRODUCTO" onClick={() => { this.setState({ buscarProducto: true }) }} variant="primary">
                    <span className="fa fa-search"></span>
                </Button>
            </ButtonGroup>
        </Col>
    }
    BotonesLeft() { return <Col xs={6} /> }
    render() {
        let imp = 0, ext = 0;
        const { BotonesRight, BotonesLeft, THead, Row, Calcular, addProductos, removeProductos, precio,
            context: { findProVen },
            state: { nuevoProducto, buscarProducto },
            props: { data: { detalles, dolar } }
        } = this;
        return <>
            <FormProductos open={nuevoProducto} dolar={dolar} close={this.close.bind(this)} add={this.createProducto.bind(this)} />
            <BuscarProducto type={this.productos} dolar={dolar} open={buscarProducto} add={this.addProductos.bind(this)} close={this.close.bind(this)} />
            <ReactTooltip />
            <br />
            <Fila>
                <BotonesLeft />
                <BotonesRight />
            </Fila>
            <hr />
            <Table className="accordion" striped hover>
                <THead /><tbody>
                    {detalles.map(p => {
                        if (findProVen(p).exento) ext += precio(p.precio) * p.unidades
                        else imp += precio(p.precio) * p.unidades
                        return <Row data={p} add={addProductos} remove={removeProductos} />
                    })}
                    <Calcular exento={ext} imponible={imp} />
                </tbody>
            </Table>
        </>
    }
    precio(p: number) {
        let { tasa } = this.context;
        let { dolar } = this.props.data;
        if (dolar) return parseFloat((p / tasa).toFixed(2));
        return p;
    }
    THead() {
        return <tr className='info'>
            <th style={{ width: "90px", textAlign: 'center' }}>COD</th>
            <th style={{ textAlign: 'center' }}>DESCRIPCIÓN</th>
            <th style={{ width: "120px", textAlign: 'center' }}>CANT</th>
            <th style={{ width: "120px", textAlign: 'center' }}>DISP</th>
            <th style={{ width: "180px", textAlign: 'center' }}>P.U.</th>
            <th style={{ width: "230px", textAlign: 'center' }}>TOTAL</th>
            <th style={{ width: "110px", textAlign: 'center' }}></th>
        </tr>
    }
    Calcular({ imponible, exento }: { imponible: number, exento: number }) {
        return <CalcularImpuesto exento={exento} imponible={imponible} />;
    }
    close() {
        this.setState({ nuevoProducto: false, buscarProducto: false, aux: false })
    }
    createProductoValidation() {
        if (this.nuevo)
            if (this.context.data.Permisos.some((p: string) => p === 'PRODUCTO'))
                return true;
        return false;
    }
}
AuxiliarNuevo.contextType = ProductosContext;
export default AuxiliarNuevo;