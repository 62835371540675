import React, { useContext } from "react"
import { ConfigContext, ConfigType } from "../../Context/Producto/Config";
import { numberFormat } from "../Formatos";
export default function CalcularImpuesto({ imponible, exento, cols = 3 }: { imponible: number, exento: number, cols?: number }) {
    const { impuesto } = useContext(ConfigContext) as ConfigType;
    function calImpuesto() {
        let imp = (impuesto / 100);
        return numberFormat(imponible * imp);
    }
    function calPagar() {
        let imp = impuesto / 100;
        let total = imponible + exento + (imponible * imp);
        return numberFormat(total)
    }
    return <>
        <tr>
            <th colSpan={cols} rowSpan={8}></th>
            <th style={{ textAlign: "right" }} colSpan={2}>SUBTOTAL:</th>
            <th style={{ textAlign: "right" }}>{numberFormat(imponible + exento)}</th>
        </tr>
        {exento > 0 ? <>
            <tr>
                <th style={{ textAlign: "right" }} colSpan={2}>EXENTO:</th>
                <th style={{ textAlign: "right" }}>{numberFormat(exento)}</th>
            </tr>
            <tr>
                <th style={{ textAlign: "right" }} colSpan={2}>BASE IMPONIBLE:</th>
                <th style={{ textAlign: "right" }}>{numberFormat(imponible)}</th>
            </tr>
        </> : <></>}
        <tr>
            <th style={{ textAlign: "right" }} colSpan={2}>IMPUESTO {impuesto}%:</th>
            <th style={{ textAlign: "right" }}>{calImpuesto()}</th>
        </tr>
        <tr>
            <th style={{ textAlign: "right" }} colSpan={2}>TOTAL:</th>
            <th style={{ textAlign: "right" }}>{calPagar()}</th>
        </tr>
    </>
}
