import { content } from './props';
import { Card, Accordion, Row } from 'react-bootstrap';
import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
import { UseIcon } from '../../../Component/Icons/IconSelector';
import { TextToHTML } from '../../../../../Utiles/Texto';

export function Itens({
    config: { seleted, border, back: iback, orientation, edit }, setConfig, iten }: { iten?: iten, config: State, setConfig: (p: Partial<State>) => void }) {
    if (iten === undefined) return <></>;
    const { id, html, icon, color, back, aux, fill } = iten; 
    return <Card className={content(back, color, fill, iback, border, aux)}>
        <Card.Header>
            <Accordion.Toggle as={Row} className={`d-flex flex-${orientation}`} eventKey={`${id}`}>
                <div className={"icon-content text-center"}>
                    <UseIcon icon={icon} width={`2rem`} height={`2rem`} fill={fill} />
                </div>
                {(seleted === id) && (edit === 'header') ?
                    <div style={{ minWidth: 'calc(100% - 3rem)', border: '2px solid red' }} className="p-1" ><EditArea key={'edit' + id} /></div> :
                    <div style={{ minWidth: 'calc(100% - 3rem)' }} onClick={() => setConfig({ edit: 'header', seleted: id })} className="p-1" ><TextToHTML text={html} /></div>
                }
            </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={`${id}`}>
            {(seleted === id) && (edit === 'body') ?
                <Card.Body style={{ border: '2px solid red' }} > <EditArea key={'edit' + id} /></Card.Body> :
                <Card.Body onClick={() => setConfig({ edit: 'body' })} style={{ color }}><TextToHTML text={html} /></Card.Body>
            }
        </Accordion.Collapse>
    </Card>
}