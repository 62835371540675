import React, { useState } from "react";
import { css } from '@emotion/css';
import { ButtonVideo, ButtonConfig } from '../../Panel/Component';
import { Modal, ButtonGroup } from "react-bootstrap";
import VideoProvider from './Context/Video';
import Main from "./Main";
interface Props {
    video: string
    type: string
    setVideo: (video: string) => void
}
export function CropModal({ video, setVideo, type }: Props) {
    return <ButtonGroup>
        <ButtonVideo setData={setVideo} />
        <EditModal setVideo={setVideo} video={video} type={type} /> 
    </ButtonGroup>
}
export function EditModal({ video, setVideo, type }: Props) {
    const [open, setOpen] = useState(false);
    return <>
        <Modal Modal className={css`
        &> .modal-dialog{
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            width:100vw;
            height:100vh;
            max-width: 100vw;
        }
        &> .modal-dialog> .modal-content{
            margin: 0 0 0 0;
            width:100vw;
            height:100vh;
        }`} animation show={open} onHide={() => setOpen(false)}>
            <Modal.Body>
                <VideoProvider><Main close={() => setOpen(false)} saveVideo={setVideo} type={type} videoUrl={video} /></VideoProvider>
            </Modal.Body>
        </Modal>
        <ButtonConfig tip='EDITAR VIDEO' action={() => setOpen(true)} icon='fas fa-edit' />
    </>
}