import { css } from '@emotion/css';
import { useState, useEffect, useMemo } from 'react'
import { PreviewSize } from '../../Ver/PreviewSize'
import { Opciones } from '../../Ver/Utils'
import { Col, Row } from 'react-bootstrap';
import { Icon2 } from '../../Component/Icons/IconSelector';
import { ToRGB } from '../../../../Utiles/Formatos';
import { conPanel } from '../../../../Models/Panel';
import { Html } from '../../Component/Commons';
export function Pestanas({ data: { props, itens, id }, size }: any) {
    const [select, setSelect] = useState(1);
    const { color, tap, tapTitle, tapPosition, font, variant } = props;
    const selectIten = itens.find((i: any) => i.indice === select) as any;
    return <div className={`${getCSS(tap, tapTitle, font)} m-0 d-flex flex-${tapPosition}`}
        style={{ backgroundColor: color, padding: '10px 0 10px 0' }}>
        <Row as='ul' className={`m-0 p-0 ${variant}`}>
            {itens.map((iten: any, i: number) => <Tap key={`itens${id}${i}`}
                selected={select} config={props} select={setSelect} iten={iten} />)}
        </Row>
        <Itens key={`iten${id}${selectIten.id}`} size={size} iten={selectIten} />
    </div>
}
function Itens({ iten, size }: any) {
    const [panel, setPanel] = useState<any>([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { conPanel().forceGetWeb(`/api/Panel/detalles/${iten.props.panel}`, setPanel, () => { }); }, [])
    return useMemo(() => <div className='w-100 m-0 p-0'>
        <Opciones panel={panel} actualizar={() => { }} />
        <PreviewSize key={panel.type + panel.id} panel={panel} size={size} />
    </div>, [panel, size])
}
function Tap({ config, select, iten, selected }: any) {
    const { indice, html, props: { icon, fill, aux } } = iten;
    const { seleted, orientation, back, border, font } = config;
    const zIndex = seleted === indice ? 20 : indice > seleted ? 20 - (indice - seleted) : 20 - (seleted - indice);
    return <Col style={{ zIndex }} onClick={() => select(indice)} as='li'
        className={`p-1 d-flex flex-${orientation} ${selected === indice ? 'active' : ''}`}>
        <div className={"icon-content text-center"}>
            <Icon2 icon={icon} font={font} fill={fill} back={back}
                border={border} aux={aux} />
        </div>
        <Html style={{ minWidth: 'calc(100% - 3rem)' }}
        className="d-flex align-items-center h-100 p-1" html={html} />
    </Col>
}
function getCSS(back: string, color: string, font: number) {
    const { r, g, b, a } = ToRGB(back)
    const { r: cr, g: cg, b: cb, a: ca } = ToRGB(color)
    return css`
        &> ul> li{
            background-color: rgba(${r + 30}, ${g + 30}, ${b + 30}, ${a});
            color: rgba(${cr - 150}, ${cg - 150}, ${cb - 150}, ${ca});
            border: 1px solid rgba(${cr}, ${cg}, ${cb}, ${ca});
            &:hover{
                background-color: rgba(${r - 30}, ${g - 30}, ${b - 30}, ${a});
                color: rgba(${cr + 150}, ${cg + 150}, ${cb + 150}, ${ca});
            }
            &.active,&:active{
                background-color: rgba(${r}, ${g}, ${b}, ${a});
                color: rgba(${cr}, ${cg}, ${cb}, ${ca});
            }
        }
        &.flex-row,
        &.flex-row-reverse {
            &> ul{
                width: 20%;
                height: 100%;
                flex-direction: column !important;
                &> li{max-height: ${(font * 1.65) + 1}rem;}
                &>.flex-column-reverse,&>.flex-column{max-height: ${(font + 1.5) + 2}rem;}
            }
            &> div{width: 80%;}
            &> .tab> li{
                margin: -10px 0 -10px 0;
                padding: 10px 5px 10px 5px !important;
                &> .p-1{margin-top: 5px;}
                &:first-child{margin-top: 0;}
                &:last-child{margin-bottom: 0;}
            }
        }
        &.flex-column-reverse,
        &.flex-column{
            &> .tab> li{
                margin: 0 -10px 0 -10px;
                padding: 5px 30px 5px 30px !important;
                &:first-child{margin-left: 0;}
                &:last-child{margin-right: 0;}
            }
        }
        &.flex-column> .tab> li{border-radius: 25px 25px 0 0;}
        &.flex-column-reverse> .tab> li{border-radius: 0 0 25px 25px;}
        &.flex-row> .tab> li{border-radius: 25px 0 0 25px;}
        &.flex-row-reverse> .tab> li{border-radius: 0 25px 25px 0;}
    `;
}

