import { CardMain, SizeViewSelector, Title, Guardar, TitleCard, CardConfig, RowConfig } from '../../Component';
import { ItenImagen } from '../../Component/Commons';
import { State, iten } from './index'
import { TextMenu, BackMenu } from './Itens/Config';
import { Selector } from './Selector';
interface Props {
    save: () => void
    iten: iten;
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void,
    setIten: (d: Partial<iten>) => void
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    addIten: () => void
    removeItem: (d: number) => void
}
export function Config({ save, title, config, setTitle, setConfig, setIten, iten, itenUp, itenDown, addIten, removeItem }: Props) {
    const { size, animated, itens: { length } } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="287px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='100%' />
            </RowConfig>
            <RowConfig top={2}>
                <Selector ani={animated} set={(animated) => setConfig({ animated })} />
            </RowConfig>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <ItenImagen
            itenDown={itenDown} removeItem={removeItem}
            setImagen={(img) => setIten({ img })}
            cont={length} iten={iten} aspect={16 / 9}
            addItem={addIten} itenUp={itenUp} />
        <TextMenu iten={iten} setConfig={setIten} />
        <BackMenu iten={iten} setConfig={setIten} />
        <SizeViewSelector size={size}
            setSize={(size) => setConfig({ size })} />
    </CardMain>
}