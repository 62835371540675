import { useState, useEffect } from 'react';
import { ColorButton, FontStyle, MarkMenu } from '../../../../../Utiles/Texto/MarkProps';
import { RowConfig, CardConfig, TitleCard } from '../../../Component';
import SlateProvider, { EditArea } from '../../../Component/SlateCustom';
import { t, t1, t3, t2, defaultText } from '../../../ProtoPanes/DefaultData';
import { Props } from './index';
const p = '<p  style="float: right; text-align: left; padding: 0px; font-size: 1rem; min-height: 1rem; width: calc(100% - 0.1rem); margin: 0.05rem;">';
export function Text({ data: { data }, setData }: Props) {
    const [texto, setText] = useState<any[]>(data.text ? data.text : defaultText);
    useEffect(() => {
        setTimeout(() => {
            const div = document.getElementById('EditableHTML');
            if (div !== null) {
                const html = div.innerHTML.split('</p>')[0]
                    .replaceAll(t, '').replaceAll(t1, '')
                    .replaceAll(t2, '').replaceAll(t3, '');
                setData({ data: { texto }, html: html.replaceAll(p, '') });
            }
        }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [texto])
    return <SlateProvider value={texto} setValue={(t: any[]) => setText([t[0]])}>
        <CardConfig marginNull width="100%">
            <RowConfig>
                <MarkMenu />
                <ColorButton />
                <FontStyle />
            </RowConfig>
            <TitleCard>TEXTO Y FUENTE</TitleCard>
        </CardConfig>
        <CardConfig marginNull width="100%">
            <RowConfig ><EditArea /></RowConfig>
            <TitleCard>AREA DE TEXTO</TitleCard>
        </CardConfig>
    </SlateProvider>
}