import { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { State } from './index';
interface Props {
    config: State
    aspect: number
    setConfig: (d: any) => void
}
export const EasyCrop = ({ config: { zoom, rotate, preview }, aspect, setConfig }: Props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const onCropComplete = useCallback((croppedArea, cropData) => {
        setConfig({ cropData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Cropper
            image={preview}
            crop={crop}
            zoom={zoom}
            rotation={rotate}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={zoom => setConfig({ zoom })}
            onRotationChange={rotate => setConfig({ rotate })}
        />
    )
}
