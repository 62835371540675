import  { useContext } from 'react'
import { Col, Button, ButtonGroup } from 'react-bootstrap'
import { Detalles, GeneralFila } from '../../../../Models/General'
import { conCotizacion, conNota, Cotizacion, Factura, Nota, sentNota, updatePrecio } from '../../../../Models/Ventas'
import { toast } from 'react-toastify'
import { Producto } from '../../../../Models/Productos'
import BuscarCotizacion from '../../Cotizacion/BuscarCotizacion';
import BuscarNota from '../../Salidas/BuscarNota';
import AuxiliarVentas from '../../../../Utiles/Ventas/AuxiliarVentas';
import { ProductosType } from '../../../../Context/Producto'
import { ProductosModal } from '../../../../Utiles/Prototipos/AuxiliarNuevo'
import { VentasContext, VentasType } from "../../../../Context/Ventas";
import { Procesar } from './ModalPagos';
import Switch from '@material-ui/core/Switch';
import { numberFormat } from '../../../../Utiles/Formatos'

class AuxiliarFactura extends AuxiliarVentas<{ procesar: () => void }> {
    productos: ProductosModal = 'Factura';
    addProductos(pr: Detalles) {
        let data = this.props.data as Factura;
        if (data.id_nota !== undefined)
            toast("UNA NOTA DE ENTREGA NO PUEDE SER EDITADA AL FACTURARLA", { type: toast.TYPE.ERROR })
        else super.addProductos(pr)
    }
    removeProductos(pr: Detalles) {
        let data = this.props.data as Factura;
        if (data.id_nota !== undefined)
            toast("UNA NOTA DE ENTREGA NO PUEDE SER EDITADA AL FACTURARLA", { type: toast.TYPE.ERROR })
        else super.removeProductos(pr)
    }
    createProducto(p: Producto) {
        let data = this.props.data as Factura;
        if (data.id_nota !== undefined)
            toast("UNA NOTA DE ENTREGA NO PUEDE SER EDITADA AL FACTURARLA", { type: toast.TYPE.ERROR })
        else super.createProducto(p)
    }
    BotonesLeft() {
        const { cargarCotizacion, cargarNota, state: { aux2, aux } } = this;
        const { exento } = this.props.data as any;
        return <Col xs={6}>
            <BuscarCotizacion open={aux} add={cargarCotizacion.bind(this)} close={() => this.setState({ aux: false })} />
            <BuscarNota open={aux2} add={cargarNota.bind(this)} close={() => this.setState({ aux2: false })} />
            <ButtonGroup>
                <Button onClick={() => this.setState({ aux: true })}
                    data-class="toolbody" data-html="true"
                    data-tip="CARGAR COTIZACION" variant="primary">
                    <span className="fa fa fa-calculator" />
                </Button>
                <Button onClick={() => this.setState({ aux2: true })}
                    data-class="toolbody" data-html="true"
                    data-tip="CARGAR NOTA" variant="primary">
                    <span className="fa fa-paper-plane" />
                </Button>
            </ButtonGroup>
            <Switch defaultChecked={exento} color="primary" className="ml-2" onChange={(e, exento) => this.props.setData({ exento })} />
            {exento ? <b>EXENTO DE IMPUESTO</b> : <>EXENTO DE IMPUESTO</>}
        </Col>
    }
    BotonesRight() {
        const { props: { setData, data: { dolar } } } = this;
        const { exento } = this.props.data as any;
        return <Col xs={6} style={{ textAlign: 'right' }} className="float-right">
            <Button data-tip={"CAMBIAR A " + (dolar ? 'BOLIVARES' : 'DOLARES')} style={{ marginRight: "15px" }}
                variant={dolar ? 'warning' : "primary"} onClick={() => setData({ dolar: !dolar })} size='sm'>
                {dolar ? <span className="fa fa-dollar-sign" /> : <b>BS</b>} DOCUMENTO EN {dolar ? 'DOLARES' : 'BOLIVARES'}
            </Button>
            <Procesar accion={this.props.procesar} data={this.props.data as Factura} setData={this.setData} iva={exento ? 0 : this.context.impuesto as number} />
            <ButtonGroup className="float-right" style={{ marginRight: "15px" }}>
                {this.createProductoValidation() ? <Button data-tip="NUEVO PRODUCTO" onClick={() => { this.setState({ nuevoProducto: true }) }} variant="info">
                    <i className="fa fa-edit"></i>
                </Button> : <></>}
                <Button data-tip="BUSCAR PRODUCTO" onClick={() => { this.setState({ buscarProducto: true }) }} variant="primary">
                    <span className="fa fa-search"></span>
                </Button>
            </ButtonGroup>
        </Col>
    }
    Calcular(p: { imponible: number, exento: number }) {
        const { exento } = this.props.data as any;
        return <CalcularImpuesto ext={exento} {...p} />;
    }
    cargarCotizacion({ codigo, rif }: GeneralFila) {
        const { tasa, dolar } = this.context as ProductosType;
        this.setData({ cod_cliente: rif, id_cotizacion: codigo });
        conCotizacion().detalle(codigo, (data: Cotizacion) => this.setData(updatePrecio(data, tasa, dolar)));
    }
    cargarNota(d: GeneralFila) {
        conNota().detalle(d.codigo, (data: Nota) => {
            let p: any = sentNota(data, 0);
            p.detalles = data.detalles;
            p.id_nota = data.codigo;
            p.codigo = 0;
            if (this.props.data.nota !== '') p.nota = this.props.data.nota;
            this.setData(p)
        });
    }
}
AuxiliarFactura.contextType = VentasContext;
export default AuxiliarFactura;


export function CalcularImpuesto({ imponible, exento, cols = 3, ext }: { ext: boolean, imponible: number, exento: number, cols?: number }) {
    const { impuesto } = useContext(VentasContext) as VentasType;
    function calImpuesto() {
        if (ext) return numberFormat(0);
        let imp = (impuesto / 100);
        return numberFormat(imponible * imp);
    }
    function calPagar() {
        if (ext) return numberFormat(imponible + exento);
        let imp = impuesto / 100;
        let total = imponible + exento + (imponible * imp);
        return numberFormat(total)
    }
    return <>
        <tr>
            <th colSpan={cols} rowSpan={8}></th>
            <th style={{ textAlign: "right" }} colSpan={2}>SUBTOTAL:</th>
            <th style={{ textAlign: "right" }}>{numberFormat(imponible + exento)}</th>
        </tr>
        {exento > 0 ? <>
            <tr>
                <th style={{ textAlign: "right" }} colSpan={2}>EXENTO:</th>
                <th style={{ textAlign: "right" }}>{numberFormat(exento)}</th>
            </tr>
            <tr>
                <th style={{ textAlign: "right" }} colSpan={2}>BASE IMPONIBLE:</th>
                <th style={{ textAlign: "right" }}>{numberFormat(imponible)}</th>
            </tr>
        </> : <></>}
        <tr>
            <th style={{ textAlign: "right" }} colSpan={2}>IMPUESTO {ext ? 0 : impuesto}%:</th>
            <th style={{ textAlign: "right" }}>{calImpuesto()}</th>
        </tr>
        <tr>
            <th style={{ textAlign: "right" }} colSpan={2}>TOTAL:</th>
            <th style={{ textAlign: "right" }}>{calPagar()}</th>
        </tr>
    </>
}