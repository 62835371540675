import React from "react";
import { toast } from "react-toastify";
import { ProductosContext } from "../../../../Context/Producto";
import { AjusteNull, ajusteSend, conAjuste } from "../../../../Models/Ajuste";
import { InputArea } from "../../../../Utiles/Inputs";
import CreateOperacion from "../../../../Utiles/Componentes/CreateOperacion";
import AuxiliarAjuste from "./AuxiliarAjuste";
class NuevaAjuste extends CreateOperacion {
    con = conAjuste();
    title = 'OPERACION DE INVENTARIO'
    msn = 'LA OPERACION DE INVENTARIO';
    constructor(p: any) {
        super(p);
        this.state = { ...AjusteNull, procesar: false };
        this.tipo = 'Ajuste';
    }
    Top() {
        return <>
            <b>NOTA</b>
            <InputArea rows={4} maxLength={600} value={this.state.nota} setData={this.setState} update="nota" />
        </>
    }
    PDF() {
        return <iframe src={`/api/Ajuste/PDF/${this.state.codigo}`} title="print" frameBorder='0' width='100%' height='700wh' scrolling='no' />
    }
    Productos() {
        return <AuxiliarAjuste setData={this.setState} data={this.state} />
    }
    Procesar() {
        const data: any = this.state;
        let procesar = true;
        if (this.state.nota === '') {
            toast('DEBE AGREGAR UNA NOTA', { type: toast.TYPE.ERROR });
            procesar = false;
        }
        procesar = this.validacionGenerica(procesar);
        if (procesar)
            this.con.nuevo(ajusteSend(data),
                data => this.setState({ codigo: data.codigo }),
                data => this.procesar = true);
    }
}
NuevaAjuste.contextType = ProductosContext;
export default NuevaAjuste;