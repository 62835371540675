import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { ConConfig } from '../../../Models/Config';
import Formulario from './Formulario';
import { Impreciones, imprecionNull } from '../../../Models/General';
import Condiciones from '../../../Utiles/Componentes/Condiciones';
interface State extends Impreciones { 
    nota: string,
    forma_pago: string
    tiempo_entrega: string
    validez: string
}
export default class Orden extends React.Component<any, State> {
    con = ConConfig('orden');
    state = { ...imprecionNull, nota: '', forma_pago: '', tiempo_entrega: '', validez: '' };
    render() {
        let setState = this.setState.bind(this);
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE ORDEN DE COMPRA </h4>
            </Card.Header>
            <Card.Body>
                <Card>
                    <Card.Header className='bg-info'>
                        <h5>CONFIGURACION DE DATOS POR DEFECTO </h5>
                    </Card.Header>
                    <Card.Body>
                        <Condiciones data={this.state} setData={setState} />
                    </Card.Body>
                </Card>
                <Formulario version='orden' data={this.state} setData={setState} />
                <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> Guardar
                </Button>
            </Card.Body>
        </Card>)
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}