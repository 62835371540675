/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useEffect, useContext, useCallback } from 'react'
import { AuthSome } from '../../Config/Permisos';
import { conNota } from '../../Models/Ventas'
import { Estado, EstadoNull, GeneralFila, updateOrPush } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { CotizacionContext, CotizacionType } from './Cotizacion';
import { semaforo } from '../../Utiles/Semaforo';
export interface NotasType extends CotizacionType {
    estNota: Estado
    Notas: GeneralFila[]
    actNota: () => void
}
export const NotasContext = createContext<NotasType | null>(null)
export default function NotasProvider({ children }: { children: any }) {
    const [Notas, setNotas] = useState<GeneralFila[]>([]);
    const cotizacion = useContext(CotizacionContext) as CotizacionType;
    const [est, setest] = useState(EstadoNull);
    const s = semaforo();
    const update = useCallback(() => {
        if (s.isTurn('Notas')) {
            if (AuthSome(['FACTURA', 'NOTA'])) conNota().cambios((data: any[]) => {
                setest({ status: 3, hora: formatoHora(conNota().state) })
                setNotas(updateOrPush(data, Notas, conNota()))
            }, s.next)
            else s.next()
        }
    }, [Notas]);
    useEffect(() => {
        if (conNota().state !== '') setest({ status: 3, hora: formatoHora(conNota().state) })
        if (AuthSome(['FACTURA', 'NOTA'])) conNota().cargarListaCache(setNotas);
        s.add('Notas');
        return () => s.clean('Notas');
    }, []);
    useEffect(() => {
        let interval = setInterval(update, 100)
        return () => clearInterval(interval)
    }, [Notas]);
    return <NotasContext.Provider value={{
        ...cotizacion,
        estNota: est, Notas,
        actNota: update
    }}>
        {children}
    </NotasContext.Provider>
}
export const SeccionConsumer = NotasContext.Consumer;