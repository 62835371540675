import { CardConfig, RowConfig, ButtonConfig, TitleCard } from '../../../Panel/Component';
import { Button, ButtonGroup } from "react-bootstrap";
import { CircleSlider } from "../../../../Utiles/Componentes/circle-slider";
import { Filtro } from './index'

interface Props {
    setData: (d: number) => void,
    value: number
}
export default function Crop({ setData, value, onCrop }: Props & { onCrop: () => void }) {
    return <>
        <Rotate setData={(v) => setData(v)} value={value} />
        <Button onClick={onCrop} size='sm' variant='success' block className='mt-2'>
            APLICAR CORTE
        </Button>
    </>
}
export function Zoom({ setData, value, }: Props) {
    return <Filtro setData={(d: any) => { setData(d) }} step={.01}
        value={value} orientation="vertical" max={3} min={1} sal={.01} />
}
export function Rotate({ setData, value, }: Props) {
    return <CardConfig marginNull width="143">
        <RowConfig>
            <ButtonGroup style={{ marginBottom: -5 }}>
                <ButtonConfig tip='POSICON ORIGINAL' style={{ width: 33 }} icon='fas fa-tree' action={() => setData(0)} />
                <ButtonConfig tip='ROTAR 90°' style={{ width: 33 }} icon='fas fa-tree fa-rotate-90' action={() => setData(90)} />
                <ButtonConfig tip='ROTAR 180°' style={{ width: 33 }} icon='fas fa-tree fa-rotate-180' action={() => setData(180)} />
                <ButtonConfig tip='ROTAR 270°' style={{ width: 33 }} icon='fas fa-tree fa-rotate-270' action={() => setData(270)} />
            </ButtonGroup>
        </RowConfig>
        <RowConfig>
            <CircleSlider min={0} max={360} size={140} showTooltip={true} value={value}
                progressWidth={10} knobRadius={10} onChange={(v: any) => setData(v)} />
        </RowConfig>
        <TitleCard>ROTAR IMAGEN</TitleCard>
    </CardConfig>
}