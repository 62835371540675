import { useState, useEffect, useContext, useMemo } from 'react'
import { Button, Modal, Row, Table, ButtonGroup } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Conexion } from '../../Config/Coneccion';
import { ProductosContext, ProductosType } from '../../Context/Producto';
import { generalNull } from '../../Models/General';
import { numberFormat } from '../Formatos';
interface Props {
    id: number
    open: boolean,
    close: () => void,
    titulo: string
    con: Conexion
}
export default function ModalDetalle({ id, open, close, titulo, con }: Props) {
    const { dolar } = useContext(ProductosContext) as ProductosType;
    const [detalles, setDetalles] = useState(generalNull.detalles);
    const [tasa, setTasa] = useState(1);
    const [usd, setUSD] = useState(dolar);
    useEffect(() => {
        con.detalle(id, data => {
            setDetalles(data.detalles);
            if (data.tasa === 0) setUSD(false);
            setTasa(data.tasa);
        });
    }, [con, id])
    return <Modal size='xl' centered animation show={open} onHide={close}>
        {useMemo(() => {
            const cDolar = (p: number) => parseFloat((usd ? p / tasa : p).toFixed(2))
            let suma = 0;
            return <>
                <Modal.Header closeButton>
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        <span><b>{titulo}</b></span>
                    </h6>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ padding: '0px 30px 0px 30px' }}>
                        <ReactTooltip />
                        <Row className='col-12'>
                            <h4 className='mr-auto'>
                                <span className="fa fa-print" />
                                MOSTRAR VISTA
                            </h4>
                            <ButtonGroup>
                                <Button onClick={() => setUSD(false)} data-tip="CAMBIAR A PRECIO EN BOLIVARES" variant={(usd ? 'outline-' : '') + "primary"}>
                                    PRECIO EN BOLIVARES
                                </Button>
                                <Button onClick={() => setUSD(true)} data-tip="CAMBIAR A PRECIO EN DOLARES" variant={(!usd ? 'outline-' : '') + "warning"}>
                                    PRECIO EN DOLARES
                                </Button>
                            </ButtonGroup>
                        </Row>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ width: "100px" }}>COD</th>
                                    <th>DESCRIPCION</th>
                                    <th style={{ width: "40px", textAlign: "right" }}>CANT</th>
                                    <th style={{ width: "80px", textAlign: "right" }}>PRE/UNT</th>
                                    <th style={{ width: "150px", textAlign: "right" }}>PRECIO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detalles.map((s) => {
                                    suma += s.precio * s.unidades
                                    return <tr>
                                        <td style={{ width: "100px" }}>{s.codigo}</td>
                                        <td>{s.descripcion}</td>
                                        <td style={{ width: "40px" }}>{numberFormat(s.unidades)}</td>
                                        <td style={{ width: "80px", textAlign: "right" }}>{numberFormat(cDolar(s.precio))}</td>
                                        <td style={{ width: "150px", textAlign: "right" }}>{numberFormat(cDolar(s.precio) * s.unidades)}</td>
                                    </tr>
                                })}
                                <tr>
                                    <td colSpan={3}></td>
                                    <td style={{ width: "80px", textAlign: "right" }}><b>TOTAL:</b></td>
                                    <td style={{ width: "150px", textAlign: "right" }}>{numberFormat(cDolar(suma))}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
            </>
        }, [detalles, tasa, usd, titulo])}
    </Modal>
}
