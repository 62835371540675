import { useState, useMemo, useCallback, CSSProperties } from "react";
import Popover from '@material-ui/core/Popover';
import { Button, Row } from 'react-bootstrap';
import { ControlText } from '../../../../Utiles/FormControl';
import { icons } from './icons';
interface Props {
    value: icon;
    className?: string;
    onChange: (v: icon, s?: boolean) => void;
    disabled?: boolean
    nullValue?: boolean
    style?: CSSProperties
}
export interface icon { width: number; height: number; path: string }
type I = string | number | never[] | string[];
interface IconsVariant { solid?: I[]; regular?: I[]; light?: I[]; duotone?: I[]; brands?: I[]; }
export function IconSelector({ value, onChange, className, disabled, nullValue, style = { width: 36, height: 24 } }: Props) {
    const labels = Object.keys(icons) as (keyof typeof icons)[];
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState<any>(null)
    const handleClick = useCallback((event: any) => setOpen(event.currentTarget), []);
    const handleClose = useCallback(() => setOpen(null), []);
    return <>
        <Button data-tip="SELECCIONAR ICONO" disabled={disabled} variant='outline-dark' style={style} size='sm' className={className} onClick={handleClick}>
            <UseIcon width="15" height="15" fill='#000000' icon={value} />
        </Button>
        <Popover open={open} anchorEl={open} onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}    >
            <ControlText before='BUSCAR' after={<i className="fa fa-search" />} setData={setSearch} value={search} />
            {useMemo(() => {
                const s = labels.filter(i => (i.indexOf(search.toLowerCase()) > -1));
                return <Row className='d-flex' style={{ width: 354, maxHeight: 432, margin: '0 0 0 0', overflowY: 'auto' }}>
                    <h5 className='text-center w-100'><b>ICONOS {s.length}</b>
                        {nullValue ?
                            <Button size='sm' variant='outline-danger'
                                style={{ width: 36, height: 30, margin: '3px 3px 3px 3px' }}
                                onClick={() => {
                                    onChange({ width: 0, height: 0, path: '' }, false);
                                    handleClose();
                                }}>
                                <i className='fas fa-times' />
                            </Button> : <></>}
                    </h5>
                    {s.filter((a, i) => (s.length > 100) ? (i >= 0 && i <= 95) : true)
                        .map(i => <Selec2Icons key={i} icons={icons[i] as IconsVariant}
                            select={(i) => {
                                onChange({ width: i[0], height: i[1], path: i[4] } as icon, true);
                                handleClose()
                            }} />)}
                </Row>
            // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [search])}
        </Popover>
    </>
}

export const defaultIcon = { width: 384, height: 512, path: "M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z" };

function SVGPring({ icon, select }: { icon: I[], select: (i: I[]) => void }) {
    return <Button key={icon[3] as string} size='sm' variant='outline-secondary'
        style={{ width: 80, height: 60, margin: '3px 3px 3px 3px' }}
        onClick={() => select(icon)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox={`0 0 ${icon[0]} ${icon[1]}`}>
            {typeof icon[4] === 'string' ? <path d={icon[4]} /> :
                (icon[4] as string[]).map((t, i) =>
                    <path style={{ opacity: i === 0 ? .5 : undefined }} d={t} />)}
        </svg>
    </Button>
}
function Selec2Icons({ icons: { solid, regular, light, brands, duotone }, select }: { icons: IconsVariant, select: (i: I[]) => void }) {
    const primary = (solid !== undefined ? solid : brands) as I[];
    const [open, setOpen] = useState<any>(null)
    const handleClick = useCallback((event: any) => setOpen(event.currentTarget), []);
    const handleClose = useCallback(() => setOpen(null), []);
    return <>
        <Button key={primary[3] as string} size='sm' variant='outline-secondary'
            style={{ width: 36, height: 30, margin: '3px 3px 3px 3px' }} onClick={handleClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox={`0 0 ${primary[0]} ${primary[1]}`}>
                {typeof primary[4] === 'string' ? <path d={primary[4]} /> :
                    (primary[4] as string[]).map((t, i) => <path style={{ fill: `currentColor`, opacity: i === 0 ? .7 : undefined }} d={t} />)}
            </svg>
        </Button>
        <Popover open={open} anchorEl={open} onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}    >
            {solid !== undefined ? <SVGPring icon={solid} select={select} /> : <></>}
            {regular !== undefined ? <SVGPring icon={regular} select={select} /> : <></>}
            {light !== undefined ? <SVGPring icon={light} select={select} /> : <></>}
            {brands !== undefined ? <SVGPring icon={brands} select={select} /> : <></>}
            {duotone !== undefined ? <SVGPring icon={duotone} select={select} /> : <></>}
        </Popover>
    </>
}

export function UseIcon({ width, height, icon, fill }: { fill: string, width: number | string, height: number | string, icon: icon }) {
    return <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${icon.width} ${icon.height}`}>
        {typeof icon.path === 'string' ? <path style={{ fill }} d={icon.path} /> :
            (icon.path as string[]).map((t, i) => <path style={{ fill, opacity: i === 0 ? .7 : undefined }} d={t} />)}
    </svg>
}



export function Icon2({ font, fill, icon, back, border, aux }: { aux: string, border: boolean, fill: string, font: number, icon: icon, back: string }) {
    return <div className="text-center mr-auto ml-auto" style={{
        width: `${font * 1.3}rem`, height: `${font * 1.3}rem`,
        borderRadius: back === 'circle' ? `${font}rem` : back === 'rounded' ? `${font * .3}rem` : undefined,
        backgroundColor: back !== 'none' ? aux : undefined, padding: `${font * .1}rem`,
        border: (border && back !== 'none') ? `${font * .05}rem solid ${fill}` : undefined,
    }}>
        <UseIcon icon={icon} width={`${font}rem`} height={`${font}rem`} fill={fill} />
    </div>
}