
import React from 'react'
import { Button, FormControl } from 'react-bootstrap'
import { Detalles } from '../../../../Models/General'
import { numberFormat } from '../../../../Utiles/Formatos'
import { InputNumber } from '../../../../Utiles/Inputs'
import { Producto } from '../../../../Models/Productos'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'
import { toast } from 'react-toastify'

interface Props {
    producto: Producto,
    add: (iten: Detalles) => void
}
export default class FilaSalida extends React.Component<Props, Detalles>{
    constructor(p: Props) {
        super(p)
        this.state = { ...p.producto, precio: p.producto.costo, unidades: 0, comentario: '' };
        this.setState = this.setState.bind(this)
    }
    render() {
        let { unidades, cantidad, descripcion, codigo } = this.state
        return <tr>
            <THFila width='90px'>{codigo}</THFila>
            <THFila width='auto'>{descripcion}</THFila>
            <THInput width='120px'><InputNumber min={0} step='0.01' setData={this.setState} value={unidades} update="unidades" /></THInput>
            <THInput width='120px'><FormControl style={{ textAlign: 'right' }} disabled value={numberFormat(cantidad)} /></THInput>
            <THInput width='70px'>
                {(cantidad > 0) ? <Button data-tip="AGREGAR PRODUCTO" onClick={() => this.add(unidades)} variant="primary">
                    <i className="fa fa-plus" />
                </Button> : <></>}
            </THInput>
        </tr>
    }
    add(unidades: number) {
        if (unidades !== 0) this.props.add(this.state)
        else toast('DEBE UNGRESAR UNA CANTIDAD AL PRODUCTO', { type: toast.TYPE.ERROR });
    }
}