import React from "react";
import { Accordion, Button, Card, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { CotizacionContext } from "../../../../Context/Ventas/Cotizacion";
import { conCotizacion, Cotizacion, CotizacionNull, sentCotizacion } from "../../../../Models/Ventas";
import Condiciones from "../../../../Utiles/Componentes/Condiciones";
import CreateOperacion from "../../../../Utiles/Componentes/CreateOperacion";
import ClienteSelector from "../../Clientes/Selector";
import AuxiliarCotizacion from "./AuxiliarCotizacion";
class NuevaCotizacion extends CreateOperacion {
    con = conCotizacion();
    title = 'COTIZACION'
    msn = 'LA COTIZACION';
    constructor(p: any) {
        super(p);
        this.state = { ...CotizacionNull, procesar: false };
        this.tipo = 'Cotizacion';
    }
    setCliente(p: string) {
        this.setState({ cod_cliente: p });
    }
    Top() {
        const data = this.getData() as Cotizacion;
        return <>
            <ClienteSelector Cliente={data.cod_cliente} add={this.setCliente.bind(this)} />
            <Accordion>
                <Accordion.Toggle eventKey='0' as={Button} variant='primary'>DATOS OPCIONALES</Accordion.Toggle>
                <Accordion.Collapse eventKey='0'><Card>
                    <Card.Body>
                        <Row>
                            <Condiciones data={data} setData={this.setState} />
                        </Row>
                    </Card.Body>
                </Card>
                </Accordion.Collapse>
            </Accordion>
        </>
    }
    Productos() {
        return <AuxiliarCotizacion key="auxCotizacion" setData={this.setState} data={this.state} />
    }
    Procesar() {
        let procesar = true;
        if (this.state.cod_cliente === "") {
            toast('DEBE SELECCIONAR UN CLIENTE', { type: toast.TYPE.ERROR });
            procesar = false;
        } procesar = this.validacionGenerica(procesar);
        if (procesar)
            this.con.nuevo(sentCotizacion(this.getData(), this.context.impuesto),
                data => this.setState({ codigo: data }),
                data => this.procesar = true);
    }
}
NuevaCotizacion.contextType = CotizacionContext;
export default NuevaCotizacion;