import { css } from '@emotion/css';
import { Row, Container } from 'react-bootstrap';
import { Html } from '../../Component/Commons';
import { Icon2 } from '../../Component/Icons/IconSelector';
export function Servicio2({ data: { id, itens, props } }: any) {
    const Rows: JSX.Element[] = [];
    for (let i = 0; i < itens.length; i += 2) {
        Rows.push(<RowItens
            left={<Itens key={`iten${id}${itens[i].id}`} iten={itens[i]} config={props} />}
            right={<Itens key={`iten${id}${itens[i + 1].id}`} iten={itens[i + 1]} config={props} />} />)
    }
    return <Row className='m-0' style={{ backgroundColor: props.color, padding: '10px 0 10px 0' }}>
        <Container>{Rows}</Container>
    </Row>
}
function Itens({ config: { card, border1, border2, back: iback, orientation, font }, iten }: any) {
    if (iten === undefined) return <></>;
    const { id, html, props: { icon, color, back, aux, fill } } = iten;
    const style = {
        border: (border1) ? `5px solid ${fill}` : undefined,
        backgroundColor: card !== 'none' ? back : undefined,
        borderRadius: card === 'rounded' ? `${font / 3}rem` : undefined,
        padding: '10px 10px 10px 10px',
    }
    return <div style={style} className={`d-flex flex-${((orientation === '' && !(id % 2)) || (orientation !== '' && id % 2)) ? 'row' : 'row-reverse'}`}>
        <div className="d-flex justify-content-center">
            <Icon2 icon={icon} font={font} fill={fill} back={iback} border={border2} aux={aux} />
        </div>
        <Html style={{ color }} html={html} className="caption d-flex align-items-center h-100 p-1" />
    </div>
}
const box = css`
    display: table;
    width: 100%;
    padding: 0;
    position: relative;
    & > li {
        display: table-cell;
        width: 50%;
        padding: 0 5px 0 0;
        position: relative;
    }
    & > li > .c-border-bottom {
        height: 5px;
        width: 90%;
        position: absolute;
        background-color: #e6e6e6cc;
    }
    & > .c-border-middle {
        height: 90%;
        width: 5px;
        position: absolute;
        background-color: #e6e6e6cc;
    }
    `;
const RowItens = ({ left, right }: { left: JSX.Element, right: JSX.Element }) => {
    return <ul className={box}><li>{left}</li><li>{right}</li></ul>
}